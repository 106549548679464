import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      return (
        localStorage.getItem('cws_token')
          ? <Component {...props} />
          : <Redirect to={{ pathname: window.location.pathname, state: { from: props.location } }} />
      )
    }} />
  )
}