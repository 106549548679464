import React from 'react'
import {
  Box,
} from '@material-ui/core';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import PeopleIcon from '@material-ui/icons/People';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import '../../../scss/_modules.scss'

const Modules = props => {
  let { active_module } = props.data ? props.data : null
  return (
    <React.Fragment>
      <Box className="modulesWrap">
        <Box className="headingWithLeftIcon">
          <AccountTreeOutlinedIcon />
          <span className="heading">{props.heading ? props.heading : 'Program Modules'}</span>
        </Box>
        <Box className="modulesCardMain">
          {/* 1 */}
          <Box className={active_module && active_module.beneficiaries ? "modulesCrad active" : "modulesCrad"} onClick={() => props.handleLink(4)}>
            <span className="iconWrap">
              <PeopleIcon />
            </span>
            <span className="text">
              <span className="name">Beneficiaries</span>
              <span className="status">{active_module && active_module.beneficiaries ? "Active" : "Inactive"}</span>
            </span>
          </Box>
          {/* 2 */}
          <Box className={active_module && active_module.locations ? "modulesCrad active" : "modulesCrad"} onClick={() => props.handleLink(5)}>
            <span className="iconWrap">
              <LocationOnIcon />
            </span>
            <span className="text">
              <span className="name">Locations</span>
              <span className="status">{active_module && active_module.locations ? "Active" : "Inactive"}</span>
            </span>
          </Box>
          {/* 3 */}
          <Box className={active_module && active_module.events ? "modulesCrad active" : "modulesCrad"} onClick={() => props.handleLink(6)}>
            <span className="iconWrap">
              <CalendarTodayOutlinedIcon />
            </span>
            <span className="text">
              <span className="name">Events</span>
              <span className="status">{active_module && active_module.events ? "Active" : "Inactive"}</span>
            </span>
          </Box>
          {/* 4 */}
          <Box className={active_module && active_module.cases ? "modulesCrad active" : "modulesCrad"} onClick={() => props.handleLink(7)}>
            <span className="iconWrap">
              <DescriptionOutlinedIcon />
            </span>
            <span className="text">
              <span className="name">Cases</span>
              <span className="status">
                {active_module && active_module.cases ? "Active" : "Inactive"}
              </span>
            </span>
          </Box>
          {/* 5 */}
          <Box className={active_module && active_module.surveys ? "modulesCrad active" : "modulesCrad"} onClick={() => props.handleLink(8)}>
            <span className="iconWrap">
              <AssessmentOutlinedIcon />
            </span>
            <span className="text">
              <span className="name">Surveys</span>
              <span className="status">{active_module && active_module.surveys ? "Active" : "Inactive"}</span>
            </span>
          </Box>
          {/* 46*/}
          <Box className={active_module && active_module.permission ? "modulesCrad active" : "modulesCrad"} onClick={() => props.handleLink(9)}>
            <span className="iconWrap">
              <FileCopyOutlinedIcon />
            </span>
            <span className="text">
              <span className="name">Permissions</span>
              <span className="status">{active_module && active_module.permission ? "Active" : "Inactive"}</span>
            </span>
          </Box>
        </Box>
        {/* {
          props.buttonLabel &&
          <button className="whiteButton" onClick={() => props.handleLink(props.linkValue)} >{props.buttonLabel}</button>
        }
        {
          <button style={{ marginLeft: 10 }} className="whiteButton" onClick={() => props.handleLink(props.linkValue)} >Update Forms</button>
        } */}
      </Box>
    </React.Fragment>
  )
}

export default Modules
