import React, { useState } from 'react'
import {
  TableCell,
  TableRow,
  Box,
  Avatar,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete';
import { history } from '../../../routes/history'
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';

const CustomFormTableRow = (props) => {
  const { index, onClick, form, onDeactivate, onDelete } = props
  const onDeactivateModal = () => {
    onDeactivate(form);
  };

  const date = value => {
    let tempDate = value.split("T")
    if (tempDate.length > 0) {
      let date = tempDate[0].split("-").reverse().join('/')
      let month = tempDate[1].slice(0, 5)
      return date + " " + month
    }
    return null
  }

  // console.log("data:", form)

  return (
    <TableRow key={index} hover>
      <TableCell>
        <Box
          onClick={onClick}
          className="tableLink"
        >
          {form.name}
        </Box>
      </TableCell>

      {/* <TableCell>
          {form.structures.length}
        </TableCell> */}
      <TableCell>
        {
          form.programs &&
            form.programs.length >= 1 ?
            form.programs.map((program, i) => (
              <span key={i} style={{ display: 'block', paddingBottom: 3, textTransform: 'capitalize' }} >{program.name}</span>
            ))
            :
            <span>----</span>
        }
      </TableCell>
      <TableCell>
        <Link className="link" onClick={() => { history.push(`/custom-form/form-revisions/${form._id}`) }}>{form.version}</Link>
      </TableCell>
      <TableCell>
        {
          form.created_by &&
            form.created_by ?
            <Box className="userNameWithImage">
              <span className="userImage">
                <Avatar src="/image/user5.png" alt={form.created_by.first_name} />
              </span>
              <span className="userName">
                {form.created_by ? form.created_by.first_name + " " + form.created_by.last_name : null}
              </span>
            </Box>
            :
            <span>----</span>
        }
      </TableCell>
      <TableCell>
        {
          form &&
            form.updated_at ?
            date(form.updated_at) : date(form.created_at)
        }
      </TableCell>
      <TableCell>
        <span className={`${form.status ? "greenBtn" : "inactiveBtn"} statusBtn`}>
          {form.status ? "Active" : "Inactive"}
        </span>
      </TableCell>
      <TableCell align="right">
        <Box className="deleteIcon">
          <DeleteIcon
            onClick={() => onDelete(form)}
          />
          {
            form.status ?
              <DesktopAccessDisabledIcon onClick={onDeactivateModal} size="small" /> :
              <DesktopMacIcon onClick={onDeactivateModal} color="secondary" size="small" />
          }
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default CustomFormTableRow
