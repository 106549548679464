import CommonFieldData from './CommonFieldData'
import removeSpecialChar from './_special'

export default class FieldGroup extends CommonFieldData{
  constructor(){
    super()
    this.field = {
      ...this.commonField,
      field_type:"field-group",
      extra_details:[],
      validation:{
        ...this.commonField.validation,
        min_repeater:0,
        max_repeater:1
      }
    }
  }
  loadData(data){
    if(this.field.hasOwnProperty('field_label')){
      this.field.field_label = data.field_label
      this.field.field_name = removeSpecialChar(data.field_label.toLowerCase().split(" ").join("_"))
    }
if(data.show_field){
      this.field.show_field = data.show_field
    }
    if(this.field.hasOwnProperty('placeholder')){
      this.field.placeholder = data.placeholder
    }
    if(this.field.hasOwnProperty('order')){
      this.field.order = data.order
    }
    if(this.field.hasOwnProperty('default')){
      this.field.default = data.default
    }
    if(this.field.hasOwnProperty('validation')){
      this.field.validation = {
        required:data.validation.required, 
        condition_chack:data.validation.condition_chack,
        cond_logic:data.validation.cond_logic,
        min_repeater:data.validation.min_repeater,
        max_repeater:data.validation.max_repeater
      }
    }
    if(data.hasOwnProperty("_id")){
      this.field._id = data._id
    }
  }
  getData(){
    return this.field
  }
}



