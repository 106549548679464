

const swDevSetup =  async () => {
	let vapidKeys = { 
	  publicKey:'BALktIPxf7yUfTqC6HenovMwAKLlksCWNUONmYHiJH_vYHfRTARiZ0qAG6bq1ng8T-5ULwPAyvIBsJhQyUQ7MXE',
	}
	function urlBase64ToUint8Array(base64String) {
	  const padding = '='.repeat((4 - base64String.length % 4) % 4);
	  const base64 = (base64String + padding)
	    .replace(/-/g, '+')
	    .replace(/_/g, '/');
	 
	  const rawData = window.atob(base64);
	  const outputArray = new Uint8Array(rawData.length);
	 
	  for (let i = 0; i < rawData.length; ++i) {
	    outputArray[i] = rawData.charCodeAt(i);
	  }
	  return outputArray;
	}
	 
	if ('serviceWorker' in navigator) {
		try{
			const url = './sw.js'
			let registrations = await navigator.serviceWorker.register(url)
			let subscription = await registrations.pushManager.subscribe({
				userVisibleOnly:true,
				applicationServerKey:urlBase64ToUint8Array(vapidKeys.publicKey)
			})
			localStorage.setItem("cws-subscription", JSON.stringify(subscription))
			return subscription
		}catch(err){
			console.log('ServiceWorker registration failed: ', err);
		}
	}
}

export default swDevSetup