
import CommonFieldData from './CommonFieldData'
import removeSpecialChar from './_special'

export default class Beneficiaries extends CommonFieldData{
  constructor(){
    super()
    this.field = {
      ...this.commonField,
      field_type:"beneficiaries",
      extra_details:[],
      validation:{
        ...this.commonField.validation,
      }
    }
  }
  
  loadData(data){
    if(this.field.hasOwnProperty('field_label')){
      this.field.field_label = data.field_label
      this.field.field_name = removeSpecialChar(data.field_label.toLowerCase().split(" ").join("_"))
    }
if(data.show_field){
      this.field.show_field = data.show_field
    }
    if(this.field.hasOwnProperty('order')){
      this.field.order = data.order
    }
    if(this.field.hasOwnProperty('validation')){
      this.field.validation = {
        required:data.validation.required, 
        condition_chack:data.validation.condition_chack,
        cond_logic:data.validation.cond_logic
      }
    }
    if(data.hasOwnProperty("_id")){
      this.field._id = data._id
    }
  }
  getData(){
    return this.field
  }
}



