import axios from '../axios'


export const getAllUser = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get('/user', {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const createUser = (user) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.post('/user', { ...user, preview: null, photo: null }, {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const getSingleUser = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/user/${_id}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}
export const uploadUserImage = (id, formData) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.patch(`/user/upload/${id}`, formData, {
    headers: {
      Authorization: token
    }
  })
  localStorage.setItem("cws_user", JSON.stringify(res.data))
  console.log(res, 'res update user image')
  return res
}

export const editUser = (user) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.patch(`/user/${user._id}`, { ...user }, {
    headers: {
      Authorization: token
    }
  })
  console.log(res, 'res update user')
  return res
}

export const deactivateUser = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.delete(`/user/${_id}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}