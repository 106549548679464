import React, {useState, useEffect} from 'react'
import { 
  Container,
  Grid,
  Paper,
  Tooltip,
  Fab,
  Link,
} from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import useStyles from '../../components/Global/useStyles'
import NavigationMenu from '../../components/Global/NavigationMenu'
import TableRole from '../../components/PageCompnent/Role/TableRole'
import {connect} from 'react-redux'
import {getAllRole, createRole, editRole, deactivateRole} from '../../store/actions/role'
import {addData, updateData} from '../../store/reducers/DataHandler'
import DeleteModal from '../../components/common/DeleteModal';
import _ from 'lodash'
import AddEditRole from './AddEditRole'
import checkPermission from '../../helper/checkPermission'

function Roles(props) {
  const classes = useStyles();
  const [role, setRole] = useState({})
  const [roles, setRoles] = useState([])
  const [openRole, setOpenRole] = useState(false)
  const [addEditModal, setAddEditModel] = useState({})
  const [openDelete, setOpenDelete] = useState(false)
  const [loader, setLoader] = useState(false)



  const handleRoleModal = (value,type="") => {
    if(type==="add"){
      setAddEditModel({
        title:"Add Role",
        actionButton:"Save",
        action:addRole
      })
    }
    setOpenRole(value)
  }
  
  function sortRole(roles){
    if(roles && roles.length > 0){
      return roles.sort((a,b) => {
        if(a.role > b.role)
          return 1
        else if(a.role<b.role)
          return -1
        else
          return 0
      })
    }
    return []
  } 
  function updateRole(form){
    let tempRole = _.cloneDeep(form)
    tempRole = _.omit(tempRole, ["permission"])
    props.editRole(tempRole).then(({data}) => {
      let tempRoles = updateData([...roles], data)
      setRoles(sortRole(tempRoles))
      setOpenRole(false)
    }).catch(err => {
      console.log(err)
    })
  }
  function editRoleModal(form){
    setAddEditModel({
      title:"Update Role",
      actionButton:"Update",
      action:updateRole,
      form:form
    })
    setOpenRole(true)
  }
  const addRole = (form,setForm) => {
      props.createRole(form).then(({data}) => {
      let tempRoles = addData(roles, data)
      setRoles(sortRole(tempRoles))
      setForm({role:""})
      setOpenRole(false)
    }).catch(err => {
      console.log('err',err)
    })
  }

  function deleteRole(_id) {
    props.deactivateRole(_id).then(({data}) => {
      let tempRoles = updateData([...roles], data)
      setRoles(sortRole(tempRoles))
      setOpenDelete(false)
    }).catch(err=>{
      console.log("err", err)
    })
  }
  function handleDeleteModal(value){
    setOpenDelete(value)
  }
 
  function deleteRoleSetup(role){
    setRole(role)
    setOpenDelete(true)
  }
  const getRoles = () =>{
    setLoader(true)
    props.getAllRole()
    .then(({data}) => {
      setRoles(sortRole(data))
      setLoader(false)
    }).catch(err => {
      console.log("err",err)
      setLoader(false)
    })
  }
  useEffect(() => {
    getRoles()
  },[])
  let can_see = checkPermission("can_get_role")
  let can_delete = checkPermission("can_delete_role")

  return (
    <>
      <div className={classes.root}>
        <NavigationMenu title="Role" />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}> 
            <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    {
                      can_see?
                      <TableRole 
                        data={roles}
                        editRoleModal = {(form) => editRoleModal(form)}
                        deleteRoleSetup={deleteRoleSetup}
                        loader={loader}
                      />:
                      <div style={{height:200}}>
                        <center>
                          <h2>You don't have permission to see Roles</h2>
                        </center>
                      </div>
                    }
                   
                  </Paper>
                </Grid>
              </Grid>
          </Container>
        </main>
          <Tooltip title='Add Role' aria-label='add-role' arrow style={{position: 'absolute', bottom: 25, right: 25}}>
            <Link href="#" onClick={() => handleRoleModal(true, "add")}>
              <Fab className="circleButton greebBtn">
                <GroupAddIcon />
              </Fab>
            </Link>
          </Tooltip>
        </div>
      <AddEditRole 
        open={openRole}
        handleRoleModal={handleRoleModal}
        modal={addEditModal}
      />
      {
        can_delete && 
        <DeleteModal
          title="Role"
          action={({_id}) => deleteRole(_id)}
          item={role.role}
          _id={role._id}
          handleModal={(value) => handleDeleteModal(value)}
          status={role.status}
          open={openDelete}
        />
      }
      
    </>
  )
} 

export default connect(null, {getAllRole, createRole, editRole,deactivateRole})(Roles)





