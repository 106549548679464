import React, { Component } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import {
  TextField,
  MenuItem,
  FormControl,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import { connect } from 'react-redux'
import { getSingleUser, editUser, uploadUserImage } from '../../store/actions/user'
import { getAllDesignation } from '../../store/actions/designation'
import { getAllRole } from '../../store/actions/role'
import Form from '../../components/Form/form'
import Wrapper from '../../components/Global/Wraper'
import SimpleReactValidator from 'simple-react-validator';
import { history } from '../../routes/history'
import { auth_url } from '../../store/mainaxios'
import _ from 'lodash'
import BackToLink from '../../components/common/BackToLink'
import checkPermission from '../../helper/checkPermission'
import ChangePassword from './ChangePassword'
import ClearIcon from '@material-ui/icons/Clear';

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roles: [],
      open: false,
      designations: [],
      user: {},
      loader: false,
      imagePreview: null,
      id: this.props.match.params.id,
      role: '',
      currentUser: {},
      showChangePassword: true
    }

    this.loadAllData = this.loadAllData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.validator = new SimpleReactValidator();
  }

  onClearImage = () => {
    this.setState(prevState => ({
      ...prevState,
      imagePreview: '',
      user: {
        ...prevState.form,
        image: ""
      }
    }))
  }
  loadAllData() {
    console.log('here')
    const _id = this.props.match.params.id
    const requests = [this.props.getSingleUser(_id), this.props.getAllDesignation(), this.props.getAllRole()]
    this.setState(prevState => ({ ...prevState, loader: true }))
    Promise.all(requests).then(async res => {
      // console.log(res[0].data.role, 'res load data')
      await this.setState(prevState => ({
        ...prevState,
        user: res[0].data,
        designations: res[1].data,
        roles: res[2].data,
        loader: false
      }))
      console.log(this.state, 'this.state.user.role.role')
      // console.log(this.state.user, 'check conditions')
      // console.log(this.state.currentUser._id === this.state.id, 'this.state.currentUser._id === this.state.id')
      if (this.state.currentUser.role.role == 'Admin') {
        console.log('if admin')
        this.setState(prevState => ({
          ...prevState,
          showChangePassword: true
        }))
      }
      else if (this.state.user.role.role !== 'Admin' && this.state.currentUser._id === this.state.id) {
        console.log('if not admin and id equal')
        this.setState(prevState => ({
          ...prevState,
          showChangePassword: true
        }))
      } else {
        console.log('else')
        this.setState(prevState => ({
          ...prevState,
          showChangePassword: false
        }))
      }

    }).catch(err => {
      this.setState(prevState => ({
        ...prevState,
        loader: false
      }))
      console.log("err", err)
    })
  }

  componentDidMount() {
    this.loadAllData()
    let user = JSON.parse(localStorage.getItem("cws_user"))
    this.state.currentUser = user
  }

  handleChange(event) {
    let name = event.target.name
    let value = event.target.value
    this.setState(prevState => ({
      ...prevState,
      user: { ...prevState.user, [name]: value },
    }));
    if (name === "photo") {
      let reader = new FileReader();
      value = event.target.files[0]
      reader.onloadend = () => {
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.user,
            [name]: value
          },
          imagePreview: reader.result
        }));
      }
      reader.readAsDataURL(value)
    } else {
      this.setState(prevState => ({
        ...prevState,
        form: { ...prevState.user, [name]: value },
      }));
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages(true)
      this.forceUpdate()
      return
    }
    this.setState((prevState) => ({ ...prevState, loader: true }))
    let user = _.cloneDeep(this.state.user)
    user.designation = user.designation.hasOwnProperty("_id") ? user.designation._id : user.designation
    user.role = user.role.hasOwnProperty("_id") ? user.role._id : user.role
    let tempUser = _.omit(user, ["photo", "preview"])
    console.log("tempUser", tempUser)
    this.props.editUser(tempUser).then(({ data }) => {
      if (this.state.imagePreview) {
        let formData = new FormData()
        formData.append("photo", user.photo)
        if (user.photo) {
          this.props.uploadUserImage(data._id, formData).then(() => {
            history.push('/user')
          })
        }
      } else {
        history.push('/user')
      }
      this.setState((prevState) => ({ ...prevState, loader: false }))
    }).catch(err => {
      this.setState((prevState) => ({ ...prevState, loader: false }))
      console.log("err", err)
    })
  }

  changePasswordModal = (value) => {
    this.setState(prevState => ({
      ...prevState,
      open: value
    }))
  }

  render() {
    const { loader, imagePreview, user } = this.state
    let can_edit = checkPermission('can_edit_user')
    return (
      <Wrapper
        maxWidth="sm"
        pageTitle={`${user.first_name} ${user.last_name} - Edit`}
        backLink={this.props.history.location.pathname.split('/')[1]}
      >
        {
          this.state.loader ?
            <Box style={{ textAlign: 'center' }}>
              <CircularProgress disableShrink />
            </Box>
            :
            <>
              <BackToLink
                isUser={true}
                changePasswordModal={this.changePasswordModal}
                link="/user"
                label="Back to all users"
                showChangePassword={this.state.showChangePassword}
                style={{ marginLeft: -8 }}
              />
              <Form
                onSubmit={this.handleSubmit}
              >
                <Box className="formControl">
                  <TextField
                    type='text'
                    label="First Name"
                    required
                    autoFocus
                    variant="outlined"
                    name='first_name'
                    value={user.first_name}
                    onChange={this.handleChange}
                  />
                  <Box component="span" className="validationMessage">
                    {this.validator.message('First Name', user.first_name, 'required')}
                  </Box>
                </Box>
                <Box className="formControl">
                  <TextField
                    type='text'
                    label="Last Name"
                    required
                    variant="outlined"
                    name='last_name'
                    value={user.last_name}
                    onChange={this.handleChange}
                  />
                  <Box component="span" className="validationMessage">
                    {this.validator.message('Last Name', user.last_name, 'required')}
                  </Box>
                </Box>
                <Box className="formControl">
                  <TextField
                    type='email'
                    label="Email"
                    placeholder="exampl@xyz.com"
                    required
                    variant="outlined"
                    name="email"
                    value={user.email}
                    onChange={this.handleChange}
                  />
                  <Box component="span" className="validationMessage">
                    {this.validator.message('Email', user.email, 'required|email')}
                  </Box>
                </Box>
                <Box className="formControl">
                  <TextField
                    type='tel'
                    label="Mobile No."
                    inputProps={{
                      maxLength: 10,
                    }}
                    variant="outlined"
                    name="mobile"
                    value={user.mobile}
                    onChange={this.handleChange}
                  />
                  <Box component="span" className="validationMessage">
                    {this.validator.message('Mobile No.', user.mobile, 'required')}
                  </Box>
                </Box>
                <Box className="formControl">
                  <TextField
                    type='text'
                    label="Employee ID"
                    variant="outlined"
                    name="employeeId"
                    value={user.employeeId}
                    onChange={this.handleChange}
                  />
                  <Box component="span" className="validationMessage">
                    {this.validator.message('Employee ID', user.employeeId, 'required')}
                  </Box>
                </Box>
                <Box className="formControl">
                  <FormControl>
                    {/* <InputLabel id="designation-label">Designation</InputLabel> */}
                    <TextField
                      labelId="designation"
                      id="designation"
                      select
                      label="Designation"
                      variant="outlined"
                      name="designation"
                      value={user.designation && user.designation.hasOwnProperty("_id") ? user.designation._id : user.designation}
                      onChange={this.handleChange}
                    >
                      {this.state.designations.map(designation => {
                        return <MenuItem key={designation._id} value={designation._id}>{designation.name}</MenuItem>
                      })}
                    </TextField>
                    <Box component="span" className="validationMessage">
                      {this.validator.message('Designation', user.designation, 'required')}
                    </Box>
                  </FormControl>
                </Box>
                <Box className="formControl">
                  <FormControl>
                    {/* <InputLabel id="role-label">Role</InputLabel> */}
                    <TextField
                      labelId="role"
                      id="role"
                      select
                      label="Role"
                      name="role"
                      value={user.role && user.role.hasOwnProperty("_id") ? user.role._id : user.role}
                      onChange={this.handleChange}
                      variant="outlined"
                    >
                      {this.state.roles.map(role => {
                        return <MenuItem key={role._id} value={role._id}>{role.role}</MenuItem>
                      })}
                    </TextField>
                    <Box component="span" className="validationMessage">
                      {this.validator.message('Role', user.role, 'required')}
                    </Box>
                  </FormControl>
                </Box>
                <Box className="formControl imageUpload">
                  {
                    imagePreview ?
                      <>
                        <Box className="photoBox">
                          <img
                            src={
                              imagePreview ? imagePreview : ''
                            }
                            alt=""
                          />
                          <ClearIcon onClick={this.onClearImage} size="small" />
                        </Box>
                      </>
                      :
                      <>
                        <Box className="photoBox">
                          <img
                            src={
                              user.photo ? `https://auth.wildconnect.in/${user.photo}` : ''
                            }
                            alt=""
                          />
                          <ClearIcon onClick={this.onClearImage} size="small" />
                        </Box>
                      </>
                  }

                  <label for="image-upload" className="customFileUpload">
                    <span>Select User Image</span>
                    <AccountBoxOutlinedIcon size="small" />
                  </label>
                  <TextField
                    id='image-upload'
                    type='file'
                    inputProps={{
                      accept: "image/x-png,image/gif,image/jpeg"
                    }}
                    name="photo"
                    onChange={this.handleChange}
                  />
                </Box>
                {
                  can_edit &&
                  <Button
                    type="submit"
                    variant="contained"
                    className="customBtn greebBtn"
                    onClick={this.handleSubmit}
                  >
                    Update
                  </Button>
                }
                {/* this.state.role == 'Admin' || */}
              </Form>
              <ChangePassword
                data={this.state.user}
                open={this.state.open}
                changePasswordModal={this.changePasswordModal}
              />
            </>
        }
      </Wrapper>
    )
  }
}

export default connect(null, { getSingleUser, uploadUserImage, editUser, getAllDesignation, getAllRole })(withRouter(EditUser))
