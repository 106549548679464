class CommonFieldData{
  constructor(){
    this.commonField = {
      field_label:"",
      field_name:"",
      placeholder:"",
      order:"",
      default:"",
      select_url:"",
      extra_details:"",
      validation:{
        required:false,
        condition_chack:"",
        cond_logic:[]
      }
    }
  }
}

export default CommonFieldData