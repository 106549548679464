import React from 'react'
import { 
  Container,
  CssBaseline,
  Box,
} from '@material-ui/core';
import useStyles from '../../components/Global/useStyles'
import NavigationMenu from '../../components/Global/NavigationMenu'

const Wraper = props => {
  const classes = useStyles()
  const background = props.backgroundColor ? props.backgroundColor : null
  return (
    <Box className={classes.root}>
      <CssBaseline />
      <NavigationMenu 
        title={props.pageTitle} 
        backLink={props.backLink} 
        secondParent={props.secondParent && props.secondParent}
        secondBackLink={props.secondBackLink && props.secondBackLink}
      />
      <main className={classes.content} style={{backgroundColor: background}}>
        <Box className={classes.appBarSpacer} />
          <Container maxWidth={props.maxWidth ? props.maxWidth : 'lg'} className={classes.container}> 
            {props.children}
          </Container>
      </main>
    </Box>
  )
}

export default Wraper


