import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Box,
 } from '@material-ui/core'
import TableActionIcons from '../../common/TableActionIcons'

import '../../../scss/_table.scss'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);

const TableRole = (props) => {

  useEffect(() => {
  }, [props.data])

 
  return (
    <React.Fragment>
      <Box>
        <TableContainer>
          <Table size="small" aria-label="user table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>Users</StyledTableCell>
                <StyledTableCell>Number of Permissions</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {
              props.loader ? 
              <TableBody>
                <TableRow>
                  <TableCell align="center" width="100%" colSpan={12}>
                  <Box>
                    <CircularProgress disableShrink />
                  </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
              :
              <TableBody>
              {props.data.map((role, i) => (
                <TableRow key={i}>
                  <TableCell
                    onClick={() => props.history.push(`/role/permission/${role._id}`)} 
                    className="tableLink"
                  >
                    {role.role}
                  </TableCell>
                  <TableCell>---</TableCell>
                  <TableCell>
                    {role.permission && Object.keys(role.permission).filter(permission => role.permission[permission]===true).length}
                  </TableCell>
                  <TableCell>
                    <Box component="span" className={`${role.status ? "greenBtn" : "inactiveBtn"} statusBtn`}>
                      {role.status ? "active": "Inactive"}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <TableActionIcons 
                      data={role}
                      getSuspendedData={props.deleteRoleSetup} 
                      handleAction={props.editRoleModal} 
                      deleteModal={props.handleModal}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            }
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}

export default withRouter(TableRole)