import { LOGIN, CHECKTOKEN, LOGOUT } from "./type";
import axios from "../axios";
import { history } from "./../../routes/history";
import _ from "lodash";

export const loginUser = (user) => async (dispatch) => {
  let tempUser = _.cloneDeep(user);
  let res = await axios.post("/login", tempUser);

  const permission = res?.data?.user?.role?.permission;

  if (permission?.can_access_dashboard !== true) {
    throw new Error("You don't have permission to access dashboard");
  }

  localStorage.setItem("cws_user", JSON.stringify(res.data.user));
  localStorage.setItem("cws_token", res.data.token);
  localStorage.setItem("cws_isLogin", true);
  dispatch({
    payload: res.data,
    type: LOGIN,
  });
  history.push("/dashboard");
  return res;
  // axios.post('/login', tempUser).then(({ data }) => {
  //   localStorage.setItem("cws_user", JSON.stringify(data.user))
  //   localStorage.setItem("cws_token", data.token)
  //   localStorage.setItem('cws_isLogin', true)
  //   dispatch({
  //     payload: data,
  //     type: LOGIN
  //   })
  //   history.push('/dashboard')
  //   return
  // }).catch(err => {
  //   console.log("err", err)
  //   return
  // })
};

export const checkToken = () => async (dispatch) => {
  let token = localStorage.getItem("cws_token");
  if (!token) {
    localStorage.setItem("cws_user", null);
    localStorage.setItem("cws_token", "");
    localStorage.setItem("cws_isLogin", false);
    history.push("/dashboard");
    dispatch({
      payload: false,
      type: CHECKTOKEN,
    });
    return;
  }

  axios
    .post("/user/verify", { token: token })
    .then(({ data }) => {
      dispatch({
        payload: true,
        type: CHECKTOKEN,
      });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        console.log("err.response", err.response);
        localStorage.setItem(
          "cws_user",
          JSON.stringify(err.response.data.user)
        );
        localStorage.setItem("cws_token", err.response.data.token);
        localStorage.setItem("cws_isLogin", true);
        dispatch({
          payload: err.response.data,
          type: LOGIN,
        });
      } else {
        localStorage.setItem("cws_user", null);
        localStorage.setItem("cws_token", "");
        localStorage.setItem("cws_isLogin", false);
        dispatch({
          payload: false,
          type: CHECKTOKEN,
        });
        history.push("/login");
      }
    });
};

export const logOut = (vm) => async (dispatch) => {
  localStorage.setItem("cws_user", null);
  localStorage.setItem("cws_token", "");
  localStorage.setItem("cws_isLogin", false);
  vm.setOpenlogout(false);
  history.push("/login");
  dispatch({
    type: LOGOUT,
  });
};
export const changePassword = (user) => async () => {
  let res = await axios.post(`/user/change_password`, user);
  return res;
};
