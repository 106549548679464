import React, {useEffect} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import CollapseTable from './CollapseTable'
import CollapseTableEdit from './CollapseTableEdit'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    backgroundColor: 'white'
  },
}))(TableCell);


export default function TableNewForm(props) {
  const { allStructure,errorFormData, savedData, errorMsg, copyForm } = props

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Order</StyledTableCell>
              <StyledTableCell>Label</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody> */}
            {
              allStructure ? 
              <CollapseTableEdit errorFormData ={errorFormData} setIsError={props.setIsError} isError={props.isError} allStructure={allStructure} savedData={savedData} errorMsg={errorMsg} />
              :
              <CollapseTable errorFormData ={errorFormData} setIsError={props.setIsError} isError={props.isError} savedData={savedData} errorMsg={errorMsg} copyForm={copyForm} />
            }
          {/* </TableBody> */}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
