import React, {useState, useEffect} from 'react'
import {
  makeStyles,
  TableRow,
  TableCell,
  Box,
} from '@material-ui/core'
import '../../../scss/_collapseTable.scss'
import DeleteDialog from './DeleteDialog'
import DragController from './DragController'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const CollapseTableVisibleHeader = (props) => {
  const no_label = 'no label'
  const no_name = ''
  const classes = useRowStyles();
  const [openDelete, setOpenDelete] = useState(false)

  useEffect(() => {
    
  }, [])

  function handleDeleteModal(value){
    setOpenDelete(value)
  }
  const deleteStructure = (item) => {
    props.onDelete(item)
    setOpenDelete(false);
  }
  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root, `collapseTableWapper ${(props.open === props.index) && `openCollpse`}`}>
        <TableCell className='order'>
          <DragController 
            order={props.index + 1}
          />
        </TableCell>
        <TableCell className='tableCell'>
          <Box
            className="tableLable"
            onClick={props.openCollpase}>
            {props.item.field_label === '' ? no_label : props.item.field_label}
          </Box>
          {/* <Box component="span" className='rowAction'>Edit</Box> */}
          {
            props.dialog ? 
            (<Box component="span" className='rowAction rowDelete' onClick={handleClickOpen}>Delete</Box>)
            :
            <Box component="span" className='rowAction rowDelete' onClick={props.onDelete}>Delete</Box>
          }
          {props.item && props.item.error?<Box style={{color:'red', marginTop:10}}>{props.item.error}</Box>:""}
        </TableCell>
        <TableCell align="left">
          {props.item.field_name === '' ? no_name : props.item.field_name}
        </TableCell>
        <TableCell align="left">
          {props.item.field_type ? props.item.field_type : 'Text'}
        </TableCell>
      </TableRow>
      <DeleteDialog 
        handleModal={(value) => handleDeleteModal(value)}
        handleDelete={() => deleteStructure(props.item)}
        open={openDelete}
        item={props.item}
        _id={props.item._id}
      />
    </React.Fragment>
  )
}

export default CollapseTableVisibleHeader
