import React, { useState } from 'react'
import {
  AppBar,
  Tab,
  Tabs,
  createMuiTheme,
  MuiThemeProvider,
  Box,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import TabPanel from '../../Tabs/TabPanel'
import RightDrawer from '../../Global/RightDrawer'
import SelectBeneficiary from './SelectBeneficiary'
import AddEditBeneficiaryType from './AddEditBeneficiaryType'
import AddEditLocationType from './AddEditLocationType'
import AddNewTeamMember from './AddNewTeamMember'
import AddEditNewGoal from './AddEditNewGoal'
import AddEditCaseType from './AddEditCaseType'
import AddEditSurveyType from './AddEditSurveyType'
import AddEditEventType from './AddEditEventType'
import AddEditPermissionFile from './AddEditPermissionFile'
import AddEditNewNotification from './AddEditNewNotification';

const BeneficiariesDrawerTab = props => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createNewType = (param) => {
    switch (param) {
      case 'Beneficiary':
        return <AddEditBeneficiaryType
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;
      case 'Location':
        return <AddEditLocationType
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;
      case 'Event':
        return <AddEditEventType
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;
      case 'Case':
        return <AddEditCaseType
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;

      case 'Survey':
        return <AddEditSurveyType
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;

      case 'Permission':
        return <AddEditPermissionFile
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          items={props.items}
          item={props.item ? props.item : null}
        />;

      case 'Team':
        return <AddNewTeamMember
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          items={props.items}
          item={props.items ? props.items : null}
        />;
      case 'Goal':
        return <AddEditNewGoal
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;
      case 'Notification':
        return <AddEditNewNotification
          handleSubmitData={props.handleSubmitData}
          handleDrawer={props.handleDrawer}
          isopen={props.isDrawerOpen}
          title={props.add_title}
          forms={props.forms}
          item={props.item ? props.item : null}
        />;


      default:
        return (
          <Box style={{ textAlign: 'center', color: '#adadad', marginTop: 25 }}>
            No form found
          </Box>
        );
    }
  }

  const headingIcon = (param) => {
    switch (param) {
      case 'Beneficiary':
        return <PeopleIcon />;
      case 'Location':
        return <LocationOnIcon />;
      case 'Event':
        return <CalendarTodayOutlinedIcon />;
      case 'Case':
        return <DescriptionOutlinedIcon />;
      case 'Notification':
        return <DescriptionOutlinedIcon />;
      case 'Survey':
        return <AssessmentOutlinedIcon />;
      case 'Permission':
        return <FileCopyOutlinedIcon />
      case 'Team':
        return <AccountCircleOutlinedIcon />;
      case 'Goal':
        return <img src="/icon/push-pin.svg" alt="pin" style={{ width: 18 }} />;
      default:
        return null
    }
  }

  return (
    <RightDrawer
      handleDrawer={props.handleDrawer}
      buttonLabel={props.buttonLabel}
      className="rightdrawerMain"
      isDrawerOpen={props.isDrawerOpen}
    >
      <Box className="headingWrap">
        {/* <PeopleIcon /> */}
        {headingIcon(props.title)}
        <span className="heading">
          {
            props.name &&
              props.name === 'Team' || props.name === 'Goal' ?
              <h3>{`ADD NEW ${props.name} ${(props.name === 'Team') ? 'MEMBER' : ''}`}</h3>
              :
              <h3>{props.name}</h3>
          }
        </span>
      </Box>
      {
        props.noTab ?
          <Box>
            {createNewType(props.title)}
          </Box>
          :
          <Box className="tabContainerMain">
            <AppBar position="static" color="default">
              <MuiThemeProvider theme={theme}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="drawer-details-tab"
                  indicatorColor="secondary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  className="beneficiaryTab"
                >
                  {
                    props.existing ?
                      <Tab label={props.existing} {...a11yProps(0)} className="leftAlignTab" />
                      : null
                  }
                  <Tab label={props.add_title} {...a11yProps(props.existing ? 1 : 0)} className="leftAlignTab" />
                </Tabs>
              </MuiThemeProvider>
            </AppBar>
            <Box>
              {
                props.existing ?
                  (
                    <TabPanel value={value} index={0}>
                      <Box className="TabContentMain">
                        <SelectBeneficiary
                          addItemTypeToProgram={props.addItemTypeToProgram}
                          data={props.data}
                          name={props.name}
                        />
                      </Box>
                    </TabPanel>
                  ) : null
              }

              <TabPanel value={value} index={props.existing ? 1 : 0}>
                <Box className="TabContentMain">
                  {
                    createNewType(props.title)
                  }
                </Box>
              </TabPanel>
            </Box>
          </Box>

      }

    </RightDrawer>
  )
}

export default BeneficiariesDrawerTab;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#444'
    },
    secondary: {
      main: '#000'
    }
  }
});