import React, {useEffect, useState} from 'react'
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  CircularProgress,
  Box,
  IconButton,
 } from '@material-ui/core'
 import SearchIcon from '@material-ui/icons/Search';

 import '../../../scss/_table.scss'

const SelectBeneficiary = props => {
  const [loader, setLoader] = useState(true)
  const [searchData, setSearchData] = useState('')

  useEffect(() => {
    setLoader(false)
    setSearchData(props.data)
  }, [props.data])

  const selectFormToProgram = (item) =>{
     props.addItemTypeToProgram(item)
  }

  const filterData = event => {
    const updateList = props.data && props.data.filter(item => {
      const name = item.name.toLowerCase()
      return (
        name.search(event.target.value.toLowerCase()) !== -1 
      )
    })
    setSearchData(updateList && updateList.length > 0 && updateList)
  }

  return (
    <Box className="selectBeneficiary">
      <Box className="searchBoxTable">
        <input 
          type="text" 
          name="searchData" 
          placeholder="Search" 
          className="serachInput"
          onChange={filterData}
        />
        <IconButton className="iconButton" type="submit" aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>
      <TableContainer>
        <Table size="medium" aria-label="beneficiary table">
          {
            loader ?
            <TableBody>
              <TableRow>
                <TableCell align="center" width="100%" colSpan={12}>
                <Box>
                  <CircularProgress disableShrink />
                </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            :
            <TableBody>
              {
                searchData ?
                searchData.map((item, key)=> (
                  <TableRow key={key}>
                    <TableCell className="singleCellLeftRightItems tableLink" onClick={()=>selectFormToProgram(item)}>
                      <Box className="topNameBottomDesc">
                        <span className="name">
                          {item.name}
                        </span>
                        {item.description?
                          (<span className="description">
                          {/* {item.description && item.description} */}
                          {
                            item.description.length > 46 ?
                            `${item.description.substring(0, 46)}...` : item.description
                          }
                          </span>):null
                        }
                        
                      </Box>
                      <Box>
                        <span className="select">Select</span>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
                : 
                <Box style={{textAlign: 'center', color: '#adadad', marginTop: 15}}>
                  {`No ${props.name} found`}
                </Box>
              }
            </TableBody>
          }
          <TableFooter>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default withRouter(SelectBeneficiary)
