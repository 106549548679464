import CommonFieldData from './CommonFieldData'
import removeSpecialChar from './_special'

class Signature extends CommonFieldData{
	constructor(){
    super()
    this.field = {
      ...this.commonField,
      field_type:"signature",
      validation:{
        ...this.commonField.validation,
      }
    }
 }
  loadData(data){
    if(this.field.hasOwnProperty('field_label')){
      this.field.field_label = data.field_label
      this.field.field_name = removeSpecialChar(data.field_label.toLowerCase().split(" ").join("_"))
    }
if(data.show_field){
      this.field.show_field = data.show_field
    }
    if(this.field.hasOwnProperty('placeholder')){
      this.field.placeholder = data.placeholder
    }
    if(this.field.hasOwnProperty('order')){
      this.field.order = data.order
    }
    if(data.hasOwnProperty("_id")){
      this.field._id = data._id
    }
  }
  getData(){
    return {...this.field}
  }

}

export default Signature