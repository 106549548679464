import {GETALLROLE, GETSINGLEROLE, CREATEROLE, UPDATEROLE } from "../actions/type"
import {addData} from './DataHandler'

let INTIAL_STATE={
  users:[],
  isLogin:false,
  user:{},
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){

    case GETALLROLE:
      return {...state, roles:action.payload}

    case GETSINGLEROLE:
      return {...state}

    case CREATEROLE:
      state.roles = addData(state.roles, action.payload)
      return {...state}
    
    case UPDATEROLE:
      return {...state}

    default:
      return state
  }
}