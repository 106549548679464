import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  TextField, 
  makeStyles, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Box,
} from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator'
import '../../scss/_dialog.scss'
import checkPermission from './../../helper/checkPermission'


const AddEditDesignation = props => {
  const classes = useStyles();
  const [, forceUpdate] = useState()
  const [form, setForm] = useState({
    name:""
  })

  const simpleValidator = useRef(new SimpleReactValidator())

  useEffect(() => {
    if(props.modal.form){
      setForm(props.modal.form)
    }else{
      setForm("")
    }
  }, [props.modal.form])

  const onChangeInput = (event) =>{
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
  }
 
  const handleSubmit = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    props.modal.action(form, setForm)
  }
  
  return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        aria-labelledby="max-width-dialog-title"
        onClose={() => props.handleDesignationModal(false)}
        className="customDialog"
      >
        <DialogTitle id="responsive-dialog-title">{props.modal.title}</DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.textInput}
              autoFocus
              margin="dense"
              variant="outlined"
              name="name"
              label="Designation"
              value={form.name}
              onChange={onChangeInput}
              type="text"
            />
            <Box style={{color: 'red'}}>
              {simpleValidator.current.message('Designation', form.name, 'required')}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          {
            props.modal.can_perform &&
            <Button onClick={handleSubmit} color="primary" autoFocus className="greenBtn">
              {props.modal.actionButton}
            </Button>
          }
          <Button autoFocus onClick={() => props.handleDesignationModal(false)} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default (AddEditDesignation)

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
  },
  textInput:{
    width:'100%'
  }
}));
