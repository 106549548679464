import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  Box,
  TextField,
  MenuItem, Card
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NavigationMenu from '../../components/Global/NavigationMenu'
import useStyles from '../../components/Global/useStyles'
import DashboardTable from '../../components/PageCompnent/Dashboard/DashboardTable'
import { getAllSyncDataFromCouch, getAllProgram } from '../../store/actions/program'
import { connect } from 'react-redux'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { getStats, getLatestStats } from '../../store/actions/dashboard'

import '../../scss/_dashboard.scss'

const Dashboard = (props) => {
  const [loader, setLoader] = useState(false)
  const [syncData, setSyncData] = useState([])
  const [programs, setPrograms] = useState([])
  const [selectPrograms, setSelectPrograms] = useState([])
  const [modulesDoc, setModulesDoc] = useState([])
  const [docs, setDocs] = useState([])
  const [stats, setStats] = useState({})
  const classes = useStyles();
  const [form, setForm] = useState({
    program: '',
    program_module: ''
  })

  const loadDataFromCouch = () => {
    // props.getAllSyncDataFromCouch().then(({data}) => {
    //   setModulesDoc(data)
    //   let tempPrograms = []
    //   data.forEach(cell => {
    //     cell.data.forEach(document => {
    //       if(document.id === "structure"){
    //         tempPrograms = [...tempPrograms, document.doc]
    //       }else{

    //       }
    //     })
    //   })
    //   setPrograms(tempPrograms)
    //   setSyncData(data)
    //   setLoader(false)
    // }).catch(err => console.log('err', err))
  }
  const getAllStats = () => {
    props.getStats().then(res => {
      console.log(res, 'res')
      setStats(res.data)
    }).catch(err => {
      console.log(err, 'err')
    })
  }

  const getUpdatedStats = () => {
    setLoader(true)
    props.getLatestStats().then(({ data }) => {
      setDocs(data)
      setLoader(false)
    })
  }
  useEffect(() => {
    // getAllProgram()
    loadDataFromCouch()
    getAllStats()
    getUpdatedStats()
  }, [])


  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }
  const handleEmpty = (event, method) => {
    if (method === "program") {
      setForm(prev => ({
        ...prev,
        'program': ''
      }))
    } else {
      setForm(prev => ({
        ...prev,
        program_module: ""
      }))
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavigationMenu title="Dashboard" />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Grid container
            direction="row"
            justify="center"
            alignItems="center" >
            <Grid item xs={4} className="dashboardGrid">
              <Card className={classes.root} className="customCard" variant="outlined">
                <CardContent>
                  <div className={classes.title} className="title" color="textSecondary" gutterBottom>
                    {stats.programsCount}
                  </div>
                  <div variant="h5" component="h2">
                  </div>
                  <div className={classes.pos} className="caption" color="textSecondary">
                    Number of Programs
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className="dashboardGrid">
              <Card className={classes.root} className="customCard" variant="outlined">
                <CardContent>
                  <div className={classes.title} className="title" color="textSecondary" gutterBottom>
                    {stats.formsCount}
                  </div>
                  <div variant="h5" component="h2">
                  </div>
                  <div className={classes.pos} className="caption" color="textSecondary">
                    Number of Custom Forms
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} style={{ paddingBottom: '20px' }}>
              <Card className={classes.root} className="customCard" variant="outlined">
                <CardContent>
                  <div className={classes.title} className="title" color="textSecondary" gutterBottom>
                    {stats.totalDocumentCount}
                  </div>
                  <div variant="h5" component="h2">
                  </div>
                  <div className={classes.pos} className="caption" color="textSecondary">
                    Number of Documents Saved
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Box className="tableContainer">
                      <div style={{ fontSize: '20px' }}>Recently Processed Documents</div>
                      <DashboardTable
                        modulesDoc={docs}
                        form={form}
                        data={docs}
                        loader={loader}
                        showFooter={false}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default connect(null, { getStats, getLatestStats })(Dashboard)
