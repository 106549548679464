import {} from './type'
import axios from '../mainaxios'


export const getAllBenificiaryType = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('beneficiarytype', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleBenificiaryType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`beneficiarytype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createBenificiaryType = (beneficiarytype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('beneficiarytype', beneficiarytype, {
    headers:{
      Authorization: token
    }
  })
}
export const editBenificiaryType = (beneficiarytype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`beneficiarytype/${beneficiarytype._id}`, beneficiarytype, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteBenificiaryType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`beneficiarytype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
