import React,{useState, useEffect} from 'react'
import { 
  TextField,
  MenuItem,
  List,
  ListItem,
  Checkbox,
  Button,
  FormControlLabel,
  Box,
 } from '@material-ui/core';
 import Wrapper from '../../components/Global/Wraper'
 import Form from '../../components/Form/form'
 import {history} from '../../routes/history'
 import BackToLink from '../../components/common/BackToLink'
 import {connect} from 'react-redux'
 import {getAllForm} from './../../store/actions/form'
 import {createBenificiaryType} from './../../store/actions/beneficiary'

 import _ from 'lodash'

const AddBeneficiaryType = (props) => {
  const [form, setForm] = useState({
    name: '',
    plural_name: '',
    form: '',
    non_mandatory_fields:{},
  })
  const [forms, setForms] = useState([]) 

  const [optional, setOptional] = useState({
    secondary_contact_number: false,
    education_level: false,
    address: false,
    location_field: false,
    occupation: false,
  })

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
  }
  const handleChangeOption = event => {
    let name = event.target.name
    let checked = event.target.checked
    setOptional({...optional, [name]:checked})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = _.cloneDeep(form)
    data.non_mandatory_fields = optional

    // props.createBenificiaryType(data).then(() => {
    //   history.push('/beneficiaries')
    // }).catch(err => {
    //   console.log("err", err, err.response)
    // })
  }

  const getAllForms = () => {
    props.getAllForm().then(({data}) => {
      setForms(data)
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  useEffect(() => {
    getAllForms()
  },[])
  return (
    <Wrapper 
        pageTitle="Add beneficiary type" 
        maxWidth="sm"
        backLink={history.location.pathname.split('/')[1]}
      >
        <BackToLink 
          link="/beneficiaries"
          label="Back to all beneficiaries"
          style={{marginLeft: -8}}
        />
        <Form 
          onSubmit={handleSubmit} 
          formHeading="Add beneficiary type"
          className="beneficiaryTypeForm"
        >
          <Box className="formControl">
            <TextField 
              type='text'
              label="Name"
              required
              variant="outlined"
              name='name'
              value={form.name}
              onChange={handleChange}
            />
          </Box>
          <Box className="formControl">
            <TextField 
              type='text'
              label="Plural Name"
              required
              variant="outlined"
              name='plural_name'
              value={form.plural_name}
              onChange={handleChange}
            />
          </Box>
          <Box className="optionalField">
            <Box>
            <Box className="formControlHeading">
              <h3>Mandatory Fields:</h3>
            </Box>
            <List>
              <ListItem className="formControl">First Name</ListItem>
              <ListItem className="formControl">Last Name</ListItem>
              <ListItem className="formControl" >Mobile Number</ListItem>
              <ListItem className="formControl" >Gender</ListItem>
              <ListItem className="formControl" >Date of Birth</ListItem>
              <ListItem className="formControl" >Photo</ListItem>
            </List>
            </Box>
            <Box className="formControlHeading">
              <h3>Non Mandatory Fields:</h3>
            </Box>
            <Box className="formControl">
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={optional.secondary_contact_number } 
                    onChange={handleChangeOption} 
                    name="secondary_contact_number" 
                    color='primary'
                  />
                }
                label="Secondary Contact Number"
              /> 
            </Box>
            <Box className="formControl">
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={optional.education_level } 
                    onChange={handleChangeOption} 
                    name="education_level" 
                    color='primary'
                  />
                }
                label="Education Level "
              /> 
            </Box>
            <Box className="formControl">
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={optional.address} 
                    onChange={handleChangeOption} 
                    name="address" 
                    color='primary'
                  />
                }
                label="Address Field"
              /> 
            </Box>
            <Box className="formControl">
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={optional.location_field} 
                    onChange={handleChangeOption} 
                    name="location_field" 
                    color='primary'
                  />
                }
                label="Location Field"
              /> 
            </Box>
            <Box className="formControl">
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={optional.occupation} 
                    onChange={handleChangeOption} 
                    name="occupation" 
                    color='primary'
                  />
                }
                label="Occupation"
              /> 
            </Box>
          </Box>
          <Box className="formControl">
            <TextField
              id="attach-custom-form"
              select
              label="Attach Custom Form"
              name="form"
              onChange={handleChange}
              variant="outlined"
            >
              {
                forms.map(form => (
                <MenuItem key={form._id} value={form._id}>
                  {form.name}
                </MenuItem>
                ))
              }
            </TextField>
          </Box>
          <Box style={{paddingTop: 10, paddingBottom: 10}}>
            <Button
              type="submit"
              variant="contained"
              className="customBtn greebBtn"
              // className={classes.submit}
              onClick={handleSubmit}
            >
              Save
            </Button>
            </Box>
        </Form>
      </Wrapper>
  )
}

export default connect(null, {getAllForm, createBenificiaryType})(AddBeneficiaryType)
