import React from 'react'
import {
  Box,
} from '@material-ui/core'

const DefaultValue = ({value, onChange}) => {
  return (
    <React.Fragment>
      <Box className='innerFormContainer'>
        <Box className='innerFormItem'>
          <label>Default Value</label>
          <span>Leave blank for no limit</span>
        </Box>
        <Box className='innerFormItem'>
          <input
            type="text"
            name="default"
            value={value}
            onChange={onChange}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default DefaultValue
