import React from "react";
import {
  TableBody,
} from '@material-ui/core'
import { sortableContainer } from "react-sortable-hoc";

const DragableTableBody = ({ children }) => {
  return <TableBody>{children}</TableBody>;
};

export default sortableContainer(DragableTableBody);
