import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  TableFooter,
  CircularProgress,
  Box,
} from '@material-ui/core'
import DashboardRightDrawer from './DashboardRightDrawer'
import { connect } from 'react-redux'
import { getDocById } from '../../../store/actions/exports'

import '../../../scss/_table.scss'
import TablePaginationActions from '../../common/TablePaginationActions';
import moment from 'moment'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);

const ExplorerTable = (props) => {

  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [data, setData] = useState({})
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const temp = props.data.slice(0).sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt));
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }

  const handleDrawerData = (isOpen, data) => {
    props.getDocById(data.local_id).then(async res => {
      await handleDrawer(isOpen)
      await setData(res.data)
    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.changePage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
  }, [props.data])

  useEffect(() => {
    setPage(0)
  }, [props.resetPage])
  const date = value => {
    return moment(value).format("DD-MM-YYYY")
  }

  const slugFormat = value => {
    let str = ''
    str = value.split('_').join(' ')
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');

  }

  return (
    <React.Fragment>
      {
        props.loader ?
          <Box style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
            <CircularProgress disableShrink />
          </Box>
          :
          <Box className="dashboardTable">
            <TableContainer>
              <Table size="medium" aria-label="dashboard table" className="programTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Program</StyledTableCell>
                    <StyledTableCell>Module</StyledTableCell>
                    <StyledTableCell>Document Type</StyledTableCell>
                    <StyledTableCell>Document ID</StyledTableCell>
                    <StyledTableCell>Created By</StyledTableCell>
                    <StyledTableCell>Dated</StyledTableCell>
                  </TableRow>
                </TableHead>
                {
                  props.loader ?
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" width="100%" colSpan={12}>
                          <Box>
                            <CircularProgress disableShrink />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    :
                    <TableBody>
                      {temp.map((item, i) => {
                        return (
                          <TableRow key={i}
                            onClick={() => handleDrawerData(true, item)}>
                            <TableCell
                              className="tableLink"
                            >{slugFormat(item.program_slug)}</TableCell>
                            <TableCell className="tableLink">{item.doc_type}</TableCell>
                            <TableCell className="tableLink">
                              <div>{item.beneficiary_type_name ? item.beneficiary_type_name : ''}</div>
                              <div>{item.case_type_name ? item.case_type_name : ''}</div>
                              <div>{item.event_type_name ? item.event_type_name : ''}</div>
                              <div>{item.survey_type_name ? item.survey_type_name : ''}</div>
                              <div>{item.location_type_name ? item.location_type_name : ''}</div>
                            </TableCell>
                            <TableCell className="tableLink">
                              {item.local_id}
                            </TableCell>
                            <TableCell className="tableLink">
                              {item.user_object.first_name + ' ' + item.user_object.last_name}
                            </TableCell>
                            <TableCell className="tableLink">
                              {date(item.createdAt)}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      }
                    </TableBody>
                }
                {props.showFooter ? <>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        style={{ borderBottom: 0 }}
                        rowsPerPageOptions={[]}
                        // rowsPerPageOptions={[10, 20, 30, { label: 'All', value: props.data.length }]}
                        colSpan={8}
                        count={props.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        totalDocs={props.totalDocs}
                        labelRowsPerPage='Documents per page'
                        onChangePage={handleChangePage}
                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </> : null}
              </Table>
            </TableContainer>
            <Box>
              <DashboardRightDrawer
                handleDrawer={handleDrawer}
                data={data}
                isDrawerOpen={isDrawerOpen}
              />
            </Box>
          </Box>
      }
    </React.Fragment>
  );
}

export default withRouter(connect(null, { getDocById })(ExplorerTable))