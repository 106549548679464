import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DeactivateFormDialog = (props) => {
  const {item} = props

  useEffect(() => {
    // console.log(props, 'props../')
  }, [])
  
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        onClose={() => props.handleModal(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to deactivate: {item && item.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleModal(false)} color="default">
            Cancel
          </Button>
          <Button onClick={() => props.deactivateForm()} color={item.status?"primary":"secondary"} autoFocus>
            {item.status?"Deactivate": "Activate"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeactivateFormDialog
