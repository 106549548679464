import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';
import MuiAlert from '@material-ui/lab/Alert';
let openSnackbarFn;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NotifierSnackbar extends React.Component {
  state = {
    open: false,
		message: '',
		status: 'info'
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ message, status }) => {
    this.setState({
      open: true,
			message,
			status
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      open: false,
			message: '',
    });
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        onClose={this.handleSnackbarClose}
        open={this.state.open}
      >
				<Alert onClose={this.handleSnackbarClose} severity={this.state.status} className="Alert">
          {this.state.message}
        </Alert>
			</Snackbar>
    );
  }
}

export function openSnackbar({ message, status }) {
  openSnackbarFn({ message, status });
}

export default NotifierSnackbar;