import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import '../../scss/_backToLink.scss'

const BackToLink = props => {
  const { link, label } = props
  console.log(props.showChangePassword, 'props backto link')
  return (
    <React.Fragment>
      <Link to={link} className="backToLink" style={props.style}>
        <ChevronLeftIcon />
        <Box component="span">
          {label}
        </Box>
      </Link>
      {
        props.showChangePassword &&
        <Button onClick={() => props.changePasswordModal(true)} style={{ float: 'right', color: '#fff' }} variant="contained" color="secondary">
          Change Password
        </Button>
      }

    </React.Fragment>
  )
}

export default BackToLink
