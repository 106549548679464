import React from 'react'

import Wrapper from '../../components/Global/Wraper'

const Support = () => {
  return (
    <React.Fragment>
      <Wrapper 
        pageTitle={`Support`} 
      >
        
      </Wrapper>
      
    </React.Fragment>
  )
}

export default Support