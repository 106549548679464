import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {deleteViewList} from './../../store/actions/form'
import {connect} from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteListView = (props) => {
  const handleSubmit = () => {
    props.deleteViewList(props.item._id).then(res => {
      props.getAllViewList()
      props.handleDeleteViewList(false)
    }).catch(err => {
      console.log('err',err)
    })
  }
  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete View List</DialogTitle>
        <DialogContent>
          Are you sure want to delete {props.item.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.handleDeleteViewList(false)}} color="primary">
            cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default connect(null, {deleteViewList})(DeleteListView)