import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ReStoreDialog(props) {
  const handleOpenDialog = (value) => {
    props.setOpen(value)
  };
  console.log("form.props", props.form)
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => handleOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to restore this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenDialog(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={() => props.handleRestore(props.form)} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
