import {} from './type'
import mainaxios from '../mainaxios'


export const getAllForm = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get('form', {
    headers:{
      Authorization: token
    }
  })
}
export const getAllRevisionForms = (parnet_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get(`form/rev/${parnet_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleForm = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get(`form/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createForm = (form) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.post('form', {...form}, {
    headers:{
      Authorization: token
    }
  })
}
export const editForm = (form) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.patch(`form/${form._id}`, {...form}, {
    headers:{
      Authorization: token
    }
  })
}
export const deactivateForm = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.delete(`form/deactivate/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteForm = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.delete(`form/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const getAllFormByItsProgram = (program_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get(`/versionform/program/${program_id}`, {
    headers:{
      Authorization: token
    }
  })
}

export const getAllVersionForm = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get('/versionform', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleVersionForm = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get(`versionform/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createVersionForm = (versionForm) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.post('form/publish', {...versionForm}, {
    headers:{
      Authorization: token
    }
  })
}

export const restoreForm = (form) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.post(`form/restore`, {_id:form._id}, {
    headers:{
      Authorization: token
    }
  })
}

export const createViewList = (data) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.post(`listview`, data, {
    headers:{
      Authorization: token
    }
  })
}
export const getViewList = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.get(`listview`, {
    headers:{
      Authorization: token
    }
  })
}
export const updateViewList = (data, _id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.patch(`listview/${_id}`, data, {
    headers:{
      Authorization: token
    }
  })
}

export const deleteViewList = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.delete(`listview/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const syncSingleView = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.post(`sync/viewlist/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const syncViewList = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await mainaxios.post(`sync/viewlist`, {
    headers:{
      Authorization: token
    }
  })
}