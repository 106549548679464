import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  FormControl,
  Button,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import Wrapper from '../../components/Global/Wraper'
import { getSingleRole, updatePermission } from '../../store/actions/role'
import useStyles from '../../components/Global/useStyles'
import { history } from '../../routes/history'
import checkPermission from './../../helper/checkPermission'

import '../../scss/userPermission.scss'

function RolePermission(props) {
  const classes = useStyles()
  const [role, setRole] = useState({})
  const [permission, setPermission] = useState()
  const [loader, setLoader] = useState(false)
  const handleChangeUserPer = (event) => {
    let name = event.target.name
    let checked = event.target.checked
    setPermission({ ...permission, [name]: checked });
  };

  const updateRolePermission = () => {
    props.updatePermission(permission).then(({ data }) => {
      history.push('/role')
    }).catch(res => {
      console.log("err")
    })
  }
  const getRole = () => {
    setLoader(true)
    const { id } = props.match.params
    props.getSingleRole(id).then(({ data }) => {
      setRole(data)
      setPermission(data.permission)
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      console.log("err", err)
    })
  }
  useEffect(() => {
    getRole()
  }, [])
  return (
    <React.Fragment>
      {
        loader ?
          <Box className={classes.loaderCenter} style={{ textAlign: 'center' }}>
            <CircularProgress disableShrink />
          </Box>
          :
          <Wrapper
            pageTitle={role.role}
            // backLink={()=>props.history.goBack()}
            backLink={history.location.pathname.split('/')[1]}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Box className="groupWrap">
                    <Box className="group">
                      <FormControl row>
                        <Box className="groupHead">
                          <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                            App
                          </Typography>
                        </Box>
                        <Box className="groupBody">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_access_app : false}
                                onChange={handleChangeUserPer}
                                name="can_access_app"
                                color='primary'
                              />
                            }
                            label="Can access data app"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_access_mobile : false}
                                onChange={handleChangeUserPer}
                                name="can_access_mobile"
                                color='primary'
                              />
                            }
                            label="Can access mobile app"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_access_dashboard : false}
                                onChange={handleChangeUserPer}
                                name="can_access_dashboard"
                                color='primary'
                              />
                            }
                            label="Can access admin dashboard"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                    <Box className="group">
                      <FormControl row>
                        <Box className="groupHead">
                          <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                            User
                          </Typography>
                        </Box>
                        <Box className="groupBody">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_get_user : false}
                                onChange={handleChangeUserPer}
                                name="can_get_user"
                                color="primary"
                              />
                            }
                            label="Can get user"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_create_user : false}
                                onChange={handleChangeUserPer}
                                name="can_create_user"
                                color='primary'
                              />
                            }
                            label="Can create user"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_edit_user : false}
                                onChange={handleChangeUserPer}
                                name="can_edit_user"
                                color="primary"
                              />
                            }
                            label="Can edit user"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_delete_user : false}
                                onChange={handleChangeUserPer}
                                name="can_delete_user"
                                color="primary"
                              />
                            }
                            label="Can delete user"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                    <Box className="group">
                      <FormControl row>
                        <Box className="groupHead">
                          <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                            Role
                          </Typography>
                        </Box>
                        <Box className="groupBody">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_get_role : false}
                                onChange={handleChangeUserPer}
                                name="can_get_role"
                                color="primary"
                              />
                            }
                            label="Can get role"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_create_role : false}
                                onChange={handleChangeUserPer}
                                name="can_create_role"
                                color='primary'
                              />
                            }
                            label="Can create role"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_edit_role : false}
                                onChange={handleChangeUserPer}
                                name="can_edit_role"
                                color="primary"
                              />
                            }
                            label="Can edit role"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_delete_role : false}
                                onChange={handleChangeUserPer}
                                name="can_delete_role"
                                color="primary"
                              />
                            }
                            label="Can delete role"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                    <Box className="group">
                      <FormControl row>
                        <Box className="groupHead">
                          <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                            Permission
                          </Typography>
                        </Box>
                        <Box className="groupBody">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_get_permission : false}
                                onChange={handleChangeUserPer}
                                name="can_get_permission"
                                color="primary"
                              />
                            }
                            label="Can get permission"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_create_permission : false}
                                onChange={handleChangeUserPer}
                                name="can_create_permission"
                                color='primary'
                              />
                            }
                            label="Can create permission"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_edit_permission : false}
                                onChange={handleChangeUserPer}
                                name="can_edit_permission"
                                color="primary"
                              />
                            }
                            label="Can edit permission"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={permission ? permission.can_delete_permission : false}
                                onChange={handleChangeUserPer}
                                name="can_delete_permission"
                                color="primary"
                              />
                            }
                            label="Can delete permission"
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="group">
                    <FormControl row>
                      <Box className="groupHead">
                        <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                          Designation
                        </Typography>
                      </Box>
                      <Box className="groupBody">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_get_designation : false}
                              onChange={handleChangeUserPer}
                              name="can_get_designation"
                              color="primary"
                            />
                          }
                          label="Can get designation"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_create_designation : false}
                              onChange={handleChangeUserPer}
                              name="can_create_designation"
                              color='primary'
                            />
                          }
                          label="Can create designation"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_edit_designation : false}
                              onChange={handleChangeUserPer}
                              name="can_edit_designation"
                              color="primary"
                            />
                          }
                          label="Can edit designation"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_delete_designation : false}
                              onChange={handleChangeUserPer}
                              name="can_delete_designation"
                              color="primary"
                            />
                          }
                          label="Can delete designation"
                        />


                      </Box>
                    </FormControl>
                  </Box>
                  <Box className="group">
                    <FormControl row>
                      <Box className="groupHead">
                        <Typography component="h2" variant="h6" color="inherit" noWrap className={classes.title}>
                          Documents
                        </Typography>
                      </Box>
                      <Box className="groupBody">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_add_document : false}
                              onChange={handleChangeUserPer}
                              name="can_add_document"
                              color="primary"
                            />
                          }
                          label="Can add documents"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_edit_own_document : false}
                              onChange={handleChangeUserPer}
                              name="can_edit_own_document"
                              color='primary'
                            />
                          }
                          label="Can edit own documents"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_edit_document : false}
                              onChange={handleChangeUserPer}
                              name="can_edit_document"
                              color="primary"
                            />
                          }
                          label="Can edit all documents"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_edit_document_after_processing : false}
                              onChange={handleChangeUserPer}
                              name="can_edit_document_after_processing"
                              color="primary"
                            />
                          }
                          label="Can edit documents after processing"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_delete_own_document : false}
                              onChange={handleChangeUserPer}
                              name="can_delete_own_document"
                              color="primary"
                            />
                          }
                          label="Can delete own documents"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_delete_document : false}
                              onChange={handleChangeUserPer}
                              name="can_delete_document"
                              color="primary"
                            />
                          }
                          label="Can delete all documents"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_delete_document_after_processing : false}
                              onChange={handleChangeUserPer}
                              name="can_delete_document_after_processing"
                              color="primary"
                            />
                          }
                          label="Can delete documents after processing"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.edit_document_after_no_of_days : false}
                              onChange={handleChangeUserPer}
                              name="edit_document_after_no_of_days"
                              color="primary"
                            />
                          }
                          label="Can edit document after some days"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission ? permission.can_see_all_docs : false}
                              onChange={handleChangeUserPer}
                              name="can_see_all_docs"
                              color="primary"
                            />
                          }
                          label="Can see all documents"
                        />
                      </Box>
                    </FormControl>
                  </Box>
                  {
                    checkPermission("can_edit_role") &&
                    <Box style={{ padding: 10 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="customBtn greebBtn"
                        onClick={updateRolePermission}
                      >
                        Update
                      </Button>
                    </Box>
                  }
                </Paper>
              </Grid>
            </Grid>

          </Wrapper>
      }
    </React.Fragment>
  )
}

export default connect(null, { getSingleRole, updatePermission })(RolePermission)
