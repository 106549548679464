import React, { useState, useEffect } from 'react'
import {
  Box,
  CardMedia,
} from '@material-ui/core'

import '../../../scss/_programLeftImageRightText.scss'
import { url } from '../../../helper/CommonHelper'
import moment from 'moment'


const LeftImageRightName = props => {
  const { data } = props

  const date = value => {
    return moment(value).format('DD/MM/YYYY')
  }

  return (
    <React.Fragment>
      {
        data ?
          <Box className="imageTextWrap" >
            <CardMedia
              className="tableImage"
              image={data.photo ? url + data.photo : ""}
              title={data.name}
            />
            <Box className="textWrap" >
              <span className="pName">{data.name}</span>
              {
                data.updated_at ?
                  <span className="lastUpdated">
                    Last updated: {
                      date(data.updated_at)
                    }
                  </span>
                  :
                  <span className="lastUpdated">
                    Last updated: {
                      date(data.created_at)
                    }
                  </span>
              }
            </Box>
          </Box>
          :
          null
      }
    </React.Fragment>
  )
}

export default LeftImageRightName
