import CommonFieldData from './CommonFieldData'
import removeSpecialChar from './_special'

export default class Image extends CommonFieldData{
  constructor(){
    super()
    this.field = {
      ...this.commonField,
      field_type:"image",
      validation:{
        ...this.commonField.validation,
        type:["jpg", "png", "jpeg"],
      }
    }
  }

  checkValidationField(validationField){
    if(validationField.hasOwnProperty('required') && validationField.hasOwnProperty('cond_logic') && validationField.hasOwnProperty('min') && validationField.hasOwnProperty('max') && validationField.hasOwnProperty('type') ) 
      return true
  }

  loadData(data){
    if(this.field.hasOwnProperty('field_label')){
      this.field.field_label = data.field_label
      this.field.field_name = removeSpecialChar(data.field_label.toLowerCase().split(" ").join("_"))
    }
if(data.show_field){
      this.field.show_field = data.show_field
    }
    if(this.field.hasOwnProperty('placeholder')){
      this.field.placeholder = data.placeholder
    }
    if(this.field.hasOwnProperty('order')){
      this.field.order = data.order
    }
    if(this.field.hasOwnProperty('default')){
      this.field.default = data.default
    }
    if(this.field.hasOwnProperty('validation')){
      this.field.validation = {
        required:data.validation.required, 
        condition_chack:data.validation.condition_chack,
        cond_logic:data.validation.cond_logic
      }
    }
    if(data.hasOwnProperty("_id")){
      this.field._id = data._id
    }
  }
  getData(){
    return this.field
  }
}
