import React, { useState, useEffect } from 'react'
import {
  Paper,
  Box,
  AppBar,
  Tab,
  Tabs,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import useStyles from '../../Global/useStyles'
import TabPanel from '../../Tabs/TabPanel'
import Overview from './ProgramOverview'
import Beneficiaries from './Beneficiaries'
import Locations from './Locations'
import Events from './Events'
import Modules from './Modules'
import Teams from './Teams'
import Goals from './Goals'
import Notifications from './Notifications'
import Cases from './Cases'
import Surveys from './Surveys'
import Permissions from './Permissions'

import '../../../scss/_programTabs.scss'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#444'
    },
    secondary: {
      main: '#000'
    }
  }
});

const ProgramTabs = props => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLink = (newValue) => {
    setValue(newValue)
  }
  return (
    <React.Fragment>
      <Box className='tabContainer programTab'>
        <AppBar position="static" color="default">
          <MuiThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleChange} _programTabs
              aria-label="user-details-tab"
              indicatorColor="secondary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              className="programTabTabs"
            >
              <Tab className="tabButton" label="Overview" {...a11yProps(0)} />
              <Tab className="tabButton" label="Goals" {...a11yProps(1)} />
              <Tab className="tabButton" label="Notifications" {...a11yProps(2)} />
              <Tab className="tabButton" label="Modules" {...a11yProps(3)} />
              <Tab className="tabButton" label="Beneficiaries" {...a11yProps(4)} />
              <Tab className="tabButton" label="Locations" {...a11yProps(5)} />
              <Tab className="tabButton" label="Events" {...a11yProps(6)} />
              <Tab className="tabButton" label="Cases" {...a11yProps(7)} />
              <Tab className="tabButton" label="Surveys" {...a11yProps(8)} />
              <Tab className="tabButton" label="Permission" {...a11yProps(9)} />
              <Tab className="tabButton" label="Teams" {...a11yProps(10)} />
            </Tabs>
          </MuiThemeProvider>
        </AppBar>
        <Paper className={classes.paperLR0} style={{ paddingTop: 0, borderRadius: 0 }}>
          <TabPanel value={value} index={0}>
            <Box className="TabContentMain">
              <Overview
                program={props.data}
                setProgram={props.setProgram}
                data={props.data && props.data}
                handleLink={handleLink}
                can_edit_docuement={props.can_edit_docuement}
              />
              {/* <Button onClick={()=>handleLink(2)}>Module</Button> */}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box className="TabContentMain">
              <Goals can_edit_docuement={props.can_edit_docuement} handleLink={handleLink} program={props.data} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box className="TabContentMain">
              <Notifications can_edit_docuement={props.can_edit_docuement} handleLink={handleLink} program={props.data} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box className="TabContentMain">
              <Modules can_edit_docuement={props.can_edit_docuement} handleLink={handleLink} program={props.data} data={props.data} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Box className="TabContentMain">
              <Beneficiaries can_edit_docuement={props.can_edit_docuement} setProgram={props.setProgram} program={props.data} handleLink={handleLink} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Box className="TabContentMain">
              <Locations can_edit_docuement={props.can_edit_docuement} setProgram={props.setProgram} program={props.data} handleLink={handleLink} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Box className="TabContentMain">
              <Events can_edit_docuement={props.can_edit_docuement} setProgram={props.setProgram} program={props.data} handleLink={handleLink} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Box className="TabContentMain">
              <Cases can_edit_docuement={props.can_edit_docuement} setProgram={props.setProgram} program={props.data} handleLink={handleLink} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Box className="TabContentMain">
              <Surveys can_edit_docuement={props.can_edit_docuement} setProgram={props.setProgram} program={props.data} handleLink={handleLink} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={9}>
            <Box className="TabContentMain">
              <Permissions can_edit_docuement={props.can_edit_docuement} handleLink={handleLink} setProgram={props.setProgram} program={props.data} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={10}>
            <Box className="TabContentMain">
              <Teams can_edit_docuement={props.can_edit_docuement} setProgram={props.setProgram} program={props.data} handleLink={handleLink} linkValue={10} />
            </Box>
          </TabPanel>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default ProgramTabs
