import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton, Button,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux'
import BeneficiariesTable from './BeneficiariesTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import { createLcoationType, getAllLocationType, editLocationType } from '../../../store/actions/location'
import { editProgram, deactivateModule } from '../../../store/actions/program'
import { getAllForm } from '../../../store/actions/form'
import _ from 'lodash'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import ModulePermissionDialog from './ModulePermissionDialog'
import '../../../scss/_beneficiaries.scss'

const Locations = props => {
  const [loader, setLoader] = useState(true)
  const [forms, setForms] = useState([])
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [locationTypes, setLocationTypes] = useState([])
  const [program, setProgram] = useState({})
  const [addedLocationTypes, setAddedLocationTypes] = useState([])
  const [searchData, setSearchData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [openModulePermission, setOpenModulePermission] = useState(false)
  let { active_module } = props.program ? props.program : null


  const getAddedLocationTypes = (data) => {
    let tempAddLocationsType = data.filter(location_type => {
      if (props.program.location_types.indexOf(location_type._id) > -1) {
        return true
      }
      return false
    })
    setSearchData([...tempAddLocationsType])
    setAddedLocationTypes(tempAddLocationsType)
  }
  const getLocationNotAddedToProgram = (data) => {
    let tempLocationsType = data.filter(location_type => {
      if (props.program.location_types.indexOf(location_type._id) > -1) {
        return false
      }
      return true
    })
    setLocationTypes([...tempLocationsType])
  }
  const getLocationTypesTypes = () => {
    props.getAllLocationType().then(({ data }) => {
      getAddedLocationTypes(data)
      getLocationNotAddedToProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }

  const addLocationType = (location) => {
    props.createLcoationType(location).then(({ data }) => {
      addItemTypeToProgram(data)
      let tempLocationTypes = _.cloneDeep(locationTypes)
      tempLocationTypes = [...tempLocationTypes, data]
      setLocationTypes(tempLocationTypes)
      handleDrawer(false)
      openSnackbar({ message: 'Added Location Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Adding Location Type', status: 'error' });
    })
  }

  const updateLocationType = (location) => {
    props.editLocationType(location).then(({ data }) => {
      let index = addedLocationTypes.findIndex(location_type => location_type._id === data._id)
      addedLocationTypes.splice(index, 1, data)
      setAddedLocationTypes([...addedLocationTypes])
      setSearchData([...addedLocationTypes])
      handleEditDrawer(false)
      openSnackbar({ message: 'Edited Location Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Edit Location Type', status: 'error' });
    })
  }
  const addItemTypeToProgram = (location_type) => {
    let tempProgram = { _id: program._id, location_types: [...program.location_types, location_type._id] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = locationTypes.findIndex(type => type._id === location_type._id)
      let tempLocationTypes = _.cloneDeep(locationTypes)
      tempLocationTypes.splice(index, 1)
      setLocationTypes(tempLocationTypes)
      setAddedLocationTypes([...addedLocationTypes, location_type])
      setSearchData([...addedLocationTypes, location_type])
      handleDrawer(false)
      openSnackbar({ message: 'Added Location Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      openSnackbar({ message: 'Error in Adding Location Type', status: 'error' });
    })
  }
  const removeLocationTypeFromProgram = (location_type) => {
    let temp_location_types = program.location_types.filter(_id => _id !== location_type._id)
    let tempProgram = { _id: program._id, location_types: [...temp_location_types] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = addedLocationTypes.findIndex(type => type._id === location_type._id)
      let tempAddedLocationTypes = _.cloneDeep(addedLocationTypes)
      tempAddedLocationTypes.splice(index, 1)
      setAddedLocationTypes(tempAddedLocationTypes)
      setSearchData([...tempAddedLocationTypes])
      setLocationTypes([...locationTypes, location_type])
      handleDrawer(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Location Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      setDeleteModal(false)
      openSnackbar({ message: 'Error in Removing Location Type', status: 'error' });
    })
  }
  const getAllForms = () => {
    props.getAllForm(props.program._id).then(({ data }) => {
      setForms(data.filter(program => program.has_published === true))
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }

  const deactivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, locations: false } }
    props.editProgram(tempProgram).then(({ data }) => {
      openSnackbar({ message: 'Locations module deactivated', status: 'success' });
      props.setProgram(data)
      props.handleLink(3)

    }).catch(err => {
      console.log('err', err)
    })
  }
  useEffect(() => {
    setProgram(props.program)
    getAllForms()
    getLocationTypesTypes()
    if (props.program && props.program.active_module && props.program.active_module.locations === false) {
      setOpenModulePermission(true)
    }
  }, []);

  const filterData = event => {
    const updateList = addedLocationTypes.filter(item => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setSearchData(updateList)
  }

  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  const handlePermissionModal = (value) => {
    setOpenModulePermission(value)
  }
  const programModuleActivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, locations: true } }
    props.editProgram(tempProgram).then(({ data }) => {
      handlePermissionModal(false)
      props.setProgram(data)
    }).catch(err => {
      console.log('err', err)
    })
  }

  return (
    <React.Fragment>
      <NotifierSnack />
      <ModulePermissionDialog
        openModulePermission={openModulePermission}
        handlePermissionModal={handlePermissionModal}
        programModuleActivate={programModuleActivate}
        handleLink={props.handleLink}
      />
      <Box className="tabbackLinkMain">
        <Box className="link" onClick={() => props.handleLink(3)}>
          <ChevronLeftIcon />
          <span>Back to all modules</span>
        </Box>
      </Box>
      <Box className="Beneficiaries">
        <Box className="headingWrap leftTextRightSearchAndBtn">
          <span className="heading">
            <LocationOnIcon />
            <h3>Locations</h3>
            <span>{active_module && active_module.locations ? "Active" : "Inactive"}</span>
          </span>
          <Box className="rightContent">
            <Button variant="contained" color="primary" onClick={deactivate}>
              {active_module && active_module.locations ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
          </Box>
        </Box>
        <Box className="leftTextRightSearchAndBtn">
          <span className="leftTxt">Active Location Types</span>
          <Box className="rightContent">
            <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
              <input
                type="text"
                name="searchData"
                placeholder="Search"
                className="serachInput"
                onChange={filterData}
              />
              <IconButton className="iconButton" type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className="buttonWrap">
              <BeneficiariesDrawerTab
                buttonLabel="ADD LOCATION TYPE"
                name="Add LOCATION TYPE TO PROGRAM"
                title='Location'
                existing="Select from existing"
                add_title="Create new location type"
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                forms={forms}
                handleSubmitData={addLocationType}
                addItemTypeToProgram={addItemTypeToProgram}
                data={locationTypes}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <BeneficiariesTable
          isEditDrawer={isEditDrawer}
          handleEditDrawer={handleEditDrawer}
          forms={forms}
          handleSubmitData={updateLocationType}
          data={searchData}
          loader={loader}
          removeItemTypeFromProgram={removeLocationTypeFromProgram}
          name="Location"
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
        />
      </Box>
    </React.Fragment>
  )
}

export default connect(null,
  {
    editProgram,
    getAllForm,
    getAllLocationType,
    createLcoationType,
    editLocationType, deactivateModule
  })(Locations)


