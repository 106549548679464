import React, {useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TableFooter,
  TablePagination,
  CircularProgress,
  Box,
  FormControlLabel,
  Tooltip,
  CardMedia,
 } from '@material-ui/core'
 import { Link} from 'react-router-dom'
 import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
 import TablePaginationActions from '../../common/TablePaginationActions'
 import DeleteProgramTabItemModal from '../../common/DeleteProgramTabItemModal'
 import {auth_url} from '../../../store/mainaxios'

 import '../../../scss/_table.scss'

const TeamsTable = props => {
  // const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableItem, setTableItem] = useState()

  // console.log('data length', props.data.length)
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data && props.data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // setLoader(false)
  }, [props.data])

  const hadleSuspendData = (item) => {
    setTableItem(item)
    props.handleDeleteModal(true)
  }

  // console.log('Teams', props.data && props.data)

  return (
    <React.Fragment>
      <TableContainer>
        <Table size="medium" aria-label="table" className="TeamsTable">
          {
            props.loader ?
            <TableBody>
              <TableRow>
                <TableCell align="center" width="100%" colSpan={12}>
                <Box>
                  <CircularProgress disableShrink />
                </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            :
            <TableBody>
              {
                props.data ?
                (rowsPerPage > 0 && props.data
                  ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : []
                ).map((item, key)=> (
                  <TableRow key={key}>
                    <TableCell>
                      <Box className="teamWrap">
                        <Box className="userInfo">
                          <CardMedia
                            className="userImage img40x40 imgCircle"
                            image={item.photo? auth_url+item.photo:"/image/user5.png"}
                            title={`team`}
                          />
                          <span className="text">
                            <span className="name">{item.first_name} {item.last_name}</span>
                            <span className="description">{item.designation?item.designation.name:'----' }</span>
                          </span>
                        </Box>
                        {
                          item.is_program_head &&
                            <Box className="teamheadInfo">
                            <span>{item.is_program_head && 'Program Head'}</span>
                          </Box>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box className="teamWrap">
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={item.is_program_head}
                              onChange = {() => props.changeStatusProgramHead(item)}
                              name="program_head" 
                            />}
                          label="Program Head"
                        />
                      </Box>
                    </TableCell>
                    <TableCell 
                      align="right" 
                      className="iconCell"
                    >
                      <Tooltip 
                        title={`Delete`} 
                        aria-label='delete-beneficiary' 
                        arrow
                        className="actionIcon"
                        // onClick={()=>props.handleSubmitData(item)}
                        onClick={()=>hadleSuspendData(item)}
                      >
                        <Link to={`#`} >
                            <DeleteTwoToneIcon />
                        </Link>
                      </Tooltip> 
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box style={{textAlign: 'center', fontSize: 15, color: '#c5c5c5'}}>
                      No {props.name ? props.name : 'data'} found
                    </Box>
                  </TableCell>
                </TableRow>
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{borderBottom: 0}}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={8}
                count={props.data && props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'teams per page' },
                  native: true,
                }}
                labelRowsPerPage='Teams per page'
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        tableItem &&
        <DeleteProgramTabItemModal 
          title={`Team`}
          action={()=>props.handleSubmitData(tableItem)}
          name={tableItem.first_name && tableItem.first_name + " "+ tableItem.last_name}
          item={tableItem.item && tableItem.item}
          _id={tableItem._id && tableItem._id}
          handleModal={props.handleDeleteModal}
          // status={tableItem.status && tableItem.status}
          open={props.deleteModal}
        />
      }
    </React.Fragment>
  )
}

export default withRouter(TeamsTable)

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);