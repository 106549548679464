import React from 'react'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    // maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 55;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultiSelectChip = props => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <FormControl className={`${classes.formContro} ${props.className}`}>
      <InputLabel id="demo-mutiple-chip-label">{props.label}</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          name={props.name}
          value={props.value}
          onChange={props.handleChange}
          variant="outlined"
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => {
            return props.list.map(form => {
              if(selected.indexOf(form._id)>-1){
                return form.name+", " 
              }
            })
          }}
          MenuProps={MenuProps}
        >
          {props.list && props.list.map((item, i) => (
            <MenuItem key={i} value={item._id} style={getStyles(item.name, props.value, theme)} className="customList">
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default MultiSelectChip
