import React,{useState, useRef} from 'react'
import {connect} from 'react-redux'
import { 
  TextField,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
  Box,
 } from '@material-ui/core';
 import Form from '../../Form/form'
 import {getAllForm} from '../../../store/actions/form'
 import {createBenificiaryType} from '../../../store/actions/beneficiary'
 import SimpleReactValidator from 'simple-react-validator'
 import _ from 'lodash'

const AddNewGoal = (props) => {
  const [, forceUpdate] = useState()
  const [form, setForm] = useState({
    target: '',
    goal_type: '',
    isPinned:false,
    program_id:'',
  })


  const simpleValidator = useRef(new SimpleReactValidator())
  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
  }
  const handleChangeOption = event => {
    let name = event.target.name
    let checked = event.target.checked
    setForm({...form, [name]:checked})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    let tempForm = _.cloneDeep(form)
    props.handleSubmitData(tempForm)
  }

  useState(() => {
    if(props.item && props.item.goal_type){
      let goal_type = props.item.goal_type._id
      setForm({...props.item, goal_type:goal_type})
    }
  }, [])  
  return (
    <Box className="createBeneficiaryType">
      <Form 
        onSubmit={handleSubmit} 
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
        <label style={{fontSize: '0.857rem', fontWeight: 'bold', display: 'inline-block', marginBottom: 10}}>Select Event, Case, Beneficiary, Location or Survey Type</label>
          <TextField
            id="attach-custom-form"
            select
            label="Select Type"
            name="goal_type"
            value={form.goal_type}
            onChange={handleChange}
            variant="outlined"
          >
            {
              props.forms && props.forms.map(form => (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
              ))
            }
          </TextField>
          <Box className="validationMessage">
            {simpleValidator.current.message('select type', form.goal_type, 'required')}
          </Box>
        </Box>
        <Box className="formControl">
        <label style={{fontSize: '0.857rem', fontWeight: 'bold', display: 'inline-block', marginBottom: 10}}>Target</label>
          <TextField 
            type='text'
            label="Enter target number"
            variant="outlined"
            name='target'
            value={form.target}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message('target number', form.target, 'required')}
          </Box>
        </Box>
        <Box className="checkBox">
          <Box className="formControl">
            <FormControlLabel
              control={
                <Checkbox 
                  checked={form.isPinned} 
                  onChange={handleChangeOption} 
                  name="isPinned" 
                  color='primary'
                />
              }
              label="Is Pinned"
            /> 
          </Box>
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) => handleSubmit(event,props.item && props.item.hasOwnProperty("_id")?"update":"add")}
          >
            {props.item && props.item.hasOwnProperty("_id")?"Update & Update to Program":"Save & Add to Program"}
          </Button>
          </Box>
      </Form>
    </Box>
  )
}

export default connect(null, {getAllForm, createBenificiaryType})(AddNewGoal)
