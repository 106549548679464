import React, { useEffect, useState } from 'react'
import axios from 'axios'
import FormBuilder from './FormBuilder'
export default function GroupFeild() {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  const [formJson, setFormJson] = useState([])
  const [fieldData, setFieldData] = useState([])

  const handleChange = (value, field_name) => {
    // value will be sent to save form
    const updatedFormJson = formJson.map(jsonItem => {
      let updatedJson;
      if (jsonItem.field_name !== field_name) return jsonItem

      if (jsonItem.field_name === field_name) {
        updatedJson = {
          ...jsonItem,
          field_value: value
        }
        return updatedJson
      }
      return jsonItem
    });

    const updatedFieldData = fieldData.map((item) => {
      if (item.field_name !== field_name) return item
      if (item.field_name === field_name) {
        let updatedItem;
        updatedItem = { ...item, value: value.toString() }
        return updatedItem
      }
      return item
    })
    setFormJson(updatedFormJson)
    setFieldData(updatedFieldData)
  }


  useEffect(() => {
    axios.get('http://api.cws-main.cobold.xyz/api/structure/5f8460d0fad33d68667a2475', {
      headers: {
        Authorization: token
      }
    })
      .then(({ data }) => {
        const updatedJSON = []
        data.map((form, index) => {
          const y = {
            ...form,
            field_value: ""
          }
          updatedJSON.push(y)
          setFieldData((field) => [
            ...field,
            {
              value: '',
              field_name: form.field_name
            }
          ])
        })
        setFormJson(updatedJSON)
      })
  }, [])
  return (
    <div>
      {formJson.length > 0 && formJson.map((item, index) => (
        <FormBuilder
          item={item}
          onChangeHandler={handleChange}
          key={item._id}
        />
      ))}
    </div>
  )
}
