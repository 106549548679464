import React, { useState, useEffect } from 'react'
import {
  Paper,
  CircularProgress,
  Box,
  Grid,
} from '@material-ui/core';
import Wrapper from '../../components/Global/Wraper'
import useStyles from '../../components/Global/useStyles'
import { history } from '../../routes/history'
import { connect } from 'react-redux'
import { getSingleProgram, getProgramGoals } from '../../store/actions/program'

import LeftImageRightName from '../../components/PageCompnent/Programs/LeftImageRightName'
import ProgramSlider from '../../components/PageCompnent/Programs/ProgramSlider'
import ProgramTabs from '../../components/PageCompnent/Programs/ProgramTabs'

import '../../scss/useProfile.scss'
import '../../scss/_singleProgramGrid.scss'

const SingleProgram = props => {
  const classes = useStyles();
  const [program, setProgram] = useState({})
  const [goals, setGoals] = useState([])
  const [loader, setLoader] = useState(true)

  const getProgram = () => {
    const id = props.match.params.id
    props.getSingleProgram(id).then(({ data }) => {
      setProgram(data)
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      console.log("err", err)
    })

  }
  const getProgramId = () => {
    const id = props.match.params.id
    props.getProgramGoals(id).then(({ data }) => {
      setGoals(data.goals)
    }).catch(err => {
      setLoader(false)
      console.log("err", err)
    })

  }
  useEffect(() => {
    getProgram()
    getProgramId()
  }, [])

  const checkPermssion = () => {
    let user = JSON.parse(localStorage.getItem("cws_user"))
    if (user && user.role && user.role.permission && user.role.permission.edit_document_after_no_of_days) {
      return true
    } else {
      if (program) {
        const date1 = new Date();
        const date2 = new Date(program.created_at);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (program.no_of_days_for_edit_program >= diffDays) {
          return true
        }
      }

    }
    return false
  }

  const sliderData = [
    {
      id: 0,
      name: 'Form Entries',
      stats: '0'
    },
    {
      id: 1,
      name: 'Team',
      stats: '0'
    },
    {
      id: 2,
      name: 'Events',
      stats: '0'
    },
    {
      id: 3,
      name: 'Team',
      stats: '0'
    },
    {
      id: 4,
      name: 'Goals',
      stats: '0'
    },
    {
      id: 5,
      name: 'Form Entries',
      stats: '0'
    },
    {
      id: 6,
      name: 'Events',
      stats: '0'
    }
  ]


  return (
    <React.Fragment>
      <Wrapper
        pageTitle={!loader ? program.name : null}
        backLink={history.location.pathname.split('/')[1]}
      >
        {
          loader ?
            <Box className={classes.loaderCenter}>
              <CircularProgress disableShrink />
            </Box> :
            <>
              <Paper className={classes.paper}>
                <Grid container spacing={4} className="singleProgramGridWrap">
                  <Grid item sm={12}>
                    <Box className="singleProgramGrid">
                      <Box className="imageWithName">
                        <LeftImageRightName
                          data={program}
                        />
                      </Box>
                      <Box className="programSlider">
                        <ProgramSlider
                          data={goals}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <ProgramTabs
                data={program}
                setProgram={setProgram}
                can_edit_document={checkPermssion()}
              />
            </>
        }
      </Wrapper>

    </React.Fragment>
  )
}


export default connect(null, { getSingleProgram, getProgramGoals })(SingleProgram)
