import React, {useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  CircularProgress,
  Box,
  Tooltip,
 } from '@material-ui/core'
import { Link} from 'react-router-dom'
import CreateIcon from '@material-ui/icons/Create';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import TablePaginationActions from '../../common/TablePaginationActions'
import DeleteProgramTabItemModal from '../../common/DeleteProgramTabItemModal'
import AddEditNewGoal from './AddEditNewGoal'


 import '../../../scss/_table.scss'

const GoalsTable = props => {
  // const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [goal, setGoal] = useState(null)
  const [tableItem, setTableItem] = useState()

  // console.log('data length', props.data.length)
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data && props.data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleEditData = (item) =>{
    setGoal(item)
    props.handleEditDrawer(true)
  }
  const hadleSuspendData = (item) => {
    setTableItem(item)
    props.handleDeleteModal(true)
  }
  return (
    <React.Fragment>
      <TableContainer>
        <Table size="medium" aria-label="table" className="BeneficiariesTable">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{paddingBottom: 12}}>Goal</StyledTableCell>
              <StyledTableCell style={{paddingBottom: 12}}>Target</StyledTableCell>
              <StyledTableCell style={{paddingBottom: 12}}>Completed</StyledTableCell>
              <StyledTableCell style={{paddingBottom: 12}}>Percentage</StyledTableCell>
              <StyledTableCell align="right" style={{paddingBottom: 12}}></StyledTableCell>
            </TableRow>
          </TableHead> 
          {
            props.loader ?
            <TableBody>
              <TableRow>
                <TableCell align="center" width="100%" colSpan={12}>
                <Box>
                  <CircularProgress disableShrink />
                </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            :
            <TableBody>
              {
                props.data ?
                (rowsPerPage > 0 && props.data
                  ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : []
                ).map((item, key)=> (
                  <TableRow key={key}>
                    <TableCell>{item.goal_type && item.goal_type.name}</TableCell>
                    <TableCell>{item.target}</TableCell>
                    <TableCell>{item && item.achieve}</TableCell>
                    <TableCell>{item && (100*item.achieve)/item.target}</TableCell>
                    <TableCell 
                      align="right" 
                      className="iconCell"
                    >
                      <Tooltip 
                        title={`Edit`} 
                        aria-label='edit-beneficiary' 
                        arrow
                        className="actionIcon"
                        onClick={()=>handleEditData(item)}
                      >
                        <Link to={`#`} >
                            <CreateIcon />
                        </Link>
                      </Tooltip>
                      <Tooltip 
                        title={`Delete`} 
                        aria-label='delete-goal' 
                        arrow
                        className="actionIcon"
                        onClick={()=>hadleSuspendData(item)}
                      >
                        <Link to={`#`} >
                            <DeleteTwoToneIcon />
                        </Link>
                      </Tooltip> 
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box style={{textAlign: 'center', fontSize: 15, color: '#c5c5c5'}}>
                      No {props.name ? props.name : 'data'} found
                    </Box>
                  </TableCell>
                </TableRow>
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{borderBottom: 0}}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={8}
                count={props.data && props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'teams per page' },
                  native: true,
                }}
                labelRowsPerPage='Goals per page'
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <BeneficiariesDrawerTab 
        name="Edit Goal To PROGRAM"
        title='Goal'
        add_title="Edit Goal"
        isDrawerOpen={props.isEditDrawer}
        handleDrawer={props.handleEditDrawer}
        forms = {props.forms}
        handleSubmitData={props.handleSubmitData}
        item={goal}
      />;
      {
        tableItem &&
        <DeleteProgramTabItemModal 
          title={props.name}
          action={()=>props.removeItemTypeFromProgram(tableItem)}
          name={tableItem.name && tableItem.name}
          item={tableItem.item && tableItem.item}
          _id={tableItem._id && tableItem._id}
          handleModal={props.handleDeleteModal}
          status={tableItem.status && tableItem.status}
          open={props.deleteModal}
        />
      }
    </React.Fragment>
  )
}

export default withRouter(GoalsTable)

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);