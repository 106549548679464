import React, {useState, useRef, useEffect} from 'react'
import {
  Grid,
  Paper,
  Button,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  Checkbox,
  Select,
  Input,
  ListItemText,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import Wrapper from '../../components/Global/Wraper'
import useStyles from '../../components/Global/useStyles'
import TableNewForm from '../../components/PageCompnent/CustomForm/TableNewForm'
import SimpleReactValidator from 'simple-react-validator'
import {createForm, createVersionForm} from './../../store/actions/form'
import {getAllProgram} from './../../store/actions/program'
import {connect} from 'react-redux'
import {history} from '../../routes/history'
import _ from 'lodash'
import NotifierSnack, {openSnackbar} from '../../components/Global/NotifierSnackbar'
import '../../scss/_form.scss'

const AddNewFormGroup = (props) => {
  const classes = useStyles()
  const [, forceUpdate] = useState()
  const [colorBorder, setColorBorder] = useState('#a0a0a0')
  const [errorMsg, setErrorMsg] = useState(false)
  const [programs, setPrograms] = useState([])
  const [formData, setFormData] = useState([])
  const [errorFormData, setErrorFormData] = useState([])
  const [form, setForm] = useState({
    name: '',
    parent_id:"",
    structures:[],
    program_ids:[],
  })
  const [isError, setIsError] = useState(false)


  const simpleValidator = useRef(new SimpleReactValidator())

  const onChangeInput = (event) =>{
    let name = event.target.name
    let value = event.target.value
    if(value !== ''){
      setColorBorder('#a0a0a0')
    }
    setForm({...form,  [name]: value})
  }
  const savedData = (data) => {
    if(data){
      setFormData(data)
    }
  }
 
  const getDublicate = (tempFormData, structures, checks)=>{
    for(let i = 0; i<tempFormData.length; i++){
      if(structures.indexOf(tempFormData[i].field_name)>-1){
        tempFormData[i].error = "Please make unique field_name"
        checks.push(i)
      }else{
        structures.push(tempFormData[i].field_name)
      }
      if(Array.isArray(tempFormData[i].extra_details)){
        getDublicate(tempFormData[i].extra_details, structures, checks)
      }
    }
  }
  const handleSubmit = () => {
    console.log('this is running top')
    if (!simpleValidator.current.allValid()) {
      console.log('this is running valid')
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    let structures = []
    let checks = []
    const emptyLabel = formData.map(x=>x.field_label)
    if(emptyLabel.includes('')){
      console.log('this is running label')
      setErrorMsg(true)
      return;
    }
    console.log('formData')
    let tempFormData = _.cloneDeep(formData)
    getDublicate(tempFormData,structures,checks)
    setFormData(tempFormData)
    savedData(tempFormData)
    setErrorFormData(tempFormData)
    if(checks.length>1){
      console.log('check.length', checks)
      return 
    }
    form.structures = formData
    form.programs = form.program_ids
    props.createForm(form).then(({data}) => {
      openSnackbar({ message: 'Successfully added form', status: 'success' });
      props.history.push(`/custom-form/edit/${data._id}`)
    }).catch(err => {
      console.log(err, 'err')
      openSnackbar({ message: 'Something went wrong', status: 'error' });
    })
  }
  const getAllPrograms = () => {
    props.getAllProgram().then(({data}) => {
      setPrograms(data)
    })
  }
  useEffect(() => {
    getAllPrograms()
  }, [])

  useEffect(() => {
    if(props.history && props.history.location.state && props.history.location.state.allStructure){
      setForm(prev => ({
        ...prev,
        structures: props.history.location.state.allStructure
      }))
    } else{
      setForm(prev => ({
        ...prev,
        structures: []
      }))
    }
  }, [])

  return (
    <React.Fragment>
      <NotifierSnack />
    <Wrapper 
      pageTitle="Add New Form Group" 
      backgroundColor="#f1f1f1"
      backLink={history.location.pathname.split('/')[1]}
    >
      <form className="customFormPage">
        <Grid container className="CustomFormTitleInput">
          <Grid item md={9}>
            {/* <Button variant="outlined" onClick={handleOpenSnack}>Snack</Button> */}
            <label>Add new Form</label>
            <input 
              type="text" 
              name="name" 
              placeholder="Add Title" 
              value={form.name}
              onChange={onChangeInput}
              style={{borderColor: colorBorder}}
            />
            <Box style={{color: 'red'}}>
              {simpleValidator.current.message('add title', form.name, 'required')}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={9}>
            <Paper className={classes.paper}>
              <TableNewForm errorFormData ={errorFormData} setIsError={setIsError} isError={isError}  savedData={savedData} errorMsg={errorMsg} copyForm={form.structures} />
            </Paper>
          </Grid>
          <Grid item md={3}>
          {/* Select Program */}
            <Box style={{marginBottom: 15}}>
              <Paper className={classes.paper}>
                <Box className="rightCard">
                  <span className="head">Select Program</span>
                  <span className="description">Forms can be associated with a program. These are visible when you create surveys or case types.</span>
                  <Box className="formControl">
                    <Select
                      className="multiSelectWithCheckbox"
                      labelId="select-program"
                      label="Select Program"
                      multiple
                      name='program_ids'
                      value={form.program_ids}
                      onChange={onChangeInput}
                      input={<Input />}
                      renderValue={(selected) => {
                        return programs.map(program => {
                          if(selected.indexOf(program._id)>-1){
                            return program.name 
                          }
                        })
                      }}
                      MenuProps={MenuProps}
                      variant="outlined"
                    >
                      {
                        programs.map((program, i)=> {
                          return(
                            <MenuItem key={i} value={program._id}>
                              <Checkbox checked={form.program_ids.indexOf(program._id) > -1} />
                              <ListItemText primary={program.name} />
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </Box>
                </Box>
              </Paper>
            </Box>
            {/* Search Form by Name */}
            <Box style={{marginBottom: 15}}>
              <Paper className={classes.paper}>
                <Box className="rightCard">
                  <span className="head">Duplicate fields from another form</span>
                  <span className="description">Search for a form below to duplicate the fields from that form in this one.</span>
                  <Box className="formControl">
                    <TextField
                      label="Search Form by Name"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
            {/* Button section */}
            <Box>
              <Paper className={classes.paper}>
                <Box className="rightCard" style={{ borderBottomColor: '#ccc', borderBottomWidth: 1, borderBottomStyle: 'solid', marginBottom: 10 }}>
                  <span className="head">Changes have been made.</span>
                  <span className="btnWrap">
                    <Button 
                      variant="contained" 
                      className="customBtn whiteBtn"
                      size='small' 
                      onClick={() => handleSubmit()}
                    >
                      Save Draft
                    </Button>
                  </span>
                </Box>
                {/* <Button 
                  variant="contained" 
                  className={`customBtn greenBtn ${disabledBtn && 'disabledBtn'}`}
                  size='medium' 
                  style={{ maxWidth: 170 }} 
                  onClick={publishForm}
                >
                  PUBLISH FORM
                </Button> */}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box style={{marginTop: 15}}>
        {/* <pre>{JSON.stringify(finalData, undefined, 2)}</pre> */}
      </Box>
    </Wrapper>
    </React.Fragment>
  )
}

export default connect(null, {createForm, getAllProgram, createVersionForm})(AddNewFormGroup)

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};
