import { } from './type'
import axios from '../mainaxios'


export const getAllProgram = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('program', {
    headers: {
      Authorization: token
    }
  })
}

export const getProgramGoals = (id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`program/${id}/goal`, {
    headers: {
      Authorization: token
    }
  })
}

export const getSingleProgramModule = (_id) => async () => {
  console.log("id", _id)
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`program/${_id}/module`, {
    headers: {
      Authorization: token
    }
  })
}
export const getSingleProgramFull = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`program/${_id}/full`, {
    headers: {
      Authorization: token
    }
  })
}

export const getSingleProgram = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`program/${_id}`, {
    headers: {
      Authorization: token
    }
  })
}
export const createProgram = (program) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('program', program, {
    headers: {
      Authorization: token
    }
  })
}
export const editProgram = (program) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`program/${program._id}`, program, {
    headers: {
      Authorization: token
    }
  })
}
export const editProgramForProgram = (program, _id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`program/${_id}`, program, {
    headers: {
      Authorization: token
    }
  })
}
export const deactivateProgram = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`program/${_id}`, {
    headers: {
      Authorization: token
    }
  })
}
export const syncProgramToCouch = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`/sync`, {
    headers: {
      Authorization: token
    }
  })
}
export const syncSingleProgram = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`/sync/${_id}`, {
    headers: {
      Authorization: token
    }
  })
}
export const getAllSyncDataFromCouch = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`/sync/data`, {
    headers: {
      Authorization: token
    }
  })
}

export const compactDatabaseOfCouch = (program_slug) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`/sync/compact/${program_slug}`, {
    headers: {
      Authorization: token
    }
  })
}

export const createUserToCouchDB = (id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`/sync/add/${id}`, {
    headers: {
      Authorization: token
    }
  })
}


export const lockProgram = (id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`program/change/lock/status/${id}`, {}, {
    headers: {
      Authorization: token
    }
  })
}
export const deactivateModule = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.put(`program/change/status/module/program/${data.id}`, { ...data }, {
    headers: {
      Authorization: token
    }
  })
}