import {} from './type'
import axios from '../mainaxios'


export const getAllLocationType = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('locationtype', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleLocationType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`locationtype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createLcoationType = (locationtype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('locationtype', locationtype, {
    headers:{
      Authorization: token
    }
  })
}
export const editLocationType = (locationtype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`locationtype/${locationtype._id}`, locationtype, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteLocationType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`locationtype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
