import axios from '../mainaxios'


export const getAllPrograms = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get('/dashboard/programs', {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const getModules = (id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/dashboard/program/${id}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const getDocs = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/dashboard/program/${data.program}/module/${data.module}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const getDocTypes = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/dashboard/module/docs`, {
    params: {
      ...data
    },
    headers: {
      Authorization: token
    }
  })
  return res
}

export const getDocById = (id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/dashboard/document?local_id=${id}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const exportDocs = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/extra/v2/report_download?doc_type=${data.module}&module_type_name=${data.doc_type}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const exportSingleDoc = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/extra/v2/single/report/doc/${data._id}?doc_type=${data.doc_type}`, {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const exportAllDocs = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get(`/extra/v2/allReport`, {
    params: {
      ...data
    },
    headers: {
      Authorization: token
    }
  })
  return res
}
