import { 
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formRoot: {
    '& > *': {
      margin: theme.spacing(2),
      // width: '33ch',
    },
  },
  loaderCenter: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperLR0: {
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperSpacing0: {
    padding: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  flexGrow1: {
    flexGrow: 1
  },
  fixedHeight: {
    height: 240,
  },
  pos: {
    marginBottom: 12,
  },
  link: {
    textDecoration: 'none'
  },
  paraFont: {
    fontSize: 14
  },
  textCenter: {
    textAlign: 'center'
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles