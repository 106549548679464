import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableFooter,
  CircularProgress,
  Box,
  IconButton,
  Avatar,
  Button,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TablePaginationActions from './TablePaginationActions'
//  import UserTableActionMenu from '../common/UserTableActionMenu'
import TableActionIcons from '../common/TableActionIcons'
import { auth_url } from '../../store/mainaxios'
import '../../scss/_table.scss'
import checkPermission from '../../helper/checkPermission'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);

const styles = {
  sortedButton: {
    borderRadius: '50% !important'
  }
}

const TableUser = (props) => {
  const { data, loader, history, columns } = props
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchData, setSearchData] = useState('')
  const [sortName, setSortName] = useState(true)
  const [sortEmail, setSortEmail] = useState(true)
  const [sortId, setSortId] = useState(true)


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSort = (type, status) => {
    console.log('type, status', type, status)
    props.sortedUser(type, status)
    if (type === "email")
      return setSortEmail(!sortEmail)
    console.log(type)
    if (type === "fullname")
      return setSortName(!sortName)

    if (type === "employeeId")
      return setSortId(!sortId)
  }
  useEffect(() => {
    setSearchData(data)
  }, [data])

  const filterData = event => {
    const updateList = data.filter(item => {
      const name = item.first_name.toLowerCase() + " " + item.last_name.toLowerCase()
      return (
        name.search(event.target.value.toLowerCase()) !== -1
      )
    })
    setSearchData(updateList.length > 0 && updateList)
  }

  // console.log('searchData:', searchData)
  // console.log('Columns:', props.columns)
  let can_get = checkPermission("can_get_user")
  return (
    <React.Fragment>
      <Box className="userTableMain">
        <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
          <IconButton className="iconButton" type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
          <input
            type="text"
            name="searchData"
            placeholder="Search by name"
            className="serachInput"
            onChange={filterData}
          />
        </Box>
        <TableContainer>
          {
            can_get ?
              <Table size="small" aria-label="user table">
                <TableHead>
                  <TableRow>
                    {
                      <StyledTableCell>
                        Emp. ID
                        {/* {
                        sortId?
                          <ArrowUpwardIcon onClick={() => handleSort("employeeId", sortId)} size="small"/> 
                          :
                          <ArrowDownwardIcon onClick={() => handleSort("employeeId", sortId)} size="small"/>
                      } */}
                      </StyledTableCell>
                    }
                    {
                      <StyledTableCell></StyledTableCell>
                    }
                    {
                      <StyledTableCell>
                        Name
                        {
                          sortName ?
                            <ArrowUpwardIcon onClick={() => handleSort("fullname", sortName)} size="small" />
                            :
                            <ArrowDownwardIcon onClick={() => handleSort("fullname", sortName)} size="small" />
                        }
                      </StyledTableCell>

                    }
                    {
                      <StyledTableCell>
                        Email
                        {
                          sortEmail ?
                            <ArrowUpwardIcon onClick={() => handleSort("email", sortEmail)} size="small" />
                            :
                            <ArrowDownwardIcon onClick={() => handleSort("email", sortEmail)} size="small" />
                        }
                      </StyledTableCell>
                    }
                    {
                      <StyledTableCell>Mobile number</StyledTableCell>
                    }
                    {
                      <StyledTableCell>Role</StyledTableCell>
                    }
                    {
                      <StyledTableCell>Designation</StyledTableCell>
                    }
                    {
                      <StyledTableCell>Status</StyledTableCell>
                    }
                    <StyledTableCell align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                {
                  loader ?
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" width="100%" colSpan={12}>
                          <Box>
                            <CircularProgress disableShrink />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    :
                    <TableBody>
                      {searchData ?
                        (rowsPerPage > 0 && searchData
                          ? searchData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : []
                        ).map((user, i) => (
                          <TableRow key={i} hover={true}>
                            {
                              <TableCell onClick={() => history.push(`/user/edit/${user._id}`)} className="tableLink">
                                {user.employeeId}
                              </TableCell>
                            }
                            {
                              columns ?
                                !(columns.indexOf("Photo") > -1) &&
                                <TableCell>
                                  <Box className="userImage" onClick={() => history.push(`/user/edit/${user._id}`)} style={{ cursor: 'pointer' }}>
                                    {
                                      user.photo ? <Avatar alt={user.first_name} src={auth_url + user.photo} /> : <Avatar alt={user.first_name} src="/image/user5.png" />
                                    }

                                  </Box>
                                </TableCell>
                                :
                                <TableCell>
                                  <Box className="userImage" onClick={() => history.push(`/user/edit/${user._id}`)} style={{ cursor: 'pointer' }}>
                                    <Avatar alt={user.first_name} src="/image/user5.png" />
                                  </Box>
                                </TableCell>
                            }
                            {
                              <TableCell onClick={() => history.push(`/user/edit/${user._id}`)} className="tableLink">
                                {user.fullname}
                              </TableCell>
                            }
                            {
                              <TableCell>{user.email}</TableCell>
                            }
                            {
                              <TableCell>{user.mobile}</TableCell>
                            }
                            {
                              <TableCell>{user.role ? user.role.role : "----"}</TableCell>
                            }
                            {
                              <TableCell>{user.designation ? user.designation.name : "----"}</TableCell>
                            }
                            {
                              <TableCell>
                                <Box component="span" className={`${user.status ? "greenBtn" : "inactiveBtn"} statusBtn`}>
                                  {user.status ? "Active" : "Inactive"}
                                </Box>
                              </TableCell>
                            }
                            <TableCell align="right">
                              <TableActionIcons
                                data={user}
                                getSuspendedData={props.getSuspendedData}
                                handleIsActive={props.handleIsActive}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Box style={{ textAlign: 'center', fontSize: 15, color: '#c5c5c5' }}>
                              No user found
                            </Box>
                          </TableCell>
                        </TableRow>
                      }

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={12} />
                        </TableRow>
                      )}
                    </TableBody>
                }
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      style={{ borderBottom: 0 }}
                      rowsPerPageOptions={[10, 20, 30, { label: 'All', value: searchData.length }]}
                      colSpan={8}
                      count={searchData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'users per page' },
                        native: true,
                      }}
                      labelRowsPerPage='Users per page'
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              :
              <div style={{ height: 200 }}>
                <center>
                  <h2>You don't have permission to see users</h2>
                </center>
              </div>
          }

        </TableContainer>
      </Box>
    </React.Fragment>
  );
}

export default withRouter(TableUser)