import React, {useState, useEffect} from "react";
import { 
  makeStyles,
  Input,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Box,
} from "@material-ui/core";

import '../../scss/_buttonForMultiSelect.scss'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 56 + ITEM_PADDING_TOP,
      width: 'auto'
    }
  }
};

const ButtonForMultiSelect = props => {
  const {icon, label, names, handleColumnsName} = props
  // const classes = useStyles();
  const [listName, setListName] = useState([]);

  const handleChange = (event) => {
    event.target.name = event.target.value;
    setListName(event.target.name);
  };

  useEffect(() => {
    handleColumnsName(listName)
  }, [listName])

  return (
      <Box className="buttonForMultiSelect">
        <Box className="iconLabelWrap">
          {icon}
          <span className="label">{label}</span>
        </Box>
        <Select
          labelId="multi-select"
          multiple
          value={listName}
          name="listName"
          onChange={handleChange}
          input={<Input />}
          renderValue={() => null}
          MenuProps={MenuProps}
          style={{
            width: (props.width && props.width), 
            minWidth: 50,
            opacity: 0
          }}
        >
          {/* {names.map((name, i) => (
            <MenuItem key={name} value={name} className="checkBoxList">
              <Checkbox checked={!(listName.indexOf(name) > -1)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))} */}
          {Object.keys(names).map((item, i) => {
            return (
            <MenuItem key={names[item]} value={names[item]} className="checkBoxList">
              <Checkbox checked={!(listName.indexOf(names[item]) > -1)} />
              <ListItemText primary={names[item]} />
            </MenuItem>
          )})}
        </Select>
      </Box>
  );
}

export default ButtonForMultiSelect
