import React from 'react'
import { sortableElement } from "react-sortable-hoc";

const DragableTableRow = sortableElement(({ children }) => {
  return (
    <React.Fragment>
      { children }
    </React.Fragment>
  )
})

export default DragableTableRow
