import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteDialog = (props) => {
  const {item} = props

  useEffect(() => {
    // console.log(props, 'props../')
  }, [])
  
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        onClose={() => props.handleModal(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Delete {item && item.field_label && item.field_label}
          {item && item.name && item.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to deactivate: {item && item.field_label && item.field_label}
            {item && item.name && item.name}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleModal(false)} color="primary">
            Cancel
          </Button>
          {
            props.handleDelete ?
            <Button onClick={() => props.handleDelete()} color="secondary" autoFocus>
              Delete
            </Button>
            :
            <Button onClick={() => props.handleModal(false)} color="secondary" autoFocus>
              Delete
            </Button>
          }
          
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteDialog
