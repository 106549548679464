import React from 'react'
import {Link} from 'react-router-dom'
import { 
  Tooltip,
  Fab,
  Grid,
  Paper
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Wrapper from '../../components/Global/Wraper'
import EventsTable from '../../components/PageCompnent/Events/EventsTable'
import useStyle from '../../components/Global/useStyles'

const Events = props => {
  const classes = useStyle()

  const data = [
    {
      id: 1,
      name: "Student",
      attached_to_program: ['Wild Surakshe']
    },
    {
      id: 2,
      name: "Teacher",
      attached_to_program: ['Wild Surakshe', 'Wild Seve']
    },
    {
      id: 3,
      name: "Farmer",
      attached_to_program: ['Wild Seve', 'Wild Shale']
    } ,
    {
      id: 4,
      name: "SDM",
      attached_to_program: ['Wild Surakshe','Wild Seve']
    }
  ]

  return (
    <Wrapper pageTitle="Events">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper} style={{marginBottom: 60, padding: '0'}}>
            <EventsTable data={data} />
          </Paper>
        </Grid>
      </Grid>
      <Tooltip title='Add New Event Type' aria-label='add-new-event-type' arrow style={{position: 'absolute', bottom: 25, right: 25}}>
        <Link to='/events/add'>
          <Fab className="circleButton greebBtn">
            <AddIcon />
          </Fab>
        </Link>
      </Tooltip>
    </Wrapper>
  )
}

export default Events
