import React from 'react'
import {
  Box,
 } from '@material-ui/core'
 import { Link } from 'react-router-dom'
 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';

 import '../../../scss/_tableActionIcons.scss'

const DesignationActionIcon = props => {
  const {data} = props
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const suspendHandle = () => {
    props.handleAction(data)
  }

  return (
    <React.Fragment>
      <Box className="tableActionIcons">
        <Link to='#' onClick={suspendHandle} className="actionIcon edit" title="Edit">
          <EditIcon onClick={handleClose} fontSize="small" /> 
        </Link>
        <Box component="span" className="actionIcon delete" onClick={()=>props.getSuspendedData(data)} title="Delete">
          <DeleteIcon fontSize="small" />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default DesignationActionIcon
