import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {
  Box,
  Tooltip,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import Wrapper from '../../components/Global/Wraper'
import '../../scss/_table.scss'
import {connect} from 'react-redux'
import {getViewList,syncSingleView,syncViewList} from '../../store/actions/form'
import AddEditListView from './AddEditListView';
import DeleteListView from './DeleteListView';
import GetAppIcon from '@material-ui/icons/GetApp';
import {url} from '../../helper/CommonHelper'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import NotifierSnack, {openSnackbar} from '../../components/Global/NotifierSnackbar'

class ViewList extends Component {
  constructor(props){
    super(props)
    this.state = {
      deleteOpen:false,
      buttonText:"",
      title:"",
      open:false,
      viewList:[],
      item:"",
      loader: false,
    }
    this.handleDeleteViewList = this.handleDeleteViewList.bind(this)
    this.handleAddEditViewList = this.handleAddEditViewList.bind(this)
    this.getAllViewList = this.getAllViewList.bind(this)
    this.handleCloseViewList = this.handleCloseViewList.bind(this)
  }
  handleDeleteViewList(value, data =""){
    if(value){
      this.setState(prevState => ({
        ...prevState,
        item:data,
        deleteOpen:value
      }))
      return
    }
    this.setState(prevState => ({
      ...prevState,
      item:"",
      deleteOpen:value
    }))
    
  }
  handleAddEditViewList(value, data ="", type="add"){
    if(type==="add"){
      this.setState(prevState => ({
        ...prevState,
        open:value,
        buttonText:"Save",
        title:"Add URL for form"
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        open:value,
        item:data,
        buttonText:"Update",
        title:"Update URL for form"
      }))
    }
  }
  handleCloseViewList(){
    this.setState(prevState => ({
      ...prevState,
      item:"",
      open:false,
    }))
  }
  getAllViewList(){
    this.props.getViewList().then(({data}) => {
      this.setState(prevState => {
        this.setState(prevState => ({
          ...prevState,
          viewList:data
        }))
      })
    })
  }
  syncSingleViewList = (_id) => {
    this.props.syncSingleView(_id).then(() => {
      openSnackbar({ message: 'Successfully Sync', status: 'success' });
    }).catch(err => {
      openSnackbar({ message: 'Something went Wrong', status: 'error' });
    })
  }
  syncViewList = () => {
    this.props.syncViewList().then(() => {
      openSnackbar({ message: 'Successfully Sync', status: 'success' });
    }).catch(err => {
      openSnackbar({ message: 'Something went Wrong', status: 'error' });
    })
  }
  componentDidMount(){
    this.setState(prevState => ({
      ...prevState,
      customForm: this.state.customForm,
      loader: true
    }))
    this.getAllViewList()
  }

  render(){
    return (
      <Wrapper pageTitle="Custom Form">
        <NotifierSnack />
        <Box className="tableTopContentMain">
          <Box className="pageTableTitle">
            <h2>All View List</h2>
          </Box>
          <Box className="tableTopActionIcons">
            <Button onClick={() => this.syncViewList()} variant="contained" color="secondary">Sync</Button>
          </Box>
        </Box>
        <Box className='tableBox marginB80'>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Download</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.viewList.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      <a href={url+row.excel_file} target="_blank">
                        <GetAppIcon size="small" />
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => this.syncSingleViewList(row._id)}>
                        <SyncIcon size="small"/>
                      </IconButton>
                      <IconButton style={{marginLeft:20}} onClick={() => this.handleAddEditViewList(true, row, "edit")}>
                        <EditIcon size="small"/>
                      </IconButton>
                      <IconButton style={{marginLeft:10}} onClick={() => this.handleDeleteViewList(true, row)}>
                        <DeleteOutlineIcon size="small"/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Tooltip title='Add New Form' aria-label='add-new' arrow style={{position: 'absolute', bottom: 25, right: 25}}>
          <Link onClick={() => this.handleAddEditViewList(true)}>
            <Fab className="circleButton greebBtn">
              <AddIcon />
            </Fab>
          </Link>
        </Tooltip>
        <AddEditListView 
          title={this.state.title}
          buttonText={this.state.buttonText} 
          open={this.state.open} 
          item={this.state.item}
          getAllViewList={this.getAllViewList}
          handleAddEditViewList={this.handleAddEditViewList}
          handleCloseViewList={this.handleCloseViewList}
        />
        <DeleteListView 
          buttonText={this.state.buttonText} 
          open={this.state.deleteOpen} 
          item={this.state.item}
          getAllViewList={this.getAllViewList}
          handleDeleteViewList={this.handleDeleteViewList}
        />
      </Wrapper>
    )
  }
}

export default connect(null, {getViewList,syncSingleView,syncViewList})(ViewList)
