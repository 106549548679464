import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModulePermissionDialog(props) {
  const onDisagree = () => {
    props.handlePermissionModal(false);
    props.handleLink(0);
  };
  const onAgree = () => {
    props.programModuleActivate();
  };
  return (
    <div>
      <Dialog
        open={props.openModulePermission}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Activate Module</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to activate this module?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDisagree()} color="primary">
            Don't Activate
          </Button>
          <Button onClick={() => onAgree()} color="primary" autoFocus>
            Activate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
