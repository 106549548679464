
export function sortData(data, field){
  if(data && data.length > 0){
    return data.sort((a,b) => {
      if(a[field] > b[field])
        return 1
      else if(a[field] < b[field])
        return -1
      else
        return 0
    })
  }
  return []
} 
export const url = "https://api.wildconnect.in/"
// export const url = "https://api.cws-main.cobold.xyz/"
// export const url = "http://192.168.0.111:6553/"
// export const url = "http://192.168.43.84:6553/"

