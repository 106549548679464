import React from 'react';
import {Button,Dialog,DialogContentText, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import '../../scss/_dialog.scss'

function DeleteModal(props) {
  let color, buttonText , titleText = ""
  if(typeof props.status == "undefined"){
    buttonText = "Yes, delete it"
    titleText = "Delete"
    color = "secondary"
  }else if(props.status === true){
    buttonText = "Yes, deactivate it"
    titleText = "Deactivate"
    color = "secondary"
  }else{
    buttonText = "Yes, activate it"
    titleText = "Activate"
    color = "primary"
  }
  return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        aria-labelledby="max-width-dialog-title"
        onClose={() => props.handleModal(false)}
        className="customDialog"
      >
        <DialogTitle id="responsive-dialog-title">{titleText} {props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Do you really want to {titleText.toLowerCase()} {props.item}?
          </DialogContentText> 
        </DialogContent>
        <DialogActions className="deleteDialogBtn">
          <Button 
            onClick={() => {props.action && props.action({_id:props._id})}} 
            color={color} 
            autoFocus
            className="greenBtn"
          >
            {buttonText}
          </Button>
          
          <Button 
            autoFocus 
            onClick={() => props.handleModal(false)} 
            color="default"
          >
            No, go back
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default (DeleteModal)
