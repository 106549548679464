import axios from '../mainaxios'

export const getAllGoal = (program_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`goal/${program_id}/obj`, {
    headers:{
      Authorization: token
    }
  })
}
export const getFullGoals = (program_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`goal/${program_id}/full`, {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleGoal = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`goal/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createGoal = (goal) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('goal', goal, {
    headers:{
      Authorization: token
    }
  })
}
export const editGoal = (goal) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`goal/${goal._id}`, goal, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteGoal = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`goal/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const getGaolTarget = (data) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post(`goal/target`, data, {
    headers:{
      Authorization: token
    }
  })
}