import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, TextField, DialogContent, DialogContentText, DialogTitle, Box } from '@material-ui/core';
import { editUser } from '../../store/actions/user'
import { connect } from 'react-redux'
import NotifierSnack, { openSnackbar } from '../../components/Global/NotifierSnackbar'



const AlertDialog = (props) => {
  const [user, setUser] = React.useState({
    _id: props.data._id,
    email: props.data.email,
    password: "",
    confirm_password: ""
  })
  const handleSubmit = () => {
    console.log("data", user)
    props.editUser(user).then(({ data }) => {
      props.changePasswordModal(false)
    }).catch(err => {
      console.log("error", err)
    })
  }
  return (
    <Dialog
      fullWidth={true}
      width={600}
      open={props.open}
      onClose={() => props.changePasswordModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box className="formControl">
            <TextField
              type='text'
              disabled={true}
              label="Email"
              required
              autoFocus
              variant="outlined"
              name='email'
              value={user.email}
              onChange={(event) => setUser({ ...user, email: event.target.value })}
            />
          </Box>
          <Box className="formControl" style={{ marginTop: 15 }}>
            <TextField
              type='text'
              label="Password"
              required
              variant="outlined"
              name="password"
              value={user.password}
              onChange={(event) => setUser({ ...user, password: event.target.value })}
            />
          </Box>
          <Box className="formControl" style={{ marginTop: 15 }}>
            <TextField
              type='text'
              label="Confirm Password"
              required
              variant="outlined"
              name="confirm_password"
              value={user.confirm_password}
              onChange={(event) => setUser({ ...user, confirm_password: event.target.value })}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.changePasswordModal(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Change
        </Button>
      </DialogActions>
      <NotifierSnack />
    </Dialog>
  )
}

export default connect(null, { editUser })(AlertDialog)