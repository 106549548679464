import React, {Component} from 'react'
import {
  Box,
  Grid,
 } from '@material-ui/core'
 import InputRange from 'react-input-range'
 import "react-input-range/lib/css/index.css";
 import '../../../scss/_goalRange.scss'

class GoalRange extends Component {
  render(){
    return (
      <Box className="goalRangeMain">
        {
          <Box className="goalRangeContainer">
          {
            this.props.goalsRange.map((goal, i) => (
              <Grid container spacing={3} key={i}>
                <Grid item xs={4} sm={3} md={2}>
                  <span>{goal.goal_type && goal.goal_type.name}</span>
                </Grid>
                <Grid item xs={8} sm={9} md={10}>
                  <Box className="rangeSlider">
                    <InputRange
                      maxValue={goal.target}
                      minValue={0}
                      value={goal.achieve !== 0 && goal.achieve}
                      onChange={()=> this.handleChange}
                      name={goal.name}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))
          }
          </Box>
        }
      </Box>
    )
  }
}

export default GoalRange
