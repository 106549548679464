import React, { useState, useEffect } from 'react'
import {
  Box,
  Divider,
} from '@material-ui/core';
import GoalsTable from './GoalsTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import { getAllGoal, createGoal, editGoal, getFullGoals, deleteGoal, getGaolTarget } from './../../../store/actions/goal'
import { editProgram, getSingleProgramFull } from '../../../store/actions/program'
import { connect } from 'react-redux'
import GoalRange from './GoalRange'
import _ from 'lodash'
import '../../../scss/_goals.scss'

const Goals = props => {
  const [loader, setLoader] = useState(true)
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [forms, setForms] = useState([])
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [fullProgram, setFullProgram] = useState(null)
  const [goals, setGoals] = useState([])
  const [fullGoals, setFullGoals] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)


  const getAllGoal = (program_id, fullTempPogram) => {
    setLoader(true)
    props.getAllGoal(program_id).then(({ data }) => {
      getFullGoals(data, fullTempPogram)
      setGoals(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }
  const getFullGoals = async (goals, fullTempPogram) => {
    setLoader(true)
    let tempGoals = _.cloneDeep(goals)
    if (props.program && fullTempPogram) {

      for (let i = 0; i < goals.length; i++) {
        let index = props.program.beneficiary_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, beneficiary_type_id: goals[i].goal_type, type: "BENEFICIARY" })
          tempGoals[i].goal_type = fullTempPogram.beneficiary_types[index]
          tempGoals[i].document_type = "BENEFICIARY"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.location_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, location_type_id: goals[i].goal_type, type: "LOCATION" })
          tempGoals[i].goal_type = fullTempPogram.location_types[index]
          tempGoals[i].document_type = "LOCATION"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.event_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, event_type_id: goals[i].goal_type, type: "EVENT" })
          tempGoals[i].goal_type = fullTempPogram.event_types[index]
          tempGoals[i].document_type = "EVENT"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.survey_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, survey_type_id: goals[i].goal_type, type: "SURVEY" })
          tempGoals[i].goal_type = fullTempPogram.survey_types[index]
          tempGoals[i].document_type = "SURVEY"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.case_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, case_type_id: goals[i].goal_type, type: "CASE" })
          tempGoals[i].goal_type = fullTempPogram.case_types[index]
          tempGoals[i].document_type = "CASE"
          tempGoals[i].achieve = data.achieve
        }
      }
    }
    setFullGoals(tempGoals)
  }
  const addGoals = (goal) => {
    let tempGoal = { ...goal, program_id: props.program._id }
    setLoader(true)
    props.createGoal(tempGoal).then(({ data }) => {
      getAllGoal(props.program._id, fullProgram)
      setLoader(false)
      handleDrawer(false)
      openSnackbar({ message: 'SuccessFully Addd Goals in', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
      openSnackbar({ message: 'Error in Adding Goal', status: 'error' });
    })
  }

  const updateGoal = (tempForm) => {
    setLoader(true)
    tempForm = _.omit(tempForm, ["created_by", "created_at"])
    props.editGoal(tempForm).then(({ data }) => {
      getAllGoal(props.program._id, fullProgram)
      handleEditDrawer(false)
      setLoader(false)
      openSnackbar({ message: 'Updated Goal Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
      openSnackbar({ message: 'Error in Edit Goal', status: 'error' });
    })
  }
  const fullSingleProgram = (_id) => {
    setLoader(true)
    props.getSingleProgramFull(_id).then(({ data }) => {
      let tempForms = [...data.beneficiary_types, ...data.case_types, ...data.event_types, ...data.location_types, ...data.survey_types]
      setForms(tempForms)
      getAllGoal(props.program._id, data)
      setFullProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err)
      setLoader(false)
    })
  }
  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }

  useEffect(() => {
    if (props.program) {
      fullSingleProgram(props.program._id)
      // getFullGoals(props.program._id)
    }
  }, [])

  const removeLocationTypeFromProgram = (goal) => {
    props.deleteGoal(goal._id).then(({ data }) => {
      getAllGoal(props.program._id, fullProgram)
      handleDrawer(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Goal Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      setDeleteModal(false)
      openSnackbar({ message: 'Error in Removing Goal', status: 'error' });
    })
  }

  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }

  return (
    <React.Fragment>
      <NotifierSnack />
      <Box className="goalsTabMain">
        <Box className="leftHeadingRightButton">
          <Box className="headingWithLeftIcon">
            <img src="/icon/push-pin.svg" alt="pin" style={{ width: 18 }} />
            <span className="heading">Goals</span>
          </Box>
          <Box></Box>
          <Box className="buttonWrap">
            <BeneficiariesDrawerTab
              buttonLabel="ADD NEW GOAL"
              name="Goal"
              title='Goal'
              existing="Select from existing"
              add_title="Create new Goal"
              noTab={true}
              isDrawerOpen={isDrawerOpen}
              handleDrawer={handleDrawer}
              forms={forms}
              handleSubmitData={addGoals}
              data={goals}
            />
          </Box>
        </Box>
        <GoalRange goalsRange={fullGoals.filter(fullGoal => fullGoal.isPinned)} />
        <Divider />
        <Box className="goalsTabMain_table" style={{ paddingTop: 10 }}>
          <GoalsTable
            isEditDrawer={isEditDrawer}
            handleEditDrawer={handleEditDrawer}
            forms={forms}
            data={fullGoals}
            removeItemTypeFromProgram={removeLocationTypeFromProgram}
            handleSubmitData={updateGoal}
            loader={loader}
            name="Goal"
            emptyMessage="No Goal Found"
            deleteModal={deleteModal}
            handleDeleteModal={handleDeleteModal}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default connect(null, { getAllGoal, getGaolTarget, createGoal, editProgram, editGoal, deleteGoal, getSingleProgramFull, getFullGoals })(Goals)
