import React from 'react'
import {
  CssBaseline, 
  Grid, 
  Typography, 
  Container, 
  makeStyles ,
  Box,
} from '@material-ui/core';

import  '../../scss/_authMainContainer.scss'

const AuthWraper = props => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth='xl' className="authMainContainer">
      <CssBaseline />
      <Grid container className="mainContainer">
        <Grid item lg={5} sm={5} className="mainContainer_col">
          <Box className={`${classes.paper} mainContainer_col_inner`}>
            <img src="./LogoCWS-black.png" alt="logo" className="logo" />
              <form className='loginForm' noValidate onSubmit={props.onSubmit}>
                {props.children}
              </form>
            <Box className="bottomText">
              <Typography component='h6'>
                CWS Wild Connect Platform 
              </Typography>
              <Typography component="h6">
                Delivered by Cobold Digital
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AuthWraper

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
