import React, { useState, useEffect } from 'react'
import {
  Container,
  Grid,
  Paper,
  Tooltip,
  Fab,
  Box,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AppsIcon from '@material-ui/icons/Apps';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom'
import useStyles from '../../components/Global/useStyles'
import NavigationMenu from '../../components/Global/NavigationMenu'
import TableUser from '../../components/common/TableUser'
import { connect } from 'react-redux'
import { getAllUser, deactivateUser } from '../../store/actions/user'
import DeleteModal from '../../components/common/DeleteModal';
import { updateData } from '../../store/reducers/DataHandler'
import ButtonForMultiSelect from '../../components/common/ButtonForMultiSelect'

const Users = props => {
  const classes = useStyles();
  const [userTitle, setUserTitle] = useState('All Users')
  const [users, setUsers] = useState([])
  const [loader, setLoader] = useState(false)
  const [activeUsers, setActiveUsers] = useState([])
  const [isActiveUsers, setIsActiveUsers] = useState(true)
  const [user, setUser] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [columns, setColumns] = useState([])
  const columnsNames = {
    emp_id: "Employee ID",
    photo: "Photo",
    name: "Name",
    email: "Email Address",
    mobile: "Mobile Number",
    role: "Role",
    designation: "Designation",
    status: "Status",
  };

  const getAllUsers = () => {
    setLoader(true)
    props.getAllUser().then(({ data }) => {
      setUsers(data)
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      console.log("err", err)
    })
  }
  const getSuspendedData = (user) => {
    setUser(user)
    handleDeleteModal(true)
  }
  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  const suspendUser = ({ _id }) => {
    setLoader(true)
    props.deactivateUser(_id).then(({ data }) => {
      let tempUsers = updateData([...users], data)
      setUsers(tempUsers)
      setLoader(false)
      setDeleteModal(false)
    }).catch(err => {
      setLoader(false)
      console.log("err", err)
    })
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  const handleColumnsName = value => {
    setColumns(value)
  }

  const handleIsActive = value => {
    setIsActiveUsers(!value)
  }

  const handleActiveUsers = (isActive) => {
    handleIsActive(isActive)
    if (isActiveUsers) {
      const onlyActive = users.filter(x => x.status === true)
      setUserTitle("Active Users")
      setActiveUsers(onlyActive)
    } else {
      setActiveUsers([])
      setUserTitle("All Users")
    }
  }
  const userSorting = (tempUsers, type, status) => {
    return tempUsers.sort((a, b) => {
      if (a[type] > b[type]) return status ? -1 : 1
      if (a[type] < b[type]) return status ? 1 : -1
      return 0
    })
  }
  const sortedUser = (type, status) => {
    if (!isActiveUsers) {
      return setActiveUsers(userSorting(activeUsers, type, status))
    }
    console.log('sorteduser calling')
    setUsers(userSorting(users, type, status))
  }

  // console.log('Users:', users)
  // console.log('Active:', isActiveSUers)
  // console.log('Active users:', activeUsers)
  // console.log('Role:', columns.indexOf('Role') > -1)

  return (
    <Box className={classes.root}>
      <NavigationMenu title="Users" />
      <main className={classes.content}>
        <Box className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box className="tableTopContentMain">
                <Box className="pageTableTitle">
                  <h2>{userTitle}</h2>
                </Box>
                <Box className="tableTopActionIcons">
                  <span className="iconWithName">
                    <ButtonForMultiSelect
                      icon={<AppsIcon fontSize="small" />}
                      label="Columns"
                      width={90}
                      names={columnsNames}
                      handleColumnsName={handleColumnsName}
                    />
                  </span>
                  <span className="iconWithName" onClick={() => handleActiveUsers(isActiveUsers)}>
                    {
                      activeUsers.length <= 0 ?
                        <>
                          <VisibilityOffOutlinedIcon fontSize="small" />
                          <span className="text">Hide Inactive Users</span>
                        </>
                        :
                        <>
                          <VisibilityOutlinedIcon fontSize="small" />
                          <span className="text">Show All Users</span>
                        </>
                    }

                  </span>
                  <span className="iconWithName download">
                    <GetAppIcon fontSize="small" />
                    <span className="text">Export Users</span>
                  </span>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ marginBottom: 60, padding: '0' }}>
                <TableUser
                  sortedUser={sortedUser}
                  data={activeUsers.length <= 0 ? users : activeUsers}
                  handleIsActive={handleIsActive}
                  loader={loader}
                  getSuspendedData={getSuspendedData}
                  handleDeleteModal={handleDeleteModal}
                  columns={columns}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
      <Tooltip title='Add user' aria-label='add-user' arrow style={{ position: 'absolute', bottom: 25, right: 25 }}>
        <Link to='/user/add'>
          <Fab className="circleButton greebBtn">
            <PersonAddIcon />
          </Fab>
        </Link>
      </Tooltip>
      <DeleteModal
        title="User"
        action={suspendUser}
        item={`${user.first_name} ${user.last_name}- ${user.email}`}
        _id={user._id}
        handleModal={handleDeleteModal}
        status={user.status}
        open={deleteModal}
      />
    </Box>
  )
  // }

}

export default connect(null, { getAllUser, deactivateUser })(Users)
