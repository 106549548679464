import React from 'react'
import {
  Box,
} from '@material-ui/core'

const MinMaxField = (props) => {
  return (
    <React.Fragment>
      <Box className='innerFormContainer'>
          <Box className='innerFormItem'>
            <label>Character Max Limit</label>
            <span>Leave blank for no limit</span>
          </Box>
          <Box className='innerFormItem'>
            <input
              type="text"
              name="max"
              value={props.maxValue}
              onChange={event => props.onChange(event, props.index)}
            />
          </Box>
        </Box>
        {/* Character Min Limit */}
        <Box className='innerFormContainer'>
          <Box className='innerFormItem'>
            <label>Character Min Limit</label>
            <span>Leave blank for no limit</span>
          </Box>
          <Box className='innerFormItem'>
            <input
              type="text"
              name="min"
              value={props.minValue}
              onChange={event => props.onChange(event, props.index)}
            />
          </Box>
        </Box>
    </React.Fragment>
  )
}

export default MinMaxField
