import React, { useState, useRef } from "react";
import {
  TextField,
  MenuItem,
  // List,
  // ListItem,
  // Checkbox,
  Button,
  // FormControlLabel,
  Box,
} from "@material-ui/core";
import Form from "../../Form/form";
import _ from "lodash";
import SimpleReactValidator from "simple-react-validator";

// const non_mandatory_fields = [
//   {
//     name: "last_name",
//     label: "Last Name",
//   },
//   {
//     name: "mobile_number",
//     label: "Mobile Number",
//   },
//   {
//     name: "secondary_contact_number",
//     label: "Secondary Contact Number",
//   },
//   {
//     name: "education_level",
//     label: "Education Level",
//   },
//   {
//     name: "address",
//     label: "Address",
//   },
//   {
//     name: "location_field",
//     label: "Location Field",
//   },
//   {
//     name: "occupation",
//     label: "Occupation",
//   },
//   {
//     name: "photo",
//     label: "Photo",
//   },
// ];
const AddEditBeneficiaryType = (props) => {
  const [, forceUpdate] = useState();
  const [form, setForm] = useState({
    name: "",
    plural_name: "",
    description: "",
    form: "",
    form_id: "",
    non_mandatory_fields: {},
  });

  const [optional, setOptional] = useState({
    last_name: false,
    mobile_number: false,
    secondary_contact_number: false,
    education_level: false,
    address: false,
    location_field: false,
    occupation: false,
    photo: false,
  });

  const simpleValidator = useRef(new SimpleReactValidator());

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const handleChangeOption = (event) => {
  //   let name = event.target.name;
  //   let checked = event.target.checked;
  //   setOptional({ ...optional, [name]: checked });
  // };
  const handleSubmit = (event, method) => {
    event.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true);
      forceUpdate(true);
      return;
    }
    let data = _.cloneDeep(form);
    data.form = data.form_id;
    data.non_mandatory_fields = optional;
    data.non_mandatory_check_fields = optional;
    props.handleSubmitData(data);
  };
  const loadDataProperly = (item) => {
    let tempBeneficiary = _.omit(
      item,
      "mandatory_fields",
      "non_mandatory_fields",
      "structures",
      "created_by"
    );
    setOptional({ ...optional, ...tempBeneficiary.non_mandatory_check_fields });
    return tempBeneficiary;
  };
  useState(() => {
    if (props.item) {
      let item = loadDataProperly(props.item);
      setForm(item);
    }
  }, []);
  return (
    <Box className="createBeneficiaryType">
      <Form
        formHeading={props.title}
        formSubheading="Use the form below to add a new beneficiary type and add it to the program"
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
          <TextField
            type="text"
            label="Beneficiary Type Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "beneficiary type name",
              form.name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Plural Name"
            variant="outlined"
            name="plural_name"
            value={form.plural_name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "plural name",
              form.plural_name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Beneficiary Type Description"
            multiline
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "beneficiary type description",
              form.description,
              "required"
            )}
          </Box>
        </Box>
        {/* <Box className="optionalField">
          <Box>
          <Box className="formControlHeading">
            <h3>Mandatory Fields:</h3>
          </Box>
          <List>
            <ListItem className="formControl">First Name</ListItem>
            <ListItem className="formControl">Last Name</ListItem>
            <ListItem className="formControl" >Mobile Number</ListItem>
            <ListItem className="formControl" >Gender</ListItem>
            <ListItem className="formControl" >Date of Birth</ListItem> */}
        {/* <ListItem className="formControl" >Photo</ListItem> */}
        {/* </List>
          </Box>
          <Box className="formControlHeading">
            <h3>Non Mandatory Fields:</h3>
          </Box>
          {
            non_mandatory_fields.map((non_mandatory_field, index) => (
              <Box className="formControl" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={optional[non_mandatory_field.name] } 
                      onChange={handleChangeOption} 
                      name={non_mandatory_field.name} 
                      color='primary'
                    />
                  }
                  label={non_mandatory_field.label}
                /> 
              </Box>
            ))
          }
        </Box> */}
        <Box className="formControl">
          <TextField
            id="attach-custom-form"
            select
            label="Attach Custom Form"
            name="form_id"
            value={form.form_id}
            onChange={handleChange}
            variant="outlined"
          >
            {props.forms.map((form) => (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) =>
              handleSubmit(
                event,
                props.item && props.item.hasOwnProperty("_id")
                  ? "update"
                  : "add"
              )
            }
          >
            {props.item && props.item.hasOwnProperty("_id")
              ? "Update & Update to Program"
              : "Save & Add to Program"}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default AddEditBeneficiaryType;
