import React from 'react'
import {
  Box,
} from '@material-ui/core'


const CheckBoxChoice = (props) => {
  return (
    <React.Fragment>
      <Box className='innerFormContainer'>
        <Box className='innerFormItem'>
          <label>Choices</label>
          <span>Enter each choice on a new line.</span>
          <span style={{marginTop: 5}}>For more control, you may specify both a value and label like this:</span>
          <span style={{marginTop: 5}}>red: Red</span>
        </Box>
        <Box className='innerFormItem'>
          <textarea 
            rows='8' 
            name='option_choice' 
            value={props.value}
            onChange={props.onChange}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default CheckBoxChoice
