import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Box,
  CardMedia,
  Avatar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom'
import DrawerListItems from './DrawerListItems'
import { connect } from 'react-redux'
import Logout from '../Global/Logout'
import { logOut } from '../../store/actions/auth'
import Notify from './Notify'
import io from 'socket.io-client'
import '../../scss/_drawerList.scss'
import { useHistory } from 'react-router-dom';


function NavigationMenu(props) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openlogout, setOpenlogout] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)
  const [user, setUser] = useState({})
  const [socket, setSocket] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logoutUser = () => {
    setAnchorEl(null)
    props.logOut({ setOpenlogout })
  }
  const handleLogoutClose = () => {
    setOpenlogout(false);
  };
  const handleLogoutModal = () => {
    setAnchorEl(null)
    setAnchorEl(null);
    setOpenlogout(true)
  }
  const handleNotifyModal = (value) => {
    setOpenNotify(value)
  }
  const handleProfile = () => {
    history.push(`/user/edit/${user._id}`);
  }

  const getUser = async () => {
    let currentUser = JSON.parse(await localStorage.getItem('cws_user'))
    currentUser.initials = currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0)
    await setUser(currentUser)
    // console.log(currentUser.first_name.charAt(0), 'currentUser')
  }

  useEffect(() => {
    // let url = 'http://localhost:6552/'
    // let socket = io.connect(url)
    // setSocket(socket)
    // socket.on("send_notification", data => {
    //   console.log("data", data)
    // })
    // return () => socket.disconnect();
    getUser()
  }, [])


  // console.log('props', props)

  return (
    <>
      <AppBar position="absolute" className={`${clsx(classes.appBar, open && classes.appBarShift)} mainHeader`}>
        <Toolbar className={`${classes.toolbar}`}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon style={{ color: "#171717" }} />
          </IconButton>
          {
            props.backLink &&
            <Box>
              <Link to={`/${props.backLink}`} className={`${classes.title} pageTitle`} title={props.parentName}>
                {/* <KeyboardBackspaceIcon  /> */}
                {props.backLink} /
              </Link>
            </Box>
          }
          {
            props.secondParent &&
            <Box>
              <Link to={`/${props.secondBackLink}`} className={`${classes.title} pageTitle`} title={props.parentName}>
                {/* <KeyboardBackspaceIcon  /> */}
                {props.secondParent} /
              </Link>
            </Box>
          }
          <Typography component="h1" variant="h6" color="inherit" noWrap className={`${classes.title} pageTitle`}>
            {props.title}
          </Typography>
          <IconButton color="inherit">
            <SearchIcon style={{ color: "#BBB6B6" }} />
          </IconButton>
          <IconButton color="inherit" onClick={() => handleNotifyModal(true)}>
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon style={{ color: "#BBB6B6" }} />
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {/* <PersonIcon style={{ color: "#BBB6B6" }} /> */}
            {user.photo ? <Avatar src={'https://auth.wildconnect.in/' + user.photo} /> : <Avatar className={classes.purple}>{user.initials}</Avatar>}


          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleLogoutModal}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Box className={classes.menuLogo}>
          {/* <h2 style={{margin: 0, color: '#fff'}}>CWS Admin</h2> */}
          <CardMedia
            style={{ width: '68%' }}
            component='img'
            image="/CWS-Logo-White.png"
            title="CWS-Logo"
          />
          <Box className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon style={{ color: '#fff' }} />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <List className="menuUlWrap">
          <DrawerListItems />
        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>

      <Logout
        open={openlogout}
        handleLogoutClose={handleLogoutClose}
        logoutUser={logoutUser}
      />
      <Notify
        open={openNotify}
        handleNotifyModal={handleNotifyModal}
      />
    </>
  )
}

export default connect(null, { logOut })(NavigationMenu)



const drawerWidth = 225;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#f7f7f7',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    color: '#444444',
    textTransform: 'capitalize',
    fontSize: 18,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#171717'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  menuLogo: {
    display: 'flex',
    paddingLeft: 15,
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
