import React from 'react'
import {
  Box,
} from '@material-ui/core'
import { sortableHandle } from "react-sortable-hoc";

const DragController = sortableHandle((props) => {
  return (
    <Box>
      {props.order}
    </Box>
  )
})

export default DragController
