import React, { useState, useEffect } from 'react'
import {
  Box,
  CircularProgress,
} from '@material-ui/core'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import '../../../scss/_slider.scss'

const ProgramSlider = props => {
  const { data } = props
  const [loader, setLoader] = useState(true)
  const [sliderData, setSliderData] = useState([])

  useEffect(() => {
    setSliderData(data)
    if (data) {
      setLoader(false)
    }
  }, [data])
  console.log(data.goals, 'data')

  const SampleNextArrow = ({ currentSlide, slideCount, ...props }) => (
    <span {...props}><ArrowForwardIosIcon /></span>
  );
  const SamplePrevArrow = ({ currentSlide, slideCount, ...props }) => (
    <span {...props}><ArrowBackIosIcon /></span>
  );

  let sliderStyle = {
    // dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: sliderData.length > 4 ? 4 : sliderData.length,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ],
  };

  // console.log("Slider Data", sliderDdata)

  return (
    <React.Fragment>
      {
        sliderData.length > 0 ?
          <>
            <Slider {...sliderStyle} className="slider">
              {sliderData.map((item, i) => {
                return (
                  <Box className="slider_inner" key={i}>
                    <span className="slider_numric">{item.achive} / {item.target}</span>
                    <span className="slider_text">{item.name}</span>
                  </Box>
                );
              })}
            </Slider>
          </>

          // <Slider {...sliderStyle} className="slider">
          //   {sliderData.map((item, i) => (
          //     <Box className="slider_inner" key={i}>
          //       <span className="slider_numric">{item.achive} / {item.target}</span>
          //       <span className="slider_text">{item.name}</span>
          //     </Box>
          //   ))}
          // </Slider>
          : <div style={{ textAlign: 'center' }}>No Goals for this Program</div>
      }

    </React.Fragment>
  )
}

export default ProgramSlider

