import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Box,
 } from '@material-ui/core'
import DesignationActionIcon from './DesignationActionIcon'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);

const TableDesignation = (props) => {

  return (
    <React.Fragment>
      <Box>
        <TableContainer>
          <Table size="small" aria-label="user table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Designation</StyledTableCell>
                <StyledTableCell>Users</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {
              props.loader ? 
              <TableBody>
                <TableRow>
                  <TableCell align="center" width="100%" colSpan={12}>
                  <Box>
                    <CircularProgress disableShrink />
                  </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
              :
              <TableBody>
              {props.data.map((designation, i) => (
                <TableRow key={i}>
                  <TableCell>{designation.name}</TableCell>
                  <TableCell>----</TableCell>
                  <TableCell align="right">
                    <DesignationActionIcon
                      data={designation}
                      getSuspendedData={props.deleteDesignationSetup}
                      handleAction={props.editDesignationModal}
                    />
                    {/* <ActionMenu 
                      data={designation}
                      handleAction={props.editDesignationModal} 
                      deleteModal={props.handleModal}
                      modalSetup={(designation) => props.deleteDesignationSetup(designation)}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            }
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}

export default withRouter(TableDesignation)