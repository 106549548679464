import axios from '../mainaxios'

export const getAllGoal = (program_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`goal/${program_id}/obj`, {
    headers: {
      Authorization: token
    }
  })
}
export const getAllNotifications = (program_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`notification/all/${program_id}`, {
    headers: {
      Authorization: token
    }
  })
}
export const getSingleGoal = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`goal/${_id}`, {
    headers: {
      Authorization: token
    }
  })
}
export const createNotification = (notification) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('notification/add', notification, {
    headers: {
      Authorization: token
    }
  })
}
export const editNotification = (notification) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`notification/update/${notification._id}`, notification, {
    headers: {
      Authorization: token
    }
  })
}
export const deleteNotification = (_id) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`notification/delete/${_id}`, {
    headers: {
      Authorization: token
    }
  })
}
export const getGaolTarget = (data) => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post(`goal/target`, data, {
    headers: {
      Authorization: token
    }
  })
}