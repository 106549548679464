import React, { Component } from "react";
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box,
  Checkbox,
  ListItemText,
  Input,
} from "@material-ui/core";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import Form from "../../components/Form/form";
import Wrapper from "../../components/Global/Wraper";
import { history } from "../../routes/history";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { createProgram, syncSingleProgram } from "../../store/actions/program";
import { getAllUser } from "../../store/actions/user";
import BackToLink from "../../components/common/BackToLink";
import NotifierSnack, {
  openSnackbar,
} from "../../components/Global/NotifierSnackbar";
import ClearIcon from "@material-ui/icons/Clear";

import "../../scss/_form.scss";

class AddProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        program_head: [],
        photo: "",
        description: "",
        no_of_days_for_edit_program: 0,
        documentVisible: "",
      },
      users: [],
      selectedTrue: false,
      imagePreview: "",
    };
    this.getUsers = this.getUsers.bind(this);
    this.validator = new SimpleReactValidator();
  }

  resetForm = () => {
    this.setState({
      form: {
        name: "",
        program_head: [],
        photo: "",
        description: "",
        no_of_days_for_edit_program: 0,
        documentVisible: "",
      },
      imagePreview: "",
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "program_head") {
      if (value.length > 0) {
        this.setState((prevState) => ({
          ...prevState,
          selectedTrue: true,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          selectedTrue: false,
        }));
      }
    }
    if (name === "photo") {
      let reader = new FileReader();
      value = event.target.files[0];
      reader.onloadend = () => {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          imagePreview: reader.result,
        }));
      };
      reader.readAsDataURL(value);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        form: { ...prevState.form, [name]: value },
      }));
    }
  };
  onClearImage = () => {
    this.setState((prevState) => ({
      ...prevState,
      imagePreview: "",
      form: {
        ...prevState.form,
        photo: "",
      },
    }));
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages(true);
      this.forceUpdate();
      return;
    }
    let form = this.state.form;
    let formData = new FormData();
    formData.append("name", form.name);
    formData.append("description", form.description);
    formData.append(
      "no_of_days_for_edit_program",
      form.no_of_days_for_edit_program
    );
    formData.append("program_head", JSON.stringify(form.program_head));
    formData.append("documentVisible", form.documentVisible);
    if (form.photo) formData.append("photo", form.photo);

    this.props
      .createProgram(formData)
      .then(({ data }) => {
        openSnackbar({
          message: "Added Program Successfully",
          status: "success",
        });
        this.props
          .syncSingleProgram(data._id)
          .then(() => {
            openSnackbar({
              message: "Sync To Database Successfully",
              status: "success",
            });
          })
          .catch((err) => {
            console.log("err", err, err.response);
            openSnackbar({
              message: "Error in Sync to Database",
              status: "error",
            });
          });
        history.push("/program");
      })
      .catch((err) => {
        console.log("err", err.response);
        if (err.response && err.response.status === 422) {
          openSnackbar({
            message: "Two program can not be same name",
            status: "error",
          });
        } else {
          openSnackbar({ message: "Something went wrong", status: "error" });
        }
      });
  };

  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };
  async getUsers() {
    this.props.getAllUser().then(({ data }) => {
      let tempUsers = data.filter(
        (item) => item._id !== "6012bbd82cb94c4f228a0d15"
      );
      this.setState((prevState) => ({
        ...prevState,
        users: tempUsers,
      }));
    });
  }
  componentDidMount() {
    this.getUsers();
  }

  render() {
    const { selectedTrue, form, imagePreview, users } = this.state;
    return (
      <Wrapper
        pageTitle="Add Program"
        backLink={history.location.pathname.split("/")[1]}
        maxWidth="sm"
      >
        <NotifierSnack />
        <BackToLink
          link="/program"
          label="Back to all program"
          style={{ marginLeft: -8 }}
        />
        <Form
          onSubmit={this.handleSubmit}
          formHeading="Add a new Program"
          formSubheading="Create a new program, by providing the name, description, photo and choosing the program heads."
        >
          <Box className="formControl">
            <TextField
              type="text"
              label="Program Name"
              required
              autoFocusNotifierSnackbar
              variant="outlined"
              name="name"
              value={form.name}
              onChange={this.handleChange}
            />
            <Box component="span" style={{ color: "red" }}>
              {this.validator.message("Program name", form.name, "required")}
            </Box>
          </Box>
          <Box className="formControl">
            <TextField
              type="text"
              label="Program Description"
              required
              variant="outlined"
              multiline
              rows={8}
              name="description"
              value={form.description}
              onChange={this.handleChange}
            />
          </Box>
          <Box
            className={`formControl multiSelectBox ${
              selectedTrue ? "selectedTrue" : ""
            }`}
          >
            <InputLabel id="select-program-head">
              Select Program Heads
            </InputLabel>
            <Select
              labelId="select-program-head"
              label="Select Program Heads"
              multiple
              name="program_head"
              value={form.program_head}
              onChange={this.handleChange}
              input={<Input />}
              renderValue={(selected) => {
                return users.map((user) => {
                  if (selected.indexOf(user._id) > -1) {
                    return user.first_name + " " + user.last_name + ", ";
                  }
                });
              }}
              MenuProps={this.MenuProps}
            >
              {users.map((head, i) => {
                return (
                  <MenuItem key={i} value={head._id}>
                    <Checkbox
                      checked={form.program_head.indexOf(head._id) > -1}
                    />
                    <ListItemText
                      primary={head.first_name + " " + head.last_name}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box className="formControl imageUpload">
            {imagePreview && imagePreview !== "unknown" && (
              <Box className="photoBox">
                <img src={imagePreview} alt={form.photo && form.program_name} />
                <ClearIcon onClick={this.onClearImage} size="small" />
              </Box>
            )}
            <label for="image-upload" className="customFileUpload">
              <span>Select Program Image</span>
              <AccountBoxOutlinedIcon size="small" />
            </label>
            <TextField
              id="image-upload"
              type="file"
              // label="Image Upload"
              inputProps={{
                accept: "image/x-png,image/gif,image/jpeg",
              }}
              // autoFocus
              name="photo"
              onChange={this.handleChange}
            />
          </Box>
          <Box className="formControl">
            <TextField
              type="number"
              label="Allowed number of days before disabling edit function"
              variant="outlined"
              name="no_of_days_for_edit_program"
              value={form.no_of_days_for_edit_program}
              onChange={(event) => this.handleChange(event)}
            />
            <Box component="span" style={{ color: "red" }}>
              {this.validator.message(
                "Allowed number of days before disabling edit function",
                form.no_of_days_for_edit_program,
                "required|integer|min:0,num"
              )}
            </Box>
          </Box>
          <Box
            className={`formControl multiSelectBox ${
              form.documentVisible !== "" ? "selectedTrue" : ""
            }`}
          >
            <InputLabel id="document-visible">
              Documents Access Permission
            </InputLabel>
            <Select
              id="documentVisible"
              labelId="document-visible"
              required
              label="Documents Access Permission"
              name="documentVisible"
              value={form.documentVisible}
              onChange={this.handleChange}
              variant="outlined"
            >
              <MenuItem value="ALL">Everyone can have access</MenuItem>
              <MenuItem value="OWN">Only owner can have access</MenuItem>
            </Select>
            <Box component="span" style={{ color: "red" }}>
              {this.validator.message(
                "Documents Access Permission",
                form.documentVisible,
                "required"
              )}
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </Form>
      </Wrapper>
    );
  }
}

export default connect(null, { createProgram, getAllUser, syncSingleProgram })(
  AddProgram
);
