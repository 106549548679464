import axios from 'axios';
 
const instance = axios.create(
  {
    baseURL: 'http://192.168.0.103:6552/api/',
    headers: {
      Accept: 'application/json',
    },
  }
);
export default instance;