import React, {useEffect} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import GroupCollapse from './FormGroup/GroupCollapse'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    backgroundColor: 'white'
  },
}))(TableCell);


 const FieldGroup = ({ allStructure, savedData, errorMsg }) => {

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Order</StyledTableCell>
              <StyledTableCell>Label</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
            </TableRow>
          </TableHead>
          {
            <GroupCollapse allStructure={allStructure} savedData={savedData} errorMsg={errorMsg} />
          }
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default FieldGroup
