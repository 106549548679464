import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton,
  Divider,
  Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux'
import BeneficiariesTable from './BeneficiariesTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import { createSurveyType, getAllSurveyType, editSurveyType } from '../../../store/actions/survey'
import { editProgram, deactivateModule } from '../../../store/actions/program'
import { getAllForm } from '../../../store/actions/form'
import _ from 'lodash'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import ModulePermissionDialog from './ModulePermissionDialog'

import '../../../scss/_beneficiaries.scss'

const Surveys = props => {
  const [loader, setLoader] = useState(true)
  const [forms, setForms] = useState([])
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [surveyTypes, setSurveyTypes] = useState([])
  const [program, setProgram] = useState({})
  const [addedSurveyTypes, setAddedSurveyTypes] = useState([])
  const [searchData, setSearchData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [openModulePermission, setOpenModulePermission] = useState(false)


  let { active_module } = props.program ? props.program : null

  const getAddedSurveyTypes = (data) => {
    let tempAddSurveyTypes = data.filter(survey_type => {
      if (props.program.survey_types.indexOf(survey_type._id) > -1) {
        return true
      }
      return false
    })
    setSearchData([...tempAddSurveyTypes])
    setAddedSurveyTypes(tempAddSurveyTypes)
  }
  const getSurveyNotAddedToProgram = (data) => {
    let tempSurveyTypes = data.filter(survey_type => {
      if (props.program.survey_types.indexOf(survey_type._id) > -1) {
        return false
      }
      return true
    })
    setSurveyTypes([...tempSurveyTypes])
  }
  const getSurveyTypes = () => {
    props.getAllSurveyType().then(({ data }) => {
      getAddedSurveyTypes(data)
      getSurveyNotAddedToProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }

  const addSurveyType = (surveytype) => {
    props.createSurveyType(surveytype).then(({ data }) => {
      addItemTypeToProgram(data)
      let tempSurveyTypes = _.cloneDeep(surveyTypes)
      tempSurveyTypes = [...tempSurveyTypes, data]
      setSurveyTypes(tempSurveyTypes)
      handleDrawer(false)
      openSnackbar({ message: 'Added Surveys Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Adding Surveys Type', status: 'error' });
    })
  }

  const updateSurveyType = (surveytype) => {
    props.editSurveyType(surveytype).then(({ data }) => {
      let index = addedSurveyTypes.findIndex(surveytype => surveytype._id === data._id)
      addedSurveyTypes.splice(index, 1, data)
      setAddedSurveyTypes([...addedSurveyTypes])
      setSearchData([...addedSurveyTypes])
      handleEditDrawer(false)
      openSnackbar({ message: 'Edited Surveys Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Edit Surveys Type', status: 'error' });
    })
  }
  const addItemTypeToProgram = (surveytype) => {
    setLoader(true)
    let tempProgram = { _id: program._id, survey_types: [...program.survey_types, surveytype._id] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = surveyTypes.findIndex(type => type._id === surveytype._id)
      let tempSurveyTypes = _.cloneDeep(surveyTypes)
      tempSurveyTypes.splice(index, 1)
      setSurveyTypes(tempSurveyTypes)
      setAddedSurveyTypes([...addedSurveyTypes, surveytype])
      setSearchData([...addedSurveyTypes, surveytype])
      handleDrawer(false)
      setLoader(false)
      openSnackbar({ message: 'Added Surveys Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      openSnackbar({ message: 'Error in Adding Surveys Type', status: 'error' });
    })
  }
  const removeItemTypeFromProgram = (surveytype) => {
    let temp_survey_types = program.survey_types.filter(_id => _id !== surveytype._id)
    console.log('surveytype', temp_survey_types)
    let tempProgram = { _id: program._id, survey_types: [...temp_survey_types] }
    setLoader(true)
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = addedSurveyTypes.findIndex(type => type._id === surveytype._id)
      let tempAddedSurveyTypes = _.cloneDeep(addedSurveyTypes)
      tempAddedSurveyTypes.splice(index, 1)
      setAddedSurveyTypes(tempAddedSurveyTypes)
      setSearchData([...tempAddedSurveyTypes])
      setSurveyTypes([...surveyTypes, surveytype])
      handleDrawer(false)
      setLoader(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Surveys Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      setDeleteModal(false)
      openSnackbar({ message: 'Error in Removing Surveys Type', status: 'error' });
    })
  }
  const getAllForms = () => {
    props.getAllForm(props.program._id).then(({ data }) => {
      setForms(data.filter(program => program.has_published === true))
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }

  useEffect(() => {
    setProgram(props.program)
    getAllForms()
    getSurveyTypes()
    if (props.program && props.program.active_module && props.program.active_module.surveys === false) {
      setOpenModulePermission(true)
    }
  }, []);

  const filterData = event => {
    const updateList = addedSurveyTypes.filter(item => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setSearchData(updateList)
  }

  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }

  const handlePermissionModal = (value) => {
    setOpenModulePermission(value)
  }
  const deactivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, surveys: false } }
    props.editProgram(tempProgram).then(({ data }) => {
      openSnackbar({ message: 'Survey module deactivated', status: 'success' });
      props.setProgram(data)
      props.handleLink(3)
    }).catch(err => {
      console.log('err', err)
    })
  }
  const programModuleActivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, surveys: true } }
    props.editProgram(tempProgram).then(({ data }) => {
      handlePermissionModal(false)
      props.setProgram(data)
    }).catch(err => {
      console.log('err', err)
    })
  }

  return (
    <React.Fragment>
      <NotifierSnack />
      <ModulePermissionDialog
        openModulePermission={openModulePermission}
        handlePermissionModal={handlePermissionModal}
        programModuleActivate={programModuleActivate}
        handleLink={props.handleLink}
      />
      <Box className="tabbackLinkMain">
        <Box className="link" onClick={() => props.handleLink(3)}>
          <ChevronLeftIcon />
          <span>Back to all modules</span>
        </Box>
      </Box>
      <Box className="Beneficiaries">
        <Box className="headingWrap leftTextRightSearchAndBtn">
          <span className="heading">
            <AssessmentOutlinedIcon />
            <h3>Surveys</h3>
            <span>{active_module && active_module.surveys ? "Active" : "Inactive"}</span>
          </span>
          <Box className="rightContent">
            <Button variant="contained" color="primary" onClick={deactivate}>
              {active_module && active_module.surveys ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
          </Box>
        </Box>
        <Box className="leftTextRightSearchAndBtn">
          <span className="leftTxt">Active Survey Types</span>
          <Box className="rightContent">
            <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
              <input
                type="text"
                name="searchData"
                placeholder="Search"
                className="serachInput"
                onChange={filterData}
              />
              <IconButton className="iconButton" type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className="buttonWrap">
              <BeneficiariesDrawerTab
                buttonLabel="ADD SURVEY TYPE"
                name="Add SURVEY TYPE TO PROGRAM"
                title='Survey'
                existing="Select from existing"
                add_title="Create new survey type"
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                forms={forms}
                handleSubmitData={addSurveyType}
                addItemTypeToProgram={addItemTypeToProgram}
                data={surveyTypes}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <BeneficiariesTable
          isEditDrawer={isEditDrawer}
          handleEditDrawer={handleEditDrawer}
          forms={forms}
          handleSubmitData={updateSurveyType}
          data={searchData}
          loader={loader}
          removeItemTypeFromProgram={removeItemTypeFromProgram}
          name="Survey"
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
        />
      </Box>
    </React.Fragment>
  )
}

export default connect(null, {
  createSurveyType,
  getAllSurveyType,
  editSurveyType,
  editProgram,
  getAllForm, deactivateModule
})(Surveys)
