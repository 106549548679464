export const CREATEUSER = "CREATEUSER"
export const LOGIN = "LOGIN"
export const OTP = "OTP"
export const DEACTIVATE = "DEACTIVATE"
export const UPDATEUSER = "UPDATEUSER"
export const GETALLUSER = "GETALLUSER"
export const GETSINGLEUSER = "GETSINGLEUSER"
export const LOADING = "LOADING"
export const ERROR = "ERROR"
export const CHECKTOKEN = "CHECKTOKEN"
export const RESENDOTP = "RESENDOTP"
export const LOGOUT = "LOGOUT"




/////// Role type /////////////////

export const GETALLROLE = "GETALLROLE"
export const GETSINGLEROLE = "GETSINGLEROLE"
export const CREATEROLE = "CREATEROLE"
export const UPDATEROLE = "UPDATEROLE"
export const DELETEROLE = "DELETEROLE"

////// DESIGNATION TYPE //////////////

export const GETALLDESIGNATION = "GETALLDESIGNATION"
export const GETSINGLEDESIGNATION = "GETSINGLEDESIGNATION"
export const UPDATEDESIGNATION = "UPDATEDESIGNATION"
export const DELETEDESIGNATION = "DELETEDESIGNATION"




