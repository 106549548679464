import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {createViewList,updateViewList} from './../../store/actions/form'
import {connect} from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle';
import { 
  TextField,
  Box,
 } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const AddEditListView = (props) => {
  const [view, setView] = React.useState({
    excel_file:"",
    name:"",
  })
  const [error, setError] = React.useState(false)
  const [review, setReView] = React.useState({
    excel_file:"",
    name:"",
  })
  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    if(name==="excel_file"){
      value = event.target.files[0]  
      console.log("value", value)    
    }
    setView(prevState => ({...prevState, [name]:value}))
  };
  const handleSubmit = () => {
    if(!view.name || !view.excel_file){
      setError(true)
      return 
    }
    let formData = new FormData()
    if(props.buttonText==="Save"){
      formData.append("name", view.name)
      formData.append("excel_file", view.excel_file)
      props.createViewList(formData).then(({data})  => {
        props.getAllViewList()
        props.handleCloseViewList()
        setView({...review})
      }).catch(err => console.log('err',err))
    }else{
      formData.append("name", view.name)
      if(view.excel_file){
        formData.append("excel_file", view.excel_file)
      }
      props.updateViewList(formData, view._id).then(({data})  => {
        props.getAllViewList()
        props.handleCloseViewList()
        setView({...review})
      }).catch(err => console.log('err',err))
    }
  }
  useEffect(() => {
    console.log('props.item',props.item)
    setView({
      ...view,
      _id:props.item._id,
      name:props.item.name
    })
  }, [props])
  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Box className="formControl">
            <TextField 
              type='text'
              label="Name"
              required
              autoFocusNotifierSnackbar
              variant="outlined"
              name='name'
              value={view.name}
              onChange={handleChange}
            />
            {
              error && !view.name && <span style={{color:'red'}}>Name field required</span>
            }
            {/* <Box component="span" style={{color: 'red'}}>
              {this.validator.message('Program name', form.name, 'required')}
            </Box> */}
          </Box>
        </DialogContent>
        <DialogContent>
          <Box className="formControl imageUpload">
            <label for="image-upload" className="customFileUpload">
              <span>Upload Excel File</span>
              <AttachFileIcon size="small" />
            </label>
            <TextField
              id='image-upload'
              type='file'
              name="excel_file"
              onChange={handleChange}
            />
            {
              error && !view.excel_file && <span style={{color:'red'}}>File field required</span>
            }
            <span>{view.excel_file.name}</span>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.handleCloseViewList()}} color="primary">
            cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {props.buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default connect(null, {createViewList, updateViewList})(AddEditListView)