import React, {useState, useEffect} from 'react'
import { 
  Container,
  Grid,
  Paper,
  Tooltip,
  Fab,
  Link
} from '@material-ui/core';
// import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../../components/Global/useStyles'
import NavigationMenu from '../../components/Global/NavigationMenu'
import TableDesignation from '../../components/PageCompnent/Designation/TableDesignation'
import {connect} from 'react-redux'
import {getAllDesignation, createDesignation, editDesignation, deactivateDesignation} from '../../store/actions/designation'
import {addData, updateData, deleteData} from '../../store/reducers/DataHandler'
import DeleteModal from '../../components/common/DeleteModal';
import {sortData} from '../../helper/CommonHelper'
import _ from 'lodash'
import AddEditDesignation from './AddEditDesignation'
import checkPermission from './../../helper/checkPermission'

function Designations(props) {
  const classes = useStyles();
  const [designations, setDesignations] = useState([])
  const [designation, setDesignation] = useState([])
  const [openDesignation, setOpenDesignation] = useState(false)
  const [addEditModal, setAddEditModel] = useState({})
  const [openDelete, setOpenDelete] = useState(false)
  const [loader, setLoader] = useState(false)

  const handleDesignationModal = (value,type="") => {
    if(type==="add"){
      setAddEditModel({
        title:"Add Designation",
        actionButton:"Save",
        action:addDesignation,
        can_perform: checkPermission("can_create_designation")
      })
    }
    setOpenDesignation(value)
  }
  
  function updateDesignation(form){
    let tempDesignation = _.cloneDeep(form)
    props.editDesignation(tempDesignation).then(({data}) => {
      let tempDesignations = updateData([...designations], data)
      setDesignations(sortData(tempDesignations, "name"))
      setOpenDesignation(false)
    }).catch(err => {
      console.log(err)
    })
  }
  function editDesignationModal(form){
    setAddEditModel({
      title:"Update Designation",
      actionButton:"Update",
      action:updateDesignation,
      form:form,
      can_perform: checkPermission("can_edit_designation")
    })
    setOpenDesignation(true)
  }
  const addDesignation = (form,setForm) => {
      props.createDesignation(form).then(({data}) => {
      let tempDesignations = addData(designations, data)
      setDesignations(sortData(tempDesignations, "name"))
      setForm({name:""})
      setOpenDesignation(false)
    }).catch(err => {
      console.log('err',err)
    })
  }

  function deleteDesignation(_id) {
    props.deactivateDesignation(_id).then(({data}) => {
      let tempDesignations = deleteData([...designations], _id)
      setDesignations(sortData(tempDesignations, "name"))
      setOpenDelete(false)
    }).catch(err=>{
      console.log("err", err)
    })
  }
  function handleDeleteModal(value){
    setOpenDelete(value)
  }
 
  function deleteDesignationSetup(designation){
    setDesignation(designation)
    setOpenDelete(true)
  }
  const getDesignations = () =>{
    setLoader(true)
    props.getAllDesignation().then(({data}) => {
      setDesignations(sortData(data, "name"))
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      console.log("err",err)
    })
  }
  useEffect(() => {
    getDesignations()
  },[])

  return (
    <>
      <div className={classes.root}>
        <NavigationMenu title="Designation" />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}> 
            <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <TableDesignation 
                      data={designations}
                      editDesignationModal = {(form) => editDesignationModal(form)}
                      deleteDesignationSetup={deleteDesignationSetup}
                      loader={loader}
                    />
                  </Paper>
                </Grid>
              </Grid>
          </Container>
        </main>
          {
            checkPermission("can_create_designation") && 
            <Tooltip title='Add Designation' aria-label='add-designation' arrow style={{position:         'absolute', bottom: 25, right: 25}}>
              <Link href="#" onClick={() => handleDesignationModal(true, "add")}>
                <Fab className="circleButton greebBtn">
                  <AddIcon />
                </Fab>
              </Link>
            </Tooltip>
          }
        </div>
      {
        addEditModal.can_perform && 
        <AddEditDesignation 
          open={openDesignation}
          handleDesignationModal={handleDesignationModal}
          modal={addEditModal}
        />
      }
      {
        checkPermission("can_delete_designation") && 
        <DeleteModal
          title="Designation"
          action={({_id}) => deleteDesignation(_id)}
          item={designation.name}
          _id={designation._id}
          handleModal={(value) => handleDeleteModal(value)}
          status={designation.status}
          open={openDelete}
        />
      }
      
    </>
  )
} 

export default connect(null, {getAllDesignation, createDesignation, editDesignation,deactivateDesignation})(Designations)





