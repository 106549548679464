import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  TextField,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import Form from '../../Form/form'
import { getAllForm } from '../../../store/actions/form'
import { createBenificiaryType } from '../../../store/actions/beneficiary'
import SimpleReactValidator from 'simple-react-validator'
import _ from 'lodash'

const AddNewGoal = (props) => {
  const [, forceUpdate] = useState()
  const [form, setForm] = useState({
    title: '',
    body: '',
    program: '',
  })


  const simpleValidator = useRef(new SimpleReactValidator())
  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    let tempForm = _.cloneDeep(form)
    props.handleSubmitData(tempForm)
  }

  useState(() => {
    if (props.item) {
      // let goal_type = props.item.goal_type._id
      setForm({ ...props.item })
    }
  }, [])
  return (
    <Box className="createBeneficiaryType">
      <Form
        onSubmit={handleSubmit}
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
          <label style={{ fontSize: '0.857rem', fontWeight: 'bold', display: 'inline-block', marginBottom: 10 }}>Title</label>
          <TextField
            type='text'
            label="Enter Notification Title"
            variant="outlined"
            name='title'
            value={form.title}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message('Notification Title', form.title, 'required')}
          </Box>
        </Box><Box className="formControl">
          <label style={{ fontSize: '0.857rem', fontWeight: 'bold', display: 'inline-block', marginBottom: 10 }}>Content</label>
          <TextField
            multiline
            label="Enter notification content"
            variant="outlined"
            name='body'
            value={form.body}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message('Notification Content', form.body, 'required')}
          </Box>
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) => handleSubmit(event, props.item && props.item.hasOwnProperty("_id") ? "update" : "add")}
          >
            {props.item && props.item.hasOwnProperty("_id") ? "Update" : "Save"}
          </Button>
        </Box>
      </Form>
    </Box>
  )
}

export default connect(null, { getAllForm, createBenificiaryType })(AddNewGoal)
