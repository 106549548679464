import React, {useState, useEffect} from 'react'
import { 
  Grid,
  Paper,
  AppBar,
  Tab,
  Tabs,
  createMuiTheme,
  MuiThemeProvider,
  Box,
} from '@material-ui/core';
import {connect} from 'react-redux'
import TabPanel from '../../components/Tabs/TabPanel'
import Wrapper from '../../components/Global/Wraper'
import useStyles from '../../components/Global/useStyles'
import BeneficiariesType from '../../components/PageCompnent/Beneficiaries/BeneficiariesType'
import {getAllBenificiaryType} from './../../store/actions/beneficiary'



import '../../scss/_tabs.scss'

const Beneficiaries = props => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [beneficiaryTypes, setBeneficiaryTypes] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = [
    {
      id: 1,
      name: "Student",
      attached_to_program: ['Wild Surakshe']
    },
    {
      id: 2,
      name: "Teacher",
      attached_to_program: ['Wild Surakshe', 'Wild Seve']
    },
    {
      id: 3,
      name: "Farmer",
      attached_to_program: ['Wild Seve', 'Wild Shale']
    } ,
    {
      id: 4,
      name: "SDM",
      attached_to_program: ['Wild Surakshe','Wild Seve']
    }
  ]
  const getBeneficiaryTypes = ()=>{
    props.getAllBenificiaryType().then(({data})=> {
      setBeneficiaryTypes(data)
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  useEffect(() => {
    getBeneficiaryTypes()
  }, []);

  return (
    <Wrapper pageTitle="Beneficiaries">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box className="tabContainerMain">
            <AppBar position="static" color="default">
              <MuiThemeProvider theme={theme}>
                <Tabs 
                  value={value} 
                  onChange={handleChange} 
                  aria-label="user-details-tab"
                  indicatorColor="secondary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  >
                  <Tab label="All Benenficiaries" {...a11yProps(0)} />
                  <Tab label="Beneficiary Types" {...a11yProps(1)} />
                </Tabs>
              </MuiThemeProvider>
            </AppBar>
            <Paper className={classes.paper}>
              <TabPanel value={value} index={0}>
                <Box className="TabContentMain">
                  <Box className="innerDemoData">
                    <span>Beneficiaries Table</span>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box className="TabContentMain">
                  <BeneficiariesType data={beneficiaryTypes} />
                </Box>
              </TabPanel>
            </Paper>
            </Box>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default connect(null, {getAllBenificiaryType})(Beneficiaries)

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#444'
    },
    secondary: {
      main: '#000'
    }
  }
});