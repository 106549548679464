import React from 'react'
import {
  Box,
} from '@material-ui/core'

const Placeholder = props => {
  return (
    <React.Fragment>
      <Box className='innerFormContainer'>
        <Box className='innerFormItem'>
          <label>Placeholder Text</label>
          <span>Appears within the input</span>
        </Box>
        <Box className='innerFormItem'>
          <input
            type="text"
            name="placeholder"
            value={props.value}
            onChange={props.onChange}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Placeholder
