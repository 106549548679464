import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import {createMuiTheme, ThemeProvider} from '@material-ui/core'
import swDevSetup from './notify/swDev'
import notifyaxios from './store/notifyAxios'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000"
    },
    secondary: {
      main: "#2EBF18"
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
// let sample_data = {
//   "title":"first notification 2",
//   "body":"test for notification 2",
//   "icon":"http://api.cws-main.cobold.xyz/static/programs/2020-10-27T13:28:41.186Z-village-school19.jpg"
// }
// swDevSetup().then(res => {
//   let subscription = localStorage.getItem("cws-subscription")
//   console.log("subscription", res)
//   notifyaxios.post('notify', {...sample_data, subscription:JSON.parse(subscription)}, {
//     headers:{
//       Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN1YmhhbUBjb2JvbGQuaW4iLCJpYXQiOjE2MDM3ODQ4NzEsImV4cCI6MTYwMzg3MTI3MX0.vSVgqa3A_KHqUVMEWWd-oasDyzEAWhd3zAyeNbHJXxs"
//     }
//   }).then(({data}) =>{
//     console.log("subscription", res, data)
//   })
// })
