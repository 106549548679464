const fieldname = [
  {
    name: "Heading",
    value: "heading",
  },
  {
    name: "Text",
    value: "text",
  },
  {
    name: "Text Area",
    value: "text-area",
  },
  {
    name: "Number",
    value: "number",
  },
  {
    name: "True/False",
    value: "boolean",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Phone",
    value: "phone",
  },
  {
    name: "Password",
    value: "password",
  },
  {
    name: "Barcode",
    value: "barcode",
  },
  {
    name: "Signature",
    value: "signature",
  },
  {
    name: "Select",
    value: "dropdown",
  },
  {
    name: "Checkbox",
    value: "checkbox",
  },
  {
    name: "Radio",
    value: "radio",
  },
  {
    name: "Date",
    value: "date",
  },
  {
    name: "Date Time",
    value: "date-time",
  },
  {
    name: "File",
    value: "file",
  },
  {
    name: "Image",
    value: "image",
  },
  // {
  //   name: 'Files',
  //   value: 'files'
  // },
  // {
  //   name: 'Gallery',
  //   value: 'gallery'
  // },
  {
    name: "Multiple Select",
    value: "multiple-select",
  },
  {
    name: "URL",
    value: "url",
  },
  {
    name: "Field Group",
    value: "field-group",
  },
  {
    name: "User Selection",
    value: "user-selection",
  },
  {
    name: "Address",
    value: "address",
  },
  {
    name: "Geo Location",
    value: "geo-location",
  },
  {
    name: "Current User",
    value: "current-user",
  },
  {
    name: "Locations",
    value: "locations",
  },
  {
    name: "Beneficiaries",
    value: "beneficiaries",
  },
  {
    name: "Cases",
    value: "cases",
  },
  // {
  //   name: 'Geo Trace',
  //   value: 'geo-trace'
  // },
  // {
  //   name: 'bracket-replace',
  //   value: 'bracket-replace'
  // },
  {
    name: "Separator",
    value: "separator",
  },
];

export default fieldname;
