import axios from '../mainaxios'


export const getAllEventType = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('eventtype', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleEventType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`eventtype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createEventType = (eventtype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('eventtype', eventtype, {
    headers:{
      Authorization: token
    }
  })
}
export const editEventType = (eventtype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`eventtype/${eventtype._id}`, eventtype, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteEventType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`eventtype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
