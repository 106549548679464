import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton, Button,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux'
import BeneficiariesTable from './BeneficiariesTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import { getAllForm } from '../../../store/actions/form'
import { createBenificiaryType, getAllBenificiaryType, editBenificiaryType } from '../../../store/actions/beneficiary'
import { editProgram, deactivateModule } from '../../../store/actions/program'
import _ from 'lodash'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import ModulePermissionDialog from './ModulePermissionDialog'
import '../../../scss/_beneficiaries.scss'

const Beneficiaries = props => {

  const [forms, setForms] = useState([])
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [beneficiaryTypes, setBeneficiaryTypes] = useState([])
  const [addedBeneficiaryTypes, setAddedBeneficiaryTypes] = useState([])
  const [searchData, setSearchData] = useState([])
  const [program, setProgram] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [openModulePermission, setOpenModulePermission] = useState(false)

  let { active_module } = props.program ? props.program : null

  const getAddedBeneficiaryTypes = (data) => {
    let tempAddBeneficiariesType = data.filter(beneficiary_type => {
      if (props.program.beneficiary_types.indexOf(beneficiary_type._id) > -1) {
        return true
      }
      return false
    })
    setSearchData([...tempAddBeneficiariesType])
    setAddedBeneficiaryTypes(tempAddBeneficiariesType)
  }
  const getBeneficiaryNotAddedToProgram = (data) => {
    let tempBeneficiariesType = data.filter(beneficiary_type => {
      if (props.program.beneficiary_types.indexOf(beneficiary_type._id) === -1) {
        return true
      }
      return false
    })
    // console.log("tempBeneficiariesType",tempBeneficiariesType);
    setBeneficiaryTypes([...tempBeneficiariesType])
  }
  const addItemTypeToProgram = (beneficiary_type) => {
    let tempProgram = { _id: program._id, beneficiary_types: [...program.beneficiary_types, beneficiary_type._id] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = beneficiaryTypes.findIndex(type => type._id === beneficiary_type._id)
      let tempBeneficiaries = _.cloneDeep(beneficiaryTypes)
      tempBeneficiaries.splice(index, 1)
      setBeneficiaryTypes(tempBeneficiaries)
      setAddedBeneficiaryTypes([...addedBeneficiaryTypes, beneficiary_type])
      setSearchData([...addedBeneficiaryTypes, beneficiary_type])
      handleDrawer(false)
    }).catch(err => {
      console.log("err", err);
    })
  }

  const getBeneficiaryTypes = () => {
    props.getAllBenificiaryType().then(({ data }) => {
      getAddedBeneficiaryTypes(data)
      getBeneficiaryNotAddedToProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }
  const getAllForms = () => {
    props.getAllForm(props.program._id).then(({ data }) => {
      setForms(data.filter(program => program.has_published === true))
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }

  useEffect(() => {
    setProgram(props.program)
    getBeneficiaryTypes()
    getAllForms()
    if (props.program && props.program.active_module && props.program.active_module.beneficiaries === false) {
      setOpenModulePermission(true)
    }
  }, []);

  const filterData = event => {
    const updateList = addedBeneficiaryTypes.filter(item => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setSearchData(updateList)
  }

  const addBeneficiaryType = (beneficiary) => {
    props.createBenificiaryType(beneficiary).then(({ data }) => {
      addItemTypeToProgram(data)
      setBeneficiaryTypes([...beneficiaryTypes, data])
      handleDrawer(false)
      openSnackbar({ message: 'Added Beneficiary Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Added Beneficiary Type', status: 'error' });
    })
  }

  const updateBeneficiaryType = (beneficiary) => {
    props.editBenificiaryType(beneficiary).then(({ data }) => {
      let index = addedBeneficiaryTypes.findIndex(beneficiaryType => beneficiaryType._id === data._id)
      addedBeneficiaryTypes.splice(index, 1, data)
      setAddedBeneficiaryTypes([...addedBeneficiaryTypes])
      setSearchData([...addedBeneficiaryTypes])
      handleEditDrawer(false)
      openSnackbar({ message: 'Edited Beneficiary Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Edit Beneficiary Type', status: 'error' });
    })
  }
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }

  const removeBeneficiaryTypeFromProgram = (beneficiary_type) => {
    let temp_beneficiary_types = program.beneficiary_types.filter(_id => _id !== beneficiary_type._id)
    let tempProgram = { _id: program._id, beneficiary_types: [...temp_beneficiary_types] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = addedBeneficiaryTypes.findIndex(type => type._id === beneficiary_type._id)
      let tempAddedBeneficiaryTypes = _.cloneDeep(addedBeneficiaryTypes)
      tempAddedBeneficiaryTypes.splice(index, 1)
      setAddedBeneficiaryTypes(tempAddedBeneficiaryTypes)
      setSearchData([...tempAddedBeneficiaryTypes])
      setBeneficiaryTypes([...beneficiaryTypes, beneficiary_type])
      handleDrawer(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Beneficiary Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      setDeleteModal(false)
      openSnackbar({ message: 'Error in Removing Beneficiary Type', status: 'error' });
    })
  }
  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  const handlePermissionModal = (value) => {
    setOpenModulePermission(value)
  }
  const deactivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, beneficiaries: false } }
    props.editProgram(tempProgram).then(async ({ data }) => {
      await openSnackbar({ message: 'Beneficiary module deactivated', status: 'success' });
      await props.setProgram(data)
      props.handleLink(3)
    }).catch(err => {
      console.log('err', err)
    })
  }
  const programModuleActivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, beneficiaries: true } }
    props.editProgram(tempProgram).then(({ data }) => {
      handlePermissionModal(false)
      props.setProgram(data)
    }).catch(err => {
      console.log('err', err)
    })
  }
  return (
    <React.Fragment>
      <NotifierSnack />
      <ModulePermissionDialog
        openModulePermission={openModulePermission}
        handlePermissionModal={handlePermissionModal}
        programModuleActivate={programModuleActivate}
        handleLink={props.handleLink}
      />
      <Box className="tabbackLinkMain">
        <Box className="link" onClick={() => props.handleLink(3)}>
          <ChevronLeftIcon />
          <span>Back to all modules</span>
        </Box>
      </Box>
      <Box className="Beneficiaries">
        <Box className="headingWrap leftTextRightSearchAndBtn">
          <span className="heading">
            <PeopleIcon />
            <h3>Beneficiaries</h3>
            <span>{active_module && active_module.beneficiaries ? "Active" : "Inactive"}</span>
          </span>
          <Box className="rightContent">
            <Button variant="contained" color="primary" onClick={deactivate}>
              {active_module && active_module.beneficiaries ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
          </Box>
        </Box>
        <Box className="leftTextRightSearchAndBtn">
          <span className="leftTxt">Active Beneficiary Types</span>
          <Box className="rightContent">
            <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
              <input
                type="text"
                name="searchData"
                placeholder="Search"
                className="serachInput"
                onChange={filterData}
              />
              <IconButton className="iconButton" type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className="buttonWrap">
              <BeneficiariesDrawerTab
                buttonLabel="ADD BENEFICIARY TYPE"
                name="Add Beneficiary TYPE TO PROGRAM"
                title='Beneficiary'
                existing="Select from existing"
                add_title="Create new beneficiary type"
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                forms={forms}
                handleSubmitData={addBeneficiaryType}
                addItemTypeToProgram={addItemTypeToProgram}
                data={beneficiaryTypes}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <BeneficiariesTable
          isEditDrawer={isEditDrawer}
          handleEditDrawer={handleEditDrawer}
          forms={forms}
          handleSubmitData={updateBeneficiaryType}
          data={searchData}
          loader={loader}
          removeItemTypeFromProgram={removeBeneficiaryTypeFromProgram}
          name="Beneficiary"
          backLink={true}
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
        />
      </Box>
    </React.Fragment>
  )
}


export default connect(
  null,
  {
    getAllForm,
    getAllBenificiaryType,
    createBenificiaryType,
    editBenificiaryType,
    editProgram, deactivateModule
  })(Beneficiaries)

