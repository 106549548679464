import React, {useEffect,} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@material-ui/core'
import Button from '@material-ui/core/Button';

export default function Logout(props) {
  const [open, setOpen] = React.useState(false);

 
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  useEffect(() => {
    setOpen(props.open)
  },[props.open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleLogoutClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you Sure want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleLogoutClose} color="primary">
            cancel
          </Button>
          <Button onClick={props.logoutUser} color="default">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
