import React, { Component } from 'react'
import { 
  TextField,
  MenuItem,
  FormControl,
  Button,
  Box,
 } from '@material-ui/core';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import Form from '../../components/Form/form'
import Wrapper from '../../components/Global/Wraper'
import {connect}   from 'react-redux'
import SimpleReactValidator from 'simple-react-validator';
import {getAllDesignation} from '../../store/actions/designation'
import {getAllRole} from '../../store/actions/role'
import {createUser,uploadUserImage} from '../../store/actions/user'
import {history} from '../../routes/history'
import BackToLink from '../../components/common/BackToLink'
import NotifierSnackbar, {openSnackbar} from '../../components/Global/NotifierSnackbar'
import checkPermission from '../../helper/checkPermission'
import ClearIcon from '@material-ui/icons/Clear';

class AddUser extends Component {
  constructor(props){
    super(props)
    this.state = {
      form: {
        employeeId:'',
        first_name: '',
        last_name: '',
        email: '', 
        mobile: '', 
        designation: '', 
        role: '', 
        image: '',
        password:'',
        preview:'',
      },
      roles:[],
      designations:[],
      regexp : /^[0-9\b]+$/,
      imagePreview: '',
    }
    this.validator = new SimpleReactValidator();
  }

  resetForm = () => {
    this.setState({
      form: {
        employeeId:'',
        first_name: '',
        last_name: '',
        email: '', 
        mobile: '', 
        designation: '', 
        role: '', 
        image: '',
        password:'',
      },
      imagePreview: '',
    })
  }
  onClearImage = () => {
    this.setState(prevState => ({
      ...prevState,
      imagePreview:'',
      form:{
        ...prevState.form,
        image:""
      }
    }))
  }
  handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    this.setState(prevState => ({
      ...prevState,
      form: { ...prevState.form, [name]:value},
    }));
    if(name==="photo"){
      let reader = new FileReader();
      value = event.target.files[0]
      reader.onloadend = () => {
        this.setState(prevState => ({
          ...prevState,
          form: { 
            ...prevState.form, 
            [name]:value,
            ["preview"]:value
          },
          imagePreview: reader.result
        }));
      }
      reader.readAsDataURL(value)
    }else{
      this.setState(prevState => ({
        ...prevState,
        form: { ...prevState.form, [name]:value},
      }));
    }
  }
  
  handleSubmit = event => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages(true)
      this.forceUpdate()
      return
    } 
    this.props.createUser(this.state.form).then(({data})=>{
      openSnackbar({ message: 'Added User Successfully', status: 'success' })
      if(this.state.imagePreview){
        let formData = new FormData() 
        formData.append("photo",this.state.form.photo)
        formData.append("preview", this.state.imagePreview)
        this.props.uploadUserImage(data._id, formData).then(() => {
          history.push('/user')
        })
      }else{
        history.push('/user')
      }
    })
    .catch(err => {
      console.log("error", err)
      openSnackbar({ message: 'Error in Adding User', status: 'error' })
    })
  }
  getAllRoles = () => {
    this.props.getAllRole().then(({data}) => {
      this.setState(prevState => ({
        ...prevState,
        roles:data
      }))
    }).catch(err => {
      console.log("err", err)
    })
  }
  getAllDesignation(){
    this.props.getAllDesignation().then(({data}) =>{
      this.setState(prevState => ({
        ...prevState,
        designations:data
      }))
    }).catch(res => console.log('res', res))
    
  }
  componentDidMount(){
    this.getAllRoles()
    this.getAllDesignation()
  }
  render() {
    const {form, imagePreview} = this.state
    let can_add = checkPermission("can_create_user")
    return (
      <React.Fragment>
        <NotifierSnackbar />
      <Wrapper 
        pageTitle="Add User" 
        maxWidth="sm"
        backLink={history.location.pathname.split('/')[1]}
      >
        <BackToLink 
          link="/user"
          label="Back to all users"
          style={{marginLeft: -8}}
        />
        <Form 
          onSubmit={this.handleSubmit} 
          formHeading="Add a new User"
          >
          <Box className="formControl">
            <TextField 
              type='text'
              label="First Name"
              required
              variant="outlined"
              name='first_name'
              value={form.first_name}
              onChange={this.handleChange}
            />
            <Box component="span" className="validationMessage">
              {this.validator.message('First Name', form.first_name, 'required')}
            </Box>
          </Box>
          <Box className="formControl">
            <TextField 
              type='text'
              label="Last Name"
              required
              variant="outlined"
              name='last_name'
              value={form.last_name}
              onChange={this.handleChange}
            />
            <Box component="span" className="validationMessage">
              {this.validator.message('Last Name', form.last_name, 'required')}
            </Box>
          </Box>
          <Box className="formControl">
            <TextField 
              type='email'
              label="Email"
              placeholder="exampl@xyz.com"
              required
              variant="outlined"
              name="email"
              value={form.email}
              onChange={this.handleChange}
            />
            <Box component="span" className="validationMessage">
              {this.validator.message('Email', form.email, 'required|email')}
            </Box>
          </Box>
          <Box className="formControl">
          <TextField 
            type='tel'
            label="Phone No."
            inputProps={{
              maxLength: 10,
            }}
            variant="outlined"
            name="mobile"
            value={form.mobile}
            onChange={this.handleChange}
          />
          <Box component="span" className="validationMessage">
            {this.validator.message('Phone No.', form.mobile, 'required')}
          </Box>
          </Box>
          <Box className="formControl">
            <TextField 
              type='text'
              label="Employee ID"
              variant="outlined"
              name="employeeId"
              value={form.employeeId}
              onChange={this.handleChange}
            />
            <Box component="span" className="validationMessage">
              {this.validator.message('Employee ID', form.employeeId, 'required')}
            </Box>
          </Box>
          <Box className="formControl">
            <FormControl>
              {/* <InputLabel id="designation-label">Designation</InputLabel> */}
              <TextField
                labelId="designation"
                id="designation"
                select
                label="Designation"
                name="designation"
                variant="outlined"
                value={form.designation}
                onChange={this.handleChange}
              >
                {this.state.designations.map(designation => {
                  return <MenuItem key={designation._id} value={designation._id}>{designation.name}</MenuItem>
                })}
              </TextField>
              <Box component="span" className="validationMessage">
                {this.validator.message('Designation', form.designation, 'required')}
              </Box>
            </FormControl>
          </Box>
          <Box className="formControl">
            <FormControl>
              {/* <InputLabel id="role-label">Role</InputLabel> */}
              <TextField
                id="role"
                select
                label="Role"
                name="role"
                value={form.role}
                onChange={this.handleChange}
                variant="outlined"
                >
                {this.state.roles.map(role => {
                  return <MenuItem key={role._id} value={role._id}>{role.role}</MenuItem>
                })}
              </TextField>
              <Box component="span" className="validationMessage">
                {this.validator.message('Role', form.role, 'required')}
              </Box>
            </FormControl>
          </Box>
          <Box className="formControl">
            <TextField 
              type='text'
              label="Password"
              variant="outlined"
              name="password"
              value={form.password}
              onChange={this.handleChange}
            />
            <Box component="span" className="validationMessage">
              {this.validator.message('Password', form.password, 'required')}
            </Box>
          </Box>
          <Box className="formControl imageUpload">
            {
              imagePreview &&
              <Box className="photoBox">
                <img src={imagePreview} alt={form.photo && form.program_name} />
                <ClearIcon onClick={this.onClearImage} size="small" />
              </Box>
            }
            <label for="image-upload" className="customFileUpload">
              <span>Select User Image</span>
              <AccountBoxOutlinedIcon size="small"/> 
            </label>
            <TextField 
              id='image-upload'
              type='file'
              name="photo"
              // label="Image Upload"
              inputProps={{
                accept: "image/x-png,image/gif,image/jpeg"
              }}
              autoFocus
              onChange={this.handleChange}
            />
          </Box>
          {
            can_add && 
            <Button
              type="submit"
              // fullWidth
              variant="contained"
              // color="primary"
              className='customBtn greebBtn'
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          }
          
        </Form>
      </Wrapper>
      </React.Fragment>
    )
  }
}

export default connect(null, {getAllRole, uploadUserImage, getAllDesignation, createUser})(AddUser)
