import React, { useState, useRef } from "react";
import {
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  Box,
  Radio,
  RadioGroup,
  // List,
  // ListItem,
} from "@material-ui/core";
import Form from "../../Form//form";
import MultipleSelectPreEventPostEvent from "../../common/MultipleSelectPreEventPostEvent";
import _ from "lodash";
import "../../../scss/_chipInput.scss";
import SimpleReactValidator from "simple-react-validator";

const AddEditEventType = (props) => {
  const [, forceUpdate] = useState();
  const [form, setForm] = useState({
    name: "",
    plural_name: "",
    description: "",
    form: "",
    form_id: "",
    pre_event_form_ids: [],
    post_event_form_ids: [],
    pre_event_forms: [],
    post_event_forms: [],
    has_checklist: false,
    check_list_items: [],
    has_attendies: false,
  });
  const [checkList, setCheckList] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true);
      forceUpdate(true);
      return;
    }
    let tempForm = _.cloneDeep(form);
    tempForm.form = tempForm.form_id;
    tempForm.check_list_items = checkList;
    tempForm.pre_event_forms = tempForm.pre_event_form_ids;
    tempForm.post_event_forms = tempForm.post_event_form_ids;
    props.handleSubmitData(tempForm);
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      setCheckList([...checkList, form.check_list_value]);
      setForm((prev) => ({
        ...prev,
        check_list_value: "",
      }));
    }
  };

  const handleDelete = (item) => {
    let filter = checkList.filter((value, i) => i !== item);
    setCheckList(filter);
  };

  const loadDataProperly = (item) => {
    let tempData = _.omit(item, "mandatory_fields", "created_by");
    return tempData;
  };
  useState(() => {
    if (props.item) {
      let item = loadDataProperly(props.item);
      setCheckList(props.item.check_list_items);
      setForm(item);
    }
  }, []);

  return (
    <Box className="createBeneficiaryType">
      <Form
        formHeading={props.title}
        formSubheading="Use the form below to add a new case type and add it to the program"
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
          <TextField
            type="text"
            label="Event Type Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "event type name",
              form.name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Plural Name"
            variant="outlined"
            name="plural_name"
            value={form.plural_name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "plural name",
              form.plural_name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Event Type Description"
            multiline
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "description",
              form.description,
              "required"
            )}
          </Box>
        </Box>
        {/* <Box className="optionalField">
            <Box className="formControlHeading">
              <h3>Mandatory Fields</h3>
            </Box>
            <List>
              <ListItem className="formControl">Dates</ListItem>
              <ListItem className="formControl">Team Members</ListItem>
              <ListItem className="formControl" >Location</ListItem>
            </List>
          </Box> */}
        <Box className="formControl">
          <Box className="formControlHeading">
            <h3>Attach a Custom Form</h3>
          </Box>
          <TextField
            id="attach-custom-form"
            select
            label="Select Form"
            name="form_id"
            value={form.form_id}
            onChange={handleChange}
            variant="outlined"
          >
            {props.forms.map((form) => (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="radiobuttonWrap">
          <Box>
            <Box className="formControlHeading">
              <h3>Enable Event Checklist?</h3>
            </Box>
            <RadioGroup
              aria-label="checklist"
              name="has_checklist"
              value={form.has_checklist}
              onChange={handleChange}
              className="flexRow"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>
        {form.has_checklist === "yes" && (
          <Box className="optionalField" style={{ marginBottom: 10 }}>
            <Box className="formControlHeading">
              <h3>Event Checklist Items</h3>
            </Box>
            <Box className="chipMainWrap">
              {/* {checkList.map((item, i) => (
                <div className="tag-item" key={i}>
                  {item}
                  <button
                    type="button"
                    className="chipButton"
                    onClick={() => handleDelete((i !== 0 && i !== 1) ? item : null)}
                    style={i !== 0 && i !== 1 ? {cursor: 'pointer'}: {cursor: 'not-allowed'}}
                  >
                    &times;
                  </button>
                </div>
              ))} */}

              {checkList.map((item, i) => (
                <div className="tag-item" key={item}>
                  {item}
                  <button
                    type="button"
                    className="chipButton"
                    onClick={() => handleDelete(i)}
                  >
                    &times;
                  </button>
                </div>
              ))}

              <input
                className={"input "}
                name={`check_list_value`}
                value={form.check_list_value}
                placeholder="Type here to add checklist items and press `Enter`..."
                onKeyDown={handleKeyDown}
                onChange={handleChange}
              />
            </Box>
          </Box>
        )}
        <Box className="radiobuttonWrap">
          <Box>
            <Box className="formControlHeading">
              <h3>Enable Event Attendance?</h3>
            </Box>
            <RadioGroup
              aria-label="attendance"
              name="has_attendies"
              value={form.has_attendies}
              onChange={handleChange}
              className="flexRow"
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>
        {
          <>
            <Box className="formControl">
              <Box className="formControlHeading" style={{ margin: 0 }}>
                <h3>Pre Event Forms</h3>
              </Box>
              <MultipleSelectPreEventPostEvent
                list={props.forms}
                name="pre_event_form_ids"
                value={form.pre_event_form_ids}
                handleChange={handleChange}
                label="Pre Event Forms"
                className="customMultiSelect"
              />
            </Box>
            <Box className="formControl">
              <Box className="formControlHeading" style={{ margin: 0 }}>
                <h3>Post Event Forms</h3>
              </Box>
              <MultipleSelectPreEventPostEvent
                list={props.forms}
                name="post_event_form_ids"
                value={form.post_event_form_ids}
                handleChange={handleChange}
                label="Post Event Forms"
                className="customMultiSelect"
              />
            </Box>
          </>
        }
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) =>
              handleSubmit(
                event,
                props.item && props.item.hasOwnProperty("_id")
                  ? "update"
                  : "add"
              )
            }
          >
            {props.item && props.item.hasOwnProperty("_id")
              ? "Update & Update to Program"
              : "Save & Add to Program"}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default AddEditEventType;
