
let INTIAL_STATE={
  isLoading:false,
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){
    default:
      return {...state}
  }
}