import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Select,
  Button,
  Box,
  MenuItem,
  CircularProgress,
  TextField,
  InputAdornment,
  FormControl,
  Card,
  CardContent,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import Wrapper from "../../components/Global/Wraper";
import NotifierSnackbar, {
  openSnackbar,
} from "../../components/Global/NotifierSnackbar";
import "../../scss/_form.scss";
import {
  getAllPrograms,
  getModules,
  getDocs,
  getDocTypes,
  getDocById,
  exportDocs,
  exportSingleDoc,
  exportAllDocs,
} from "../../store/actions/exports";
import ExplorerTable from "../../components/PageCompnent/Dashboard/ExplorerTable";
import GetAppIcon from "@material-ui/icons/GetApp";

const Exports = (props) => {
  const [programs, setPrograms] = useState([]);
  const [docs, setDocs] = useState([]);
  const [singleQuery, setSingleQuery] = useState("");
  const [modules, setModules] = useState([]);
  const [type, setType] = useState([]);
  const [noDocs, setNoDocs] = useState("");
  const [loader, setLoader] = useState(true);
  const [totalDocs, setTotalDocs] = useState(0);
  const [reset, setReset] = useState(false);
  const [form, setForm] = useState({
    program: "",
    module: "",
    doc_type: "",
    programDetail: "",
  });

  const handleChange = (event) => {
    setNoDocs("");
    let name = event.target.name;
    let value = event.target.value;

    if (name === "program" && value) {
      getAllModules(value._id);
      setForm((prevState) => ({
        [name]: value._id,
        module: "",
        doc_type: "",
        programDetail: value,
      }));
      setDocs("");
      setReset(!reset);
      setSingleQuery("");
    }
    if (name === "module" && value) {
      getAllDocs(value);
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setReset(!reset);
      setSingleQuery("");
    }
    if (name === "doc_type" && value) {
      // getAllDocTypes(value)
      setSingleQuery("");
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setReset(!reset);
      setSingleQuery("");
    }
  };

  const getAllModules = (id) => {
    setLoader(true);
    props.getModules(id).then((res) => {
      let arr = [];
      for (let item in res.data.active_module) {
        arr.push(item);
      }
      setModules(arr);
      setLoader(false);
    });
  };

  const getAllDocs = (value) => {
    setLoader(true);
    let formClone = {
      program: form.program,
      module: value,
    };
    props.getDocs(formClone).then((res) => {
      if (res.data.beneficiary_types) {
        setType(res.data.beneficiary_types);
      }
      if (res.data.survey_types) {
        setType(res.data.survey_types);
      }
      if (res.data.location_types) {
        setType(res.data.location_types);
      }
      if (res.data.case_types) {
        setType(res.data.case_types);
      }
      if (res.data.event_types) {
        setType(res.data.event_types);
      }
      setLoader(false);
    });
  };
  const getAllDocTypes = (item) => {
    let perPage;
    if (typeof item == "number") {
      perPage = item;
    } else {
      perPage = 0;
    }
    setLoader(true);
    let formClone = {};
    if (form.doc_type) {
      formClone = {
        doc_name: form.doc_type,
        module_name: form.module,
        program_slug: form.programDetail.name_slug,
        pageNo: perPage,
      };
    } else {
      formClone = {
        module_name: form.module,
        program_slug: form.programDetail.name_slug,
        pageNo: perPage,
      };
    }
    props.getDocTypes(formClone).then((res) => {
      if (res.data.length === 0) {
        setDocs(res.data.data);
        setTotalDocs(res.data.total);
        setNoDocs("No document found");
      } else {
        setDocs(res.data.data);
        setTotalDocs(res.data.total);
      }
      setLoader(false);
    });
  };

  const handleInputChange = (event) => {
    setSingleQuery(event.target.value);
  };

  const getSingleData = () => {
    if (singleQuery === "") {
      return;
    }
    setLoader(true);
    props
      .getDocById(singleQuery)
      .then(({ data }) => {
        if (data) {
          setDocs([data]);
          setTotalDocs([data].length);
        } else {
          setDocs([]);
          setTotalDocs(0);
          setNoDocs("No document found");
        }
        // setSingleQuery('')
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const exports = () => {
    setLoader(true);
    if (singleQuery !== "" && docs.length === 1) {
      let formClone = {
        id: docs[0]._id,
        doc_type: docs[0].doc_type,
      };
      props.exportAllDocs(formClone).then((res) => {
        // window.open(`https://api.wildconnect.in/${res.data.path}`)
        // window.open(res.data.path)
        setLoader(false);
        openSnackbar({
          message:
            "We have received your request, you will receive an email shortly to download the report.",
          status: "success",
        });
      });
    } else {
      let formClone = {};
      if (form.doc_type) {
        formClone = {
          module_type_name: form.doc_type,
        };
      } else {
        formClone = {
          program_slug: form.programDetail.name_slug,
        };
      }
      switch (form.module) {
        case "events":
          formClone.doc_type = "EVENT";
          break;
        case "cases":
          formClone.doc_type = "CASE";
          break;
        case "surveys":
          formClone.doc_type = "SURVEY";
          break;
        case "beneficiaries":
          formClone.doc_type = "BENEFICIARY";
          break;
        case "locations":
          formClone.doc_type = "LOCATION";
          break;
        default:
          formClone.doc_type = "";
      }
      props.exportAllDocs(formClone).then((res) => {
        // window.open(`https://api.wildconnect.in/${res.data.path}`)
        // window.open(res.data.path)
        setLoader(false);
        openSnackbar({
          message:
            "We have received your request, you will receive an email shortly to download the report.",
          status: "success",
        });
      });
    }
  };
  const handleChangePage = async (page) => {
    getAllDocTypes(page);
  };
  useEffect(() => {
    props.getAllPrograms().then((res) => {
      setPrograms(res.data);
      setLoader(false);
    });
  }, []);

  return (
    <React.Fragment>
      <NotifierSnackbar />
      <Wrapper pageTitle={`Data Explorer`}>
        <h1>Explore data with wildconnect</h1>
        <div className="exportForm">
          <Grid container spacing={3} className="exportForm">
            <Grid item xs>
              <Box className="formControl" variant="outlined">
                <div id="standard-start-adornment" className="fieldLabel">
                  Select Program
                </div>
                <FormControl variant="outlined">
                  {/* <InputLabel id="demo-simple-select-outlined-label">Select Program</InputLabel> */}
                  <Select
                    id="demo-simple-select-outlined"
                    displayEmpty
                    value={form.programDetail}
                    onChange={handleChange}
                    name="program"
                  >
                    <MenuItem value="" disabled>
                      Select Program
                    </MenuItem>
                    {programs &&
                      programs.length > 0 &&
                      programs.map((item, i) => {
                        return (
                          <MenuItem value={item} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs>
              <Box className="formControl" variant="outlined">
                <div id="standard-start-adornment" className="fieldLabel">
                  Select Module
                </div>

                <FormControl variant="outlined">
                  {/* <InputLabel id="demo-simple-select-outlined-label">Select Module</InputLabel> */}
                  <Select
                    id="demo-simple-select-outlined"
                    name="module"
                    displayEmpty
                    value={form.module}
                    onChange={handleChange}
                    disabled={form.program === ""}
                  >
                    <MenuItem value="" disabled>
                      Select Module
                    </MenuItem>
                    {modules &&
                      modules.length > 0 &&
                      modules.map((item, i) => {
                        return (
                          <MenuItem value={item} key={i}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs>
              <Box className="formControl" variant="outlined">
                <div id="standard-start-adornment" className="fieldLabel">
                  Select Document Type
                </div>
                <FormControl variant="outlined">
                  {/* <InputLabel id="demo-simple-select-outlined-label">Select Document Type</InputLabel> */}

                  <Select
                    id="demo-simple-select-outlined"
                    name="doc_type"
                    displayEmpty
                    value={form.doc_type}
                    onChange={handleChange}
                    disabled={
                      (form.program === "" && form.module === "") ||
                      (form.program !== "" && form.module === "")
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Document Type
                    </MenuItem>
                    {type &&
                      type.length > 0 &&
                      type.map((item, i) => {
                        return (
                          <MenuItem value={item.name} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs container direction="row" alignItems="flex-end">
              <Button
                variant="contained"
                className="customBtn greebBtn exportBtn"
                size="large"
                onClick={getAllDocTypes}
              >
                Query Data
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="exportText">
          Use the filters above to query all documents in any program. You will
          be able to export all data to CSV format once the query is complete.
        </div>

        <Divider className="divider" />
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Box className="formControl" variant="outlined">
              <div id="standard-start-adornment" className="fieldLabel">
                Search for a specific document
              </div>
              <FormControl variant="outlined">
                {/* <InputLabel id="demo-simple-select-outlined-label">Search for a specific document</InputLabel> */}
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Document ID"
                  variant="outlined"
                  value={singleQuery}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={2} container direction="row" alignItems="flex-end">
            <Button
              variant="contained"
              className="customBtn greebBtn exportBtn"
              size="large"
              onClick={getSingleData}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <Divider className="divider" />

        {docs && docs.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <div style={{ fontSize: "16px" }}>
                Total number of Documents: {totalDocs}
              </div>
              <div
                onClick={exports}
                style={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <GetAppIcon fontSize="small" />
                Export Documents
              </div>
            </div>
            <Card variant="outlined">
              <CardContent>
                <Box className="tableContainer">
                  <ExplorerTable
                    form={form}
                    data={docs}
                    modulesDoc={docs}
                    loader={loader}
                    showFooter={true}
                    changePage={handleChangePage}
                    count={totalDocs}
                    resetPage={reset}
                  />
                </Box>
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            {loader ? (
              <Box
                style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}
              >
                <CircularProgress disableShrink />
              </Box>
            ) : (
              <div style={{ textAlign: "center" }}>{noDocs}</div>
            )}
          </>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default connect(null, {
  getAllPrograms,
  getModules,
  getDocs,
  getDocTypes,
  getDocById,
  exportDocs,
  exportSingleDoc,
  exportAllDocs,
})(Exports);
