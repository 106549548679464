import React, { useState } from 'react'
import {
  Button, 
  TextField, 
  Typography, 
  Box,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import AuthWraper from '../Global/AuthWraper'


const ResetPassword = () => {
  const [form, setForm] = useState({
    password: '',
    repassword: ''
  })
  const [open, setOpen] = useState(false);
  // const user = {
  //   id: 1,
  //   password: '123456',
  //   repassword: '12345'
  // }

  const handleChange = (event) => {
    setForm({...form,  [event.target.id]: event.target.value})
  }

  const handleSubmit = event => {
    event.preventDefault();
    // if(repassword !== user.repassword || password !== user.password){
    //   setOpen(true)
    // } else {
    // }
  }

  return (
    <AuthWraper onSubmit={handleSubmit}>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Password not match
        </Alert>
      </Collapse>
      <Typography component="h3" variant="h5">
        Change Password
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="repassword"
        label="Repassword"
        type="password"
        id="repassword"
        autoComplete="current-repassword"
        onChange={handleChange}
      />
      <Box className="buttonWrap">
        <Button
          type="submit"
          variant="contained"
          className='button'
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Link to="/login">
          Back to login
        </Link>
      </Box>
    </AuthWraper>
  );
}

export default ResetPassword
