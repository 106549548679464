import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton, Button,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { getAllCaseType, createCaseType, editCaseType } from './../../../store/actions/case'
import { editProgram, deactivateModule } from '../../../store/actions/program'
import { getAllForm } from '../../../store/actions/form'
import { connect } from 'react-redux'
import BeneficiariesTable from './BeneficiariesTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import _ from 'lodash'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import ModulePermissionDialog from './ModulePermissionDialog'


import '../../../scss/_beneficiaries.scss'

const Cases = props => {
  const [searchData, setSearchData] = useState([])
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [loader, setLoader] = useState(true)
  const [forms, setForms] = useState([])
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [casesType, setCasesType] = useState([])
  const [program, setProgram] = useState({})
  const [addedCaseTypes, setAddedCaseTypes] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [openModulePermission, setOpenModulePermission] = useState(false)

  let { active_module } = props.program ? props.program : null

  const getAddedCaseTypes = (data) => {
    let tempAddCaseType = data.filter(case_type => {
      if (props.program.case_types.indexOf(case_type._id) > -1) {
        return true
      }
      return false
    })
    setSearchData([...tempAddCaseType])
    setAddedCaseTypes(tempAddCaseType)
  }
  const getCaseNotAddedToProgram = (data) => {
    let tempEventType = data.filter(case_type => {
      if (props.program.case_types.indexOf(case_type._id) > -1) {
        return false
      }
      return true
    })
    setCasesType([...tempEventType])
  }
  const getLocationTypesTypes = () => {
    props.getAllCaseType().then(({ data }) => {
      getAddedCaseTypes(data)
      getCaseNotAddedToProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }

  const addCaseType = (casetype) => {
    props.createCaseType(casetype).then(({ data }) => {
      addItemTypeToProgram(data)
      let tempCaseTypes = _.cloneDeep(casesType)
      tempCaseTypes = [...tempCaseTypes, data]
      setCasesType(tempCaseTypes)
      handleDrawer(false)
      openSnackbar({ message: 'Added Cases Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Added Cases Type', status: 'error' });
    })
  }

  const updateCaseType = (casetype) => {
    props.editCaseType(casetype).then(({ data }) => {
      let index = addedCaseTypes.findIndex(case_type => case_type._id === data._id)
      addedCaseTypes.splice(index, 1, data)
      setAddedCaseTypes([...addedCaseTypes])
      setSearchData([...addedCaseTypes])
      handleEditDrawer(false)
      openSnackbar({ message: 'Edited Cases Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Edit Cases Type', status: 'error' });
    })
  }
  const addItemTypeToProgram = (casetype) => {
    let tempProgram = { _id: program._id, case_types: [...program.case_types, casetype._id] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = casesType.findIndex(type => type._id === casetype._id)
      let tempCasesType = _.cloneDeep(casesType)
      tempCasesType.splice(index, 1)
      setCasesType(tempCasesType)
      setAddedCaseTypes([...addedCaseTypes, casetype])
      setSearchData([...addedCaseTypes, casetype])
      handleDrawer(false)
      openSnackbar({ message: 'Added Cases Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      openSnackbar({ message: 'Error in Edit Cases Type', status: 'error' });
    })
  }
  const removeItemTypeFromProgram = (case_type) => {
    let temp_case_types = program.case_types.filter(_id => _id !== case_type._id)
    let tempProgram = { _id: program._id, case_types: [...temp_case_types] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = addedCaseTypes.findIndex(type => type._id === case_type._id)
      let tempAddedCaseTypes = _.cloneDeep(addedCaseTypes)
      tempAddedCaseTypes.splice(index, 1)
      setAddedCaseTypes(tempAddedCaseTypes)
      setSearchData([...tempAddedCaseTypes])
      setCasesType([...casesType, case_type])
      handleDrawer(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Cases Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      openSnackbar({ message: 'Error in Removing Cases Type', status: 'error' });
      setDeleteModal(false)
    })
  }
  const getAllForms = () => {
    props.getAllForm(props.program._id).then(({ data }) => {
      setForms(data.filter(program => program.has_published === true))
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }

  const deactivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, cases: false } }
    props.editProgram(tempProgram).then(({ data }) => {
      openSnackbar({ message: 'Cases module deactivated', status: 'success' });
      props.setProgram(data)
      props.handleLink(3)
    }).catch(err => {
      console.log('err', err)
    })
  }
  useEffect(() => {
    setProgram(props.program)
    getAllForms()
    getLocationTypesTypes()
    if (props.program && props.program.active_module && props.program.active_module.cases === false) {
      setOpenModulePermission(true)
    }
  }, []);

  const filterData = event => {
    const updateList = addedCaseTypes.filter(item => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setSearchData(updateList)
  }

  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  const handlePermissionModal = (value) => {
    setOpenModulePermission(value)
  }
  const programModuleActivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, cases: true } }
    props.editProgram(tempProgram).then(({ data }) => {
      handlePermissionModal(false)
      props.setProgram(data)
    }).catch(err => {
      console.log('err', err)
    })
  }
  return (
    <React.Fragment>
      <NotifierSnack />
      <ModulePermissionDialog
        openModulePermission={openModulePermission}
        handlePermissionModal={handlePermissionModal}
        programModuleActivate={programModuleActivate}
        handleLink={props.handleLink}
      />
      <Box className="tabbackLinkMain">
        <Box className="link" onClick={() => props.handleLink(3)}>
          <ChevronLeftIcon />
          <span>Back to all modules</span>
        </Box>
      </Box>
      <Box className="Beneficiaries">
        <Box className="headingWrap leftTextRightSearchAndBtn">
          <span className="heading">
            <DescriptionOutlinedIcon />
            <h3>Cases</h3>
            <span>{active_module && active_module.cases ? "Active" : "Inactive"}</span>

          </span>
          <Box className="rightContent">
            <Button variant="contained" color="primary" onClick={deactivate}>
              {active_module && active_module.cases ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
          </Box>
        </Box>
        <Box className="leftTextRightSearchAndBtn">
          <span className="leftTxt">Active Case Types</span>
          <Box className="rightContent">
            <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
              <input
                type="text"
                name="searchData"
                placeholder="Search"
                className="serachInput"
                onChange={filterData}
              />
              <IconButton className="iconButton" type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className="buttonWrap">
              <BeneficiariesDrawerTab
                buttonLabel="ADD CASE TYPE"
                name="Add CASE TYPE TO PROGRAM"
                title='Case'
                existing="Select from existing"
                add_title="Create new case type"
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                forms={forms}
                handleSubmitData={addCaseType}
                addItemTypeToProgram={addItemTypeToProgram}
                data={casesType}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <BeneficiariesTable
          isEditDrawer={isEditDrawer}
          handleEditDrawer={handleEditDrawer}
          forms={forms}
          handleSubmitData={updateCaseType}
          data={searchData}
          loader={loader}
          removeItemTypeFromProgram={removeItemTypeFromProgram}
          name="Case"
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
        />
      </Box>
    </React.Fragment>
  )
}

export default connect(null, {
  getAllCaseType,
  editProgram,
  getAllForm,
  createCaseType,
  editCaseType, deactivateModule
})(Cases)


