
const  {
  Heading,
  Text,
  Address,
  Barcode,
  TrueFalse,
  CheckBox,
  DateField,
  DropDown,
  Email,
  FieldGroup,
  File,
  Image,
  MultipleChoice,
  NumberField,
  Password,
  Phone,
  Radio,
  TextArea,
  URL,
  UserSelection,
  Separator,
  CurrentUser,
  Locations,
  GeoLocation,
  Beneficiaries,
  Gallery,
  Files,
  DateTime,
  BracketReplace,
  Cases,
  GeoTrace,
  Signature
} = require('./fields/_index')

export default class FormStructureHelper{
  constructor(){
    this.field = {}
  }
  loadForm(structure){
    this.setFormData(structure)
    return this.field
  }

  setFormData(structure){
    switch(structure.field_type){
      case "heading":
        let heading = new Heading()
        heading.loadData(structure)
        this.field = heading.getData()
        break

      case "text":
        let text = new Text()
        text.loadData(structure)
        this.field = text.getData()
        break

      case "address":
        let address = new Address()
        address.loadData(structure)
        this.field = address.getData()
        break;

      case "barcode":
        let barcode = new Barcode()
        barcode.loadData(structure)
        this.field = barcode.getData()
        break;

      case "boolean":
        let boolean = new TrueFalse()
        boolean.loadData(structure)
        this.field = boolean.getData()
        break;

      case "checkbox":
        let checkbox = new CheckBox()
        checkbox.loadData(structure)
        this.field = checkbox.getData()
        break;

      case "date":
        let date = new DateField()
        date.loadData(structure)
        this.field = date.getData()
        break;

      case "dropdown":
        let dropdown = new DropDown()
        dropdown.loadData(structure)
        this.field = dropdown.getData()
        break;

      case "email":
        let email = new Email()
        email.loadData(structure)
        this.field = email.getData()
        break;

      case "field-group":
        let fieldgroup = new FieldGroup()
        fieldgroup.loadData(structure)
        this.field = fieldgroup.getData()
        break;

      case "file":
        let file = new File()
        file.loadData(structure)
        this.field = file.getData()
        break;

      case "image":
        let image = new Image()
        image.loadData(structure)
        this.field = image.getData()
        break;
        
      case "multiple-select":
        let multiplechoice = new MultipleChoice()
        multiplechoice.loadData(structure)
        this.field = multiplechoice.getData()
        break;

      case "number":
        let number = new NumberField()
        number.loadData(structure)
        this.field = number.getData()
        break;

      case "password":
        let password = new Password()
        password.loadData(structure)
        this.field = password.getData()
        break;

      case "phone":
        let phone = new Phone()
        phone.loadData(structure)
        this.field = phone.getData()
        break;

      case "radio":
        let radio = new Radio()
        radio.loadData(structure)
        this.field = radio.getData()
        break;

      case "text-area":
        let textarea = new TextArea()
        textarea.loadData(structure)
        this.field = textarea.getData()
        break;

      case "url":
        let url = new URL()
        url.loadData(structure)
        this.field = url.getData()
        break;

      case "user-selection":
        let userselection = new UserSelection()
        userselection.loadData(structure)
        this.field = userselection.getData()
        break;

      case "separator":
        let separator = new Separator()
        separator.loadData(structure)
        this.field = separator.getData()
        break;
      case "current-user":
        let currentuser = new CurrentUser()
        currentuser.loadData(structure)
        this.field = currentuser.getData()
        break;
      case "geo-location":
        let geolocation = new GeoLocation()
        geolocation.loadData(structure)
        this.field = geolocation.getData()
        break;
      case "locations":
        let locations = new Locations()
        locations.loadData(structure)
        this.field = locations.getData()
        break;

      case "beneficiaries":
        let beneficiaries = new Beneficiaries()
        beneficiaries.loadData(structure)
        this.field = beneficiaries.getData()
        break;
      
      case "files":
        let files = new Files()
        files.loadData(structure)
        this.field = files.getData()
        break;
      
      case "gallery":
        let gallery = new Gallery()
        gallery.loadData(structure)
        this.field = gallery.getData()
        break;

      case "date-time":
        let date_time = new DateTime()
        date_time.loadData(structure)
        this.field = date_time.getData()
        break;
        
      case "bracket-replace":
        let bracketReplace = new BracketReplace()
        bracketReplace.loadData(structure)
        this.field = bracketReplace.getData()
        break;

      case "cases":
        let cases = new Cases()
        cases.loadData(structure)
        this.field = cases.getData()
        break;
        
      case "geo-trace":
        let geoTrace = new GeoTrace()
        geoTrace.loadData(structure)
        this.field = geoTrace.getData()
        break;

      case "signature":
        let signature = new Signature()
        signature.loadData(structure)
        this.field = signature.getData()
        break;

      default:
        return 

    }
  }
}

