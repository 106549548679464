import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {
  Box,
  Tooltip,
  Fab,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import AppsIcon from '@material-ui/icons/Apps';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Wrapper from '../../components/Global/Wraper'
import CustomFormTable from '../../components/PageCompnent/CustomForm/CustomFormTable'
import '../../scss/_table.scss'
import {connect} from 'react-redux'
import {getAllForm} from '../../store/actions/form'
import _ from 'lodash'
import ButtonForMultiSelect from '../../components/common/ButtonForMultiSelect'


class CustomeForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      loader: false,
      customForm: [],
      forms:[],
      columns: [],
      activeForms: [],
      searchForms:[],
      isActiveForms: false,
      columnsNames : {
        form_name: "Form Name",
        program: "Program",
        current_version: "Current Version",
        last_updated: "Last Updated",
        status: "Status",
      }
    }
    this.deleteFormTheList = this.deleteFormTheList.bind(this)
    this.deactivateForms = this.deactivateForms.bind(this)
  }

  deactivateForms(item){
    console.log('item',item)
    item.status = !item.status
    let tempForms = [...this.state.forms]
    let index = this.state.forms.findIndex(form => form._id == item._id)
    tempForms.splice(index, 1, item)
    this.setState(prevState => ({
      ...prevState,
      forms:tempForms
    }))
  }

  deleteFormTheList(item){
    let tempList = this.state.forms.filter(form => form._id !== item._id)
    this.setState(prevState => ({
      ...prevState,
      forms:tempList
    }))
  }

  allForms(){
    this.props.getAllForm().then(({data})=> {
      console.log('data',data)
      this.setState(prevState => ({
        ...prevState,
        forms: data,
        searchForms:data,
        loader: false,
      }))
    })
  }
  componentDidMount(){
    this.setState(prevState => ({
      ...prevState,
      customForm: this.state.customForm,
      loader: true
    }))
    this.allForms()
  }

  handleColumnsName = value => {
    this.setState(prevState => ({
      ...prevState,
      columns: value
    }))
  }

  handleIsActive = value => {
    // console.log('is active:', value)
    this.setState(prevState => ({
      ...prevState,
      isActiveForms: !value
    }))
  }

  handleActiveUsers = (isActive) => {
    this.handleIsActive(isActive)
    if(!isActive){
      const onlyActive = this.state.forms.filter(x => x.status === true)
      this.setState(prevState => ({
        ...prevState,
        activeForms: onlyActive
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        activeForms: []
      }))
    }
  }

  render(){
    return (
      <Wrapper pageTitle="Custom Form">
        <Box className="tableTopContentMain">
          <Box className="pageTableTitle">
            <h2>All Forms</h2>
          </Box>
          <Box className="tableTopActionIcons">
            <span className="iconWithName">
              <ButtonForMultiSelect 
                icon={<AppsIcon fontSize="small" />}
                label="Columns"
                width={90}
                names={this.state.columnsNames}
                handleColumnsName={this.handleColumnsName}
              />
            </span>
            <span className="iconWithName" onClick={()=>this.handleActiveUsers(this.state.isActiveForms)}>
              {
                this.state.activeForms.length <= 0 ?
                <>
                  <VisibilityOffOutlinedIcon fontSize="small" />
                  <span className="text">Hide Inactive Forms</span>
                </>
                :
                <>
                  <VisibilityOutlinedIcon fontSize="small" />
                  <span className="text">Show Inactive Forms</span>
                </>
              }
              
            </span>
          </Box>
        </Box>
        <Box className='tableBox marginB80'>
          <CustomFormTable 
            deactivateForms={this.deactivateForms}
            deleteFormTheList={this.deleteFormTheList}
            data={!this.state.isActiveForms?this.state.forms:this.state.activeForms}
            loader={this.state.loader}
            columns={this.state.columns}
          />
        </Box>
        <Tooltip title='Add New Form' aria-label='add-new' arrow style={{position: 'absolute', bottom: 25, right: 25}}>
          <Link to='/custom-form/add-new'>
            <Fab className="circleButton greebBtn">
              <AddIcon />
            </Fab>
          </Link>
        </Tooltip>
      </Wrapper>
    )
  }
}

export default connect(null, {getAllForm})(CustomeForm)
