import React from 'react'
import {
  Box,
  Button,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const CondLogicField = (props) => {

  return (
    <React.Fragment>
      <Box className="conditionWrapper" key={props.parentIndex}>
        <Box className="conditionInner">
          <label>Show this field if</label>
          <select
            name="field_name"
            value={props.logic.field_name}
            onChange={event => props.onChangeInput(event, props.parentIndex, props.index)}
          >
            <option value="">Select Input</option>
            {
              props.fields.map((field, index) => {
                // console.log('field_label..', field.field_label)
                if(props.parentIndex === index){
                  return (<option value={field.field_name} disabled>{field.field_label}</option>)
                }
                return (<option value={field.field_name}>{field.field_label}</option>)
              })
            }
            
          </select>
        </Box>
        <Box className="conditionInner">
          <select 
            name="show" 
            value={props.logic.show} 
            onChange={event => props.onChangeInput(event, props.parentIndex, props.index)}
          >
            {
              props.logic.field_name == 'boolean' ?
              <>
                <option value="is-equal-to">Value is equal to</option>
                <option value="not-equal-to">Value is not equal to</option> 
              </>
              :
              <>
                <option value="any-value">Has any value</option>
                <option value="no-value">Has no value</option>
                <option value="is-equal-to">Value is equal to</option>
                <option value="not-equal-to">Value is not equal to</option>
                <option value="matches-pattern">Value matches pattern</option>
                <option value="contains">Value contains</option>
              </>
              
            }
            
          </select>
        </Box>
        <Box className="conditionInner">
          {
            props.logic.field_name === 'boolean' ?
            <select 
              name="check" 
              value='Checked'
              onChange={event => props.onChangeInput(event, props.parentIndex, props.index)}
            >
              <option value="cheched">Checked</option>
          </select>
            :
            props.logic.show==="is-equal-to" || props.logic.show==="not-equal-to" || props.logic.show==="matches-pattern" || props.logic.show==="contains"? 
            <input 
              type="text"
              value={props.logic && props.logic.value} 
              name='value' 
              onChange={event => props.onChangeInput(event, props.parentIndex, props.index)}
            /> : ""
          }
          
        </Box>
        <Box className="conditionInner">
          {
            props.index >= 1 ? 
            <Box onClick={() => props.onRemove(props.parentIndex, props.index)} className='removeBtn'>
              <RemoveCircleOutlineIcon />
            </Box>
            : null
          }
          <Button variant="outlined" className="borderBtn greebBtn" size='small' onClick={props.onAdd}>
              and
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default CondLogicField
