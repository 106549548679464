import React from "react";
import {
  Drawer,
  Box,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import '../../scss/_rightDrawer.scss'

const RightDrawer = props => {

  return (
    <React.Fragment>
      {
        props.buttonLabel &&
        <button onClick={() => props.handleDrawer(true)} className="rightDrawerBtn">{props.buttonLabel ? props.buttonLabel : 'Button'}</button>
      }
      
      <Drawer
        anchor={"right"}
        open={props.isDrawerOpen}
        onClose={() => props.handleDrawer(false)}
        className={props.className && props.className}
      >
        <Box
          className={`rightDrawerContainer ${props.drawerClass && props.drawerClass}`}
          role="presentation"
        >
          <span 
            className="close"
            onClick={() => props.handleDrawer(false)}
          >
            <CloseIcon />
          </span>
          {props.children}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default RightDrawer;

