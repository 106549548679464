import React, {useState, useEffect} from 'react'
import {
  Box,
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import TeamsTable from './TeamsTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import {editProgram, getAllProgram, getSingleProgramModule} from '../../../store/actions/program'
import {getAllUser} from '../../../store/actions/user'
import {connect} from 'react-redux'
import '../../../scss/_teams.scss'
import _ from 'lodash'
import NotifierSnack, {openSnackbar} from '../../Global/NotifierSnackbar'

const Teams = (props) => {
  const [loader, setLoader] = useState(true)
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [program, setProgram] = useState({})
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [team, setTeam] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

const addMemberToTeam = (user) =>{
  let tempProgram = {}
  if(user.is_program_head){
    tempProgram = {_id:program._id, program_head: [...program.program_head, user.form]}
  }else{
    tempProgram = {_id:program._id, teams: [...program.teams, user.form]}
  }
  updateProgram(tempProgram, 'addMember')
}

const deleteMemberFromTeam = (user) => {
  // console.log("tempProgram", user)
  let tempProgram = {}
  if(user.is_program_head){
    tempProgram = {_id:program._id, program_head:program.program_head.filter(head=> head!=user._id)}
  }else{
    tempProgram = {_id:program._id, teams: program.teams.filter(team=> team!=user._id)}
  }
  // console.log("tempProgram", tempProgram)
  updateProgram(tempProgram, 'deleteMember')
  setDeleteModal(false)
}

const changeStatusProgramHead = (user) => {
  let tempTeam = [...team]
  user.is_program_head = !user.is_program_head
  let index = tempTeam.findIndex(singleUser => singleUser._id == user._id)
  tempTeam.splice(index, 1, user)
  setTeam(tempTeam)
  let program_head = []
  let teams = []
  tempTeam.forEach(teamUser => {
    if(teamUser.is_program_head){
      program_head = [...program_head, teamUser._id]
    }else{
      teams = [...teams, teamUser._id]
    }
  })
  updateProgram({_id:program._id, program_head, teams}, 'update_program_head')
}

const updateProgram=(tempProgram, action)=>{
  props.editProgram(tempProgram).then(({data}) => {
    setProgram(data)
    loadTeams(allUsers, data)
    handleDrawer(false)
    if(action === 'addMember'){
      openSnackbar({ message: 'Added New Team Member Successfully', status: 'success' });
    } else if(action === 'deleteMember'){
      openSnackbar({ message: 'Deleted Team Member Successfully', status: 'success' });
      setDeleteModal(false)
    }else{
      openSnackbar({ message: 'Change program head status successfully', status: 'success' });
    }
  })
  .catch(err=>{
    console.log("err", err, err.response)
    if(action === 'addMember'){
      openSnackbar({ message: 'Error in Adding New Team Member', status: 'error' });
    }
    else if(action === 'deleteMember'){
      openSnackbar({ message: 'Error in Delete Team Member', status: 'error' });
    }
  })
}


const loadTeams = (all_users, tempProgram) => {
  let rest_teams = all_users.filter(user => {
    if(tempProgram.teams.indexOf(user._id)>-1){
      return true
    }
    return false
  }).map(user => {user.is_program_head=false; return user})
  let program_head = all_users.filter(user => {
    if(tempProgram.program_head.indexOf(user._id)>-1){
      return true
    }
    return false
  }).map(user => {user.is_program_head=true; return user})

  let tempTeam_ids = [...tempProgram.teams, ...tempProgram.program_head]
  let tempTeam = [...program_head, ...rest_teams]
  setTeam(tempTeam)
  setLoader(false)
  let rest_users = all_users.filter(user => {
    if(tempTeam_ids.indexOf(user._id)>-1){
      return false
    }
    return true
  })
  setUsers(rest_users)
}

const getAllUsers = (tempProgram) =>{
  props.getAllUser().then(({data}) => {
    setAllUsers(data)
    loadTeams(data, tempProgram)
  })
}
const getSingleProgram = () => {
  props.getSingleProgramModule(props.program._id).then(({data}) => {
    setProgram(data)
    getAllUsers(data)

  })
}
useEffect(() => {
  getSingleProgram()
}, []);


const handleDrawer = (isOpen) => {
  setIsdrawerOpen(isOpen)
}


const handleDeleteModal = (value) => {
  setDeleteModal(value)
}
  return (
    <React.Fragment>
      <NotifierSnack />
      <Box className="teamTabMain">
        <Box className="teamWrap">
          <Box className="headingWithLeftIcon">
            <AccountCircleOutlinedIcon />
            <span className="heading">Team</span>
          </Box>
          <Box className="buttonWrap">
            <BeneficiariesDrawerTab 
              buttonLabel="ADD TEAM MEMBER"
              name="Team"
              items={users}
              title='Team'
              noTab={true}
              existing="Select from existing"
              add_title="Create new team"
              isDrawerOpen={isDrawerOpen}
              handleDrawer={handleDrawer}
              handleSubmitData={addMemberToTeam}
            />
          </Box>
        </Box>
        <Box className="teamCardTable">
          <TeamsTable 
            data={team}
            handleSubmitData={deleteMemberFromTeam} 
            loader={loader}
            changeStatusProgramHead = {changeStatusProgramHead}
            deleteModal={deleteModal}
            handleDeleteModal={handleDeleteModal}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
}


export default connect(null, 
  {
    getAllUser,
    getSingleProgramModule,
    editProgram,
    getAllProgram,
  })(Teams)