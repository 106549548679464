import React, {useEffect, useState} from 'react'
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  CircularProgress,
  Box,
  Tooltip,
 } from '@material-ui/core'
 import { Link} from 'react-router-dom'
 import CreateIcon from '@material-ui/icons/Create';
 import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
 import TablePaginationActions from '../../common/TablePaginationActions'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import DeleteProgramTabItemModal from '../../common/DeleteProgramTabItemModal'

 import '../../../scss/_table.scss'

const BeneficiariesTable = props => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [beneficiary, setBeneficiary] = useState(null)
  const [tableItem, setTableItem] = useState()

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data && props.data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleEditData = (item) =>{
    // console.log("item",item)
    setBeneficiary(item)
    props.handleEditDrawer(true)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const returnRenderDrawerType = (name) =>{
    // console.log("this is props",props)
    switch(name){
      case "Location":
        return <BeneficiariesDrawerTab 
                  name="Edit Location TYPE TO PROGRAM"
                  title='Location'
                  add_title="Edit location type"
                  isDrawerOpen={props.isEditDrawer}
                  handleDrawer={props.handleEditDrawer}
                  forms = {props.forms}
                  handleSubmitData={props.handleSubmitData}
                  item={beneficiary}
                />;

        
      case "Beneficiary":
        return <BeneficiariesDrawerTab 
                  name="Edit Beneficiary TYPE TO PROGRAM"
                  title='Beneficiary'
                  add_title="Edit beneficiary type"
                  isDrawerOpen={props.isEditDrawer}
                  handleDrawer={props.handleEditDrawer}
                  forms = {props.forms}
                  handleSubmitData={props.handleSubmitData}
                  item={beneficiary}
                />;
      case "Event":
        return <BeneficiariesDrawerTab 
                  name="Edit EVENT TYPE TO PROGRAM"
                  title='Event'
                  add_title="Edit event type"
                  isDrawerOpen={props.isEditDrawer}
                  handleDrawer={props.handleEditDrawer}
                  forms = {props.forms}
                  handleSubmitData={props.handleSubmitData}
                  item={beneficiary}
                />;

      case "Case":
        return <BeneficiariesDrawerTab 
                  name="Edit CASE TYPE TO PROGRAM"
                  title='Case'
                  add_title="Edit case type"
                  isDrawerOpen={props.isEditDrawer}
                  handleDrawer={props.handleEditDrawer}
                  forms = {props.forms}
                  handleSubmitData={props.handleSubmitData}
                  item={beneficiary}
                />;

      case "Survey":
        return <BeneficiariesDrawerTab 
                  name="Edit SURVEY TYPE TO PROGRAM"
                  title='Survey'
                  add_title="Edit survey type"
                  isDrawerOpen={props.isEditDrawer}
                  handleDrawer={props.handleEditDrawer}
                  forms = {props.forms}
                  handleSubmitData={props.handleSubmitData}
                  item={beneficiary}
                />;
      case "Permission":
        return <BeneficiariesDrawerTab 
                  name="Edit Permission File TO PROGRAM"
                  title='Permission'
                  add_title="Edit Permission File"
                  isDrawerOpen={props.isEditDrawer}
                  handleDrawer={props.handleEditDrawer}
                  forms = {props.forms}
                  handleSubmitData={props.handleSubmitData}
                  item={beneficiary}
                />;
      default: 
        return ""
      
    }
  }
  useEffect(() => {
  }, [props.data])

  

  const hadleSuspendData = (item) => {
    setTableItem(item)
    props.handleDeleteModal(true)
  }

  // console.log('props.data', props.data)

  return (
    <React.Fragment>
      <TableContainer>
        <Table size="medium" aria-label="table" className="BeneficiariesTable">
          {
            props.loader ?
            <TableBody>
              <TableRow>
                <TableCell align="center" width="100%" colSpan={12}>
                <Box>
                  <CircularProgress disableShrink />
                </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            :
            <TableBody>
              {
                props.data &&
                props.data.length >0 ?
                (rowsPerPage > 0 && props.data
                  ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : []
                ).map((item, key)=> (
                                  <TableRow key={key}>
                                    <TableCell>
                                      <span className="name">
                                        {item.name}
                                      </span>
                                      {
                                        item.description?
                                        (<span className="description">
                                      {
                                        item.description.length > 46 ?
                                        `${item.description.substring(0, 46)}...` : item.description
                                      }
                                        {/* {item.description.substring(0, 250)} */}
                                      </span>):""}
                                    </TableCell>
                                    <TableCell 
                                      align="right" 
                                      className="iconCell"
                                    >
                                      <Tooltip 
                                        title={`Edit ${props.name ? props.name : null}`} 
                                        aria-label='edit-beneficiary' 
                                        arrow
                                        className="actionIcon"
                                        onClick={() => handleEditData(item)}
                                      >
                                        <Link to={`#`} >
                                            <CreateIcon />
                                        </Link>
                                      </Tooltip>
                                      <Tooltip 
                                        title={`Delete ${props.name ? props.name : null}`} 
                                        aria-label='delete-beneficiary' 
                                        arrow
                                        className="actionIcon"
                                        // onClick={()=>props.removeItemTypeFromProgram(item)}
                                        onClick={()=>hadleSuspendData(item)}
                                      >
                                        <span style={{cursor: 'pointer'}}>
                                            <DeleteTwoToneIcon />
                                        </span>
                                      </Tooltip> 
                                    </TableCell>
                                  </TableRow>
                                ))
                :
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box style={{textAlign: 'center', fontSize: 15, color: '#c5c5c5'}}>
                      {
                        props &&
                        props.emptyMessage ? props.emptyMessage
                        :
                        (
                          `No ${props.name ? props.name : 'data'} found`
                        )
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{borderBottom: 0}}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={8}
                count={props.data && props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': `${props.name} per page` },
                  native: true,
                }}
                labelRowsPerPage={`${props.name} per page`}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box className="displayNone">
        {returnRenderDrawerType(props.name)}
      </Box>
      {
        tableItem &&
        <DeleteProgramTabItemModal 
          title={props.name}
          action={()=>props.removeItemTypeFromProgram(tableItem)}
          name={tableItem.name && tableItem.name}
          item={tableItem.item && tableItem.item}
          _id={tableItem._id && tableItem._id}
          handleModal={props.handleDeleteModal}
          status={tableItem.status && tableItem.status}
          open={props.deleteModal}
        />
      }
      
    </React.Fragment>
  )
}

export default withRouter(BeneficiariesTable)
