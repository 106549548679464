import React from 'react'
import FieldGroupComponent from './FieldGroupComponent'


let groupFieldData =[
            [
              {
                field_name:"first_name_0",
                value:"subham"
              },
              {
                field_name:"last_name_0",
                value:"tiwari"
              },
              {
                field_name:"email_0",
                value:"subham@gmail.com"
              },
            ],
            [
              {
                field_name:"first_name_1",
                value:"sani"
              },
              {
                field_name:"last_name_1",
                value:"tiwari"
              },
              {
                field_name:"email_1",
                value:"sani@gmail.com"
              },
            ],
            [
              {
                field_name:"first_name_2",
                value:"digember"
              },
              {
                field_name:"last_name_2",
                value:"dangwal"
              },
              {
                field_name:"email_2",
                value:"digember@gmail.com"
              },
            ]
          ]

export default function FormBuilder({ item, onChangeHandler }) {
  return (
    <div>
      {item.field_type === 'field-group' && (
        <FieldGroupComponent
          label={item.field_label}
          fields={item.extra_details}
          onChangeValue={value => onChangeHandler(value, item.field_name)}
          values={groupFieldData}
        />
      )}
      {/* other field type  */}
    </div>
  )
}
