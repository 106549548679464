import React, { useState, useRef } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  // List,
  // ListItem,
  // Checkbox,
  // FormControlLabel,
} from "@material-ui/core";
import Form from "../../Form/form";
import _ from "lodash";
import SimpleReactValidator from "simple-react-validator";

//  const non_mandatory_fields = [
//   {
//     name:"address",
//     label:"Address Field"
//   },
//   {
//     name:"abbreviated_address",
//     label:"Abbreviated Address Field"
//   },
// ]

const AddEditLocationType = (props) => {
  const [, forceUpdate] = useState();
  const [form, setForm] = useState({
    name: "",
    plural_name: "",
    description: "",
    form: "",
    form_id: "",
    non_mandatory_fields: {},
  });

  const [optional, setOptional] = useState({
    address: false,
    abbreviated_address: false,
  });

  const simpleValidator = useRef(new SimpleReactValidator());

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const handleChangeOption = event => {
  //   let name = event.target.name
  //   let checked = event.target.checked
  //   setOptional({...optional, [name]:checked})
  // }
  const handleSubmit = (event, method) => {
    event.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true);
      forceUpdate(true);
      return;
    }
    let data = _.cloneDeep(form);
    data.form = data.form_id;
    data.non_mandatory_fields = optional;
    data.non_mandatory_check_fields = optional;
    props.handleSubmitData(data);
  };

  const loadDataProperly = (item) => {
    let tempLocation = _.omit(
      item,
      "mandatory_fields",
      "non_mandatory_fields",
      "structures",
      "created_by"
    );
    setOptional({ ...optional, ...tempLocation.non_mandatory_check_fields });
    return tempLocation;
  };
  useState(() => {
    if (props.item) {
      let item = loadDataProperly(props.item);
      setForm(item);
    }
  }, []);

  return (
    <Box className="createBeneficiaryType">
      <Form
        onSubmit={handleSubmit}
        formHeading="Create a new Location Type"
        formSubheading="Use the form below to add a new location type and add it to the program"
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
          <TextField
            type="text"
            label="Location Type Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "location type name",
              form.name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Plural Name"
            variant="outlined"
            name="plural_name"
            value={form.plural_name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "plural name",
              form.plural_name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Location Type Description"
            multiline
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "Location Type Description",
              form.description,
              "required"
            )}
          </Box>
        </Box>
        {/* <Box className="optionalField">
          <Box>
          <Box className="formControlHeading">
            <h3>Mandatory Fields:</h3>
          </Box>
          <List>
            <ListItem className="formControl">Location Name</ListItem>
            <ListItem className="formControl">Geolocation Coordinates</ListItem>
          </List>
          </Box>
          <Box className="formControlHeading">
            <h3>Non Mandatory Fields:</h3>
          </Box>
          {
            non_mandatory_fields.map((non_mandatory_field, index) => (
              <Box className="formControl" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={optional[non_mandatory_field.name] } 
                      onChange={handleChangeOption} 
                      name={non_mandatory_field.name} 
                      color='primary'
                    />
                  }
                  label={non_mandatory_field.label}
                /> 
              </Box>
            ))
          }
        </Box> */}
        <Box className="formControl">
          <TextField
            id="attach-custom-form"
            select
            label="Attach Custom Form"
            name="form_id"
            value={form.form_id}
            onChange={handleChange}
            variant="outlined"
          >
            {props.forms.map((form) => (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) =>
              handleSubmit(
                event,
                props.item && props.item.hasOwnProperty("_id")
                  ? "update"
                  : "add"
              )
            }
          >
            {props.item && props.item.hasOwnProperty("_id")
              ? "Update & Update to Program"
              : "Save & Add to Program"}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default AddEditLocationType;
