import axios from '../mainaxios'


export const getStats = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get('/dashboard/static', {
    headers: {
      Authorization: token
    }
  })
  return res
}

export const getLatestStats = () => async () => {
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  let res = await axios.get('/dashboard/lastupdated', {
    headers: {
      Authorization: token
    }
  })
  return res
}

