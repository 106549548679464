import React,{useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import { 
  TextField,
  Button,
  Box,
 } from '@material-ui/core';
 import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
 import Form from '../../Form/form'
//  import {history} from '../../../routes/history'
 import {getAllForm} from './../../../store/actions/form'
 import {createBenificiaryType} from './../../../store/actions/beneficiary'
 import SimpleReactValidator from 'simple-react-validator'

const AddEditPermissionFile = (props) => {
  const [, forceUpdate] = useState()
  const [form, setForm] = useState({
    name: '',
    file: '',
    preview: ''
    
  })

  const simpleValidator = useRef(new SimpleReactValidator())

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
    if(name==="file"){
      let reader = new FileReader()
      value = event.target.files[0]
      reader.onloadend = () => {
        setForm(prev => ({
          ...prev,
          [name]:value,
          preview: reader.result
        }))
      }
      if(value && value.name !== ''){
        reader.readAsDataURL(value)
      }
    } else{
      setForm(prev => ({
        ...prev,
        [name]:value
      }))
    }
  }

  const handleSubmit = (e, method) => {
    e.preventDefault()
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    let formData = new FormData()
    formData.append("name", form.name)
    formData.append('preview', form.preview)
    if(form.file){
      formData.append("file", form.file)
    }
    if(method==="add"){
      props.handleSubmitData(formData)
    }else{
      props.handleSubmitData(form._id, formData)
    }
  }

  
  useEffect(() => {
    if(props.item){
      setForm(props.item)
    }
  },[])

  return (
    <Box className="createBeneficiaryType">
      <Form 
        onSubmit={handleSubmit} 
        className="beneficiaryTypeForm"
      >
        <Box className="formControl imageUpload">
          <label style={{fontSize: '0.857rem', fontWeight: 'bold', display: 'inline-block', marginBottom: 10}}>Upload File</label>
          <label htmlFor="file-upload" className="customFileUpload">
            <span style={{color: '#000'}}>
              {
                !form.file ? 'Select Type' : form.file.name
              }
            </span>
            <AccountBoxOutlinedIcon size="small"/> 
          </label>
          <TextField 
            id='file-upload'
            type='file'
            autoFocus
            name="file"
            onChange={handleChange}
          />
        </Box>
        
        <Box className="formControl">
          <label style={{fontSize: '0.857rem', fontWeight: 'bold', display: 'inline-block', marginBottom: 10}}>Permission Name</label>
          <TextField 
            type='text'
            label="Permission Name"
            variant="outlined"
            name='name'
            value={form.name}
            onChange={handleChange}
          />
         
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) => handleSubmit(event,props.item && props.item.hasOwnProperty("_id")?"update":"add")}
          >
            {props.item && props.item.hasOwnProperty("_id")?"Update & Update to Program":"Save & Add to Program"}
          </Button>
        </Box>
      </Form>
    </Box>
  )
}

export default connect(null, {getAllForm, createBenificiaryType})(AddEditPermissionFile)
