import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton, Button,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux'
import BeneficiariesTable from './BeneficiariesTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import { getAllEventType, createEventType, editEventType } from './../../../store/actions/event'
import { editProgram, deactivateModule } from '../../../store/actions/program'
import { getAllForm } from '../../../store/actions/form'
import _ from 'lodash'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import ModulePermissionDialog from './ModulePermissionDialog'
import '../../../scss/_beneficiaries.scss'

const Events = props => {
  const [loader, setLoader] = useState(true)
  const [eventTypes, setEventTypes] = useState([])
  const [searchData, setSearchData] = useState([])
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [forms, setForms] = useState([])
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [program, setProgram] = useState({})
  const [addedEventTypes, setAddedEventTypes] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [openModulePermission, setOpenModulePermission] = useState(false)

  let { active_module } = props.program ? props.program : null
  const getAddedEventTypes = (data) => {
    let tempAddEventType = data.filter(event_type => {
      if (props.program.event_types.indexOf(event_type._id) > -1) {
        return true
      }
      return false
    })
    setSearchData([...tempAddEventType])
    setAddedEventTypes(tempAddEventType)
  }
  const getEventNotAddedToProgram = (data) => {
    let tempEventType = data.filter(event_type => {
      if (props.program.event_types.indexOf(event_type._id) > -1) {
        return false
      }
      return true
    })
    setEventTypes([...tempEventType])
  }
  const getEventTypes = () => {
    setLoader(true)
    props.getAllEventType().then(({ data }) => {
      getAddedEventTypes(data)
      getEventNotAddedToProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }

  const addEventType = (eventtype) => {
    setLoader(true)
    props.createEventType(eventtype).then(({ data }) => {
      addItemTypeToProgram(data)
      let tempEventTypes = _.cloneDeep(eventTypes)
      tempEventTypes = [...tempEventTypes, data]
      setEventTypes(tempEventTypes)
      handleDrawer(false)
      setLoader(false)
      openSnackbar({ message: 'Added Event Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
      openSnackbar({ message: 'Error in Adding Event Type', status: 'error' });
    })
  }

  const updateEventType = (eventtype) => {
    setLoader(true)
    props.editEventType(eventtype).then(({ data }) => {
      let index = addedEventTypes.findIndex(event_type => event_type._id === data._id)
      addedEventTypes.splice(index, 1, data)
      setAddedEventTypes([...addedEventTypes])
      setSearchData([...addedEventTypes])
      handleEditDrawer(false)
      setLoader(false)
      openSnackbar({ message: 'Edited Event Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
      openSnackbar({ message: 'Error in Edit Event Type', status: 'error' });
    })
  }
  const addItemTypeToProgram = (event_type) => {
    setLoader(true)
    let tempProgram = { _id: program._id, event_types: [...program.event_types, event_type._id] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = eventTypes.findIndex(type => type._id === event_type._id)
      let tempEventTypes = _.cloneDeep(eventTypes)
      tempEventTypes.splice(index, 1)
      setEventTypes(tempEventTypes)
      setAddedEventTypes([...addedEventTypes, event_type])
      setSearchData([...addedEventTypes, event_type])
      handleDrawer(false)
      setLoader(false)
      openSnackbar({ message: 'Added Event Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
      openSnackbar({ message: 'Error in Add Event Type', status: 'error' });
    })
  }
  const removeItemTypeFromProgram = (event_type) => {
    setLoader(true)
    let temp_event_types = program.event_types.filter(_id => _id !== event_type._id)
    let tempProgram = { _id: program._id, event_types: [...temp_event_types] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = addedEventTypes.findIndex(type => type._id === event_type._id)
      let tempAddedEventTypes = _.cloneDeep(addedEventTypes)
      tempAddedEventTypes.splice(index, 1)
      setAddedEventTypes(tempAddedEventTypes)
      setSearchData([...tempAddedEventTypes])
      setEventTypes([...eventTypes, event_type])
      handleDrawer(false)
      setLoader(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Event Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Error in Removing Event Type', status: 'error' });
    })
  }

  const getAllForms = () => {
    setLoader(true)
    props.getAllForm(props.program._id).then(({ data }) => {
      setForms(data.filter(program => program.has_published === true))
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      console.log("err", err, err.response)
    })
  }
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }
  const deactivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, events: false } }

    props.editProgram(tempProgram).then(({ data }) => {
      openSnackbar({ message: 'Events module deactivated', status: 'success' });
      props.setProgram(data)
      props.handleLink(3)

    }).catch(err => {
      console.log('err', err)
    })
  }

  useEffect(() => {
    setProgram(props.program)
    getAllForms()
    getEventTypes()
    if (props.program && props.program.active_module && props.program.active_module.events === false) {
      setOpenModulePermission(true)
    }
  }, []);

  const filterData = event => {
    const updateList = addedEventTypes.filter(item => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setSearchData(updateList)
  }

  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  const handlePermissionModal = (value) => {
    setOpenModulePermission(value)
  }
  const programModuleActivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, events: true } }
    props.editProgram(tempProgram).then(({ data }) => {
      handlePermissionModal(false)
      props.setProgram(data)
    }).catch(err => {
      console.log('err', err)
    })
  }

  return (
    <React.Fragment>
      <NotifierSnack />
      <ModulePermissionDialog
        openModulePermission={openModulePermission}
        handlePermissionModal={handlePermissionModal}
        programModuleActivate={programModuleActivate}
        handleLink={props.handleLink}
      />
      <Box className="tabbackLinkMain">
        <Box className="link" onClick={() => props.handleLink(3)}>
          <ChevronLeftIcon />
          <span>Back to all modules</span>
        </Box>
      </Box>
      <Box className="Beneficiaries">
        <Box className="headingWrap leftTextRightSearchAndBtn">
          <span className="heading">
            <CalendarTodayOutlinedIcon />
            <h3>Events</h3>
            <span >{active_module && active_module.events ? "Active" : "Inactive"}</span>
          </span>
          <Box className="rightContent">
            <Button variant="contained" color="primary" onClick={deactivate}>
              {active_module && active_module.events ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
          </Box>
        </Box>
        <Box className="leftTextRightSearchAndBtn">
          <span className="leftTxt">Active Event Types</span>
          <Box className="rightContent">
            <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
              <input
                type="text"
                name="searchData"
                placeholder="Search"
                className="serachInput"
                onChange={filterData}
              />
              <IconButton className="iconButton" type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className="buttonWrap">
              <BeneficiariesDrawerTab
                buttonLabel="ADD EVENT TYPE"
                name="Add EVENT TYPE TO PROGRAM"
                title='Event'
                existing="Select from existing"
                add_title="Create new event type"
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                forms={forms}
                handleSubmitData={addEventType}
                addItemTypeToProgram={addItemTypeToProgram}
                data={eventTypes}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <BeneficiariesTable
          isEditDrawer={isEditDrawer}
          handleEditDrawer={handleEditDrawer}
          forms={forms}
          handleSubmitData={updateEventType}
          data={searchData}
          loader={loader}
          removeItemTypeFromProgram={removeItemTypeFromProgram}
          name="Event"
          emptyMessage="No Event Found"
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
        />
      </Box>
    </React.Fragment>
  )
}

export default connect(null, {
  getAllEventType,
  editProgram,
  createEventType,
  editEventType,
  getAllForm, deactivateModule
})(Events)
