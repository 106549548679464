import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { 
  Grid,
  Paper,
  Tooltip,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Wrapper from '../../components/Global/Wraper'
import useStyles from '../../components/Global/useStyles'
import TablePrograms from '../../components/PageCompnent/Programs/TablePrograms'
import {connect} from 'react-redux'
import {getAllProgram} from '../../store/actions/program'

const Programs = props => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false)
  const [programs, setPrograms] = useState([])

  const getPrograms = () => {
    setLoader(true)
    props.getAllProgram().then(({data}) => {
      setPrograms(data)
      setLoader(false)
    })
  }

  useEffect(() => {
    getPrograms()  
  }, [])

  return (
    <Wrapper pageTitle="Programs">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper} style={{marginBottom: 60, padding: '0'}}>
            <TablePrograms 
              data={programs}
              loader={loader}
            />
          </Paper>
        </Grid>
      </Grid>
      <Tooltip title='Add Program' aria-label='add-program' arrow style={{position: 'absolute', bottom: 25, right: 25}}>
        <Link to='/program/add'>
          <Fab className="circleButton greebBtn">
            <AddIcon />
          </Fab>
        </Link>
      </Tooltip>
    </Wrapper>
  )
}

export default connect(null, {getAllProgram})(Programs)
