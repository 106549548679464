import React, { useState, useRef } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  // List,
  // ListItem,
  // Checkbox,
  // FormControlLabel,
} from "@material-ui/core";
import Form from "../../Form/form";
import _ from "lodash";
import SimpleReactValidator from "simple-react-validator";

// const non_mandatory_fields = [
//   {
//     name: "address",
//     label: "Address Field",
//   },
//   {
//     name: "abbreviated_address",
//     label: "Abbreviated Address Field",
//   },
// ];
const AddEditCaseType = (props) => {
  const [, forceUpdate] = useState();
  const [form, setForm] = useState({
    name: "",
    plural_name: "",
    description: "",
    form: "",
    form_id: "",
    non_mandatory_fields: {},
    case_type_status: [],
    check_list_value: "",
  });

  const [optional, setOptional] = useState({
    address: false,
    abbreviated_address: false,
  });
  const [checkListChip, setCheckListChip] = useState(["Open", "Closed"]);

  const simpleValidator = useRef(new SimpleReactValidator());

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const handleChangeOption = (event) => {
  //   let name = event.target.name;
  //   let checked = event.target.checked;
  //   setOptional({ ...optional, [name]: checked });
  // };
  const handleSubmit = (event, method) => {
    event.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true);
      forceUpdate(true);
      return;
    }
    let data = _.cloneDeep(form);
    data.form = data.form_id;
    data.non_mandatory_fields = optional;
    data.non_mandatory_check_fields = optional;
    data.case_type_status = checkListChip;
    props.handleSubmitData(data);
  };
  const loadDataProperly = (item) => {
    let tempCasetype = _.omit(
      item,
      "mandatory_fields",
      "non_mandatory_fields",
      "structures",
      "created_by"
    );
    setOptional({ ...optional, ...tempCasetype.non_mandatory_check_fields });
    return tempCasetype;
  };
  useState(() => {
    if (props.item) {
      let item = loadDataProperly(props.item);
      setCheckListChip(props.item.case_type_status);
      setForm(item);
    }
  }, []);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      setCheckListChip([...checkListChip, form.check_list_value]);
      setForm((prev) => ({
        ...prev,
        check_list_value: "",
      }));
    }
  };

  const handleDelete = (item) => {
    let filter = checkListChip.filter((i) => i !== item);
    setCheckListChip(filter);
  };

  return (
    <Box className="createBeneficiaryType">
      <Form
        formHeading={props.title}
        formSubheading="Use the form below to add a new case type and add it to the program"
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
          <TextField
            type="text"
            label="Case Type Name"
            variant="outlined"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "case type name",
              form.name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Plural Name"
            variant="outlined"
            name="plural_name"
            value={form.plural_name}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "plural name",
              form.plural_name,
              "required"
            )}
          </Box>
        </Box>
        <Box className="formControl">
          <TextField
            type="text"
            label="Case Type Description"
            multiline
            variant="outlined"
            name="description"
            value={form.description}
            onChange={handleChange}
          />
          <Box className="validationMessage">
            {simpleValidator.current.message(
              "case type description",
              form.description,
              "required"
            )}
          </Box>
        </Box>
        {/* <Box className="optionalField">
          <Box>
          <Box className="formControlHeading">
            <h3>Mandatory Fields:</h3>
          </Box>
          <List>
            <ListItem className="formControl">Beneficiary </ListItem>
            <ListItem className="formControl">Location</ListItem>
          </List>
          </Box>
          <Box className="formControlHeading">
            <h3>Non Mandatory Fields:</h3>
          </Box>
          {
            non_mandatory_fields.map((non_mandatory_field, index) => (
              <Box className="formControl" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={optional[non_mandatory_field.name] } 
                      onChange={handleChangeOption} 
                      name={non_mandatory_field.name} 
                      color='primary'
                    />
                  }
                  label={non_mandatory_field.label}
                /> 
              </Box>
            ))
          }
        </Box> */}
        <Box className="formControl">
          <label
            style={{
              fontSize: "0.857rem",
              fontWeight: "bold",
              marginBottom: 10,
              display: "inline-block",
            }}
          >
            Attach a Custom Form
          </label>
          <TextField
            id="attach-custom-form"
            select
            label="Select Form"
            name="form_id"
            value={form.form_id}
            onChange={handleChange}
            variant="outlined"
          >
            {props.forms.map((form) => (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="formControl">
          <label
            style={{
              fontSize: "0.857rem",
              fontWeight: "bold",
              marginBottom: 10,
              display: "inline-block",
            }}
          >
            Case Type Statuses
          </label>
          <Box className="chipMainWrap">
            {checkListChip.map((item, i) => (
              <div className="tag-item" key={i}>
                {item}
                <button
                  type="button"
                  className="chipButton"
                  onClick={() => handleDelete(i !== 0 && i !== 1 ? i : null)}
                  style={
                    i !== 0 && i !== 1
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                >
                  &times;
                </button>
              </div>
            ))}

            <input
              className={"input "}
              name={`check_list_value`}
              value={form.check_list_value}
              placeholder="Type here to add new case statuses and press 'Enter'..."
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={(event) =>
              handleSubmit(
                event,
                props.item && props.item.hasOwnProperty("_id")
                  ? "update"
                  : "add"
              )
            }
          >
            {props.item && props.item.hasOwnProperty("_id")
              ? "Update & Update to Program"
              : "Save & Add to Program"}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default AddEditCaseType;
