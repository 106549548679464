import React, { useState } from 'react'
import {
  Button,
  TextField,
  Collapse,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import AuthWraper from '../Global/AuthWraper'
import { useHistory } from 'react-router-dom'
import { changePassword } from '../../store/actions/auth'
import { connect } from 'react-redux'
import NotifierSnack, { openSnackbar } from '../Global/NotifierSnackbar'

const Otp = (props) => {
  let history = useHistory()
  const [email, setEmail] = useState(null)
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    setEmail({ [event.target.id]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault();
    props.changePassword(email).then(() => {
      openSnackbar({ message: 'Please contact admin! Mail has send to them', status: 'success' });
      history.push('/login')
    }).catch(err => {
      console.log("err")
      openSnackbar({ message: 'Something went wrong', status: 'error' });
    })

  }

  return (
    <AuthWraper onSubmit={handleSubmit}>
      <NotifierSnack />
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Incorrect OTP
        </Alert>
      </Collapse>
      <Typography component="h3" variant="h5">
        Enter Email
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Enter email"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={handleChange}
      />
      <Box className="buttonWrap">
        <Button
          type="submit"
          variant="contained"
          className='button'
          onClick={handleSubmit}
        >
          Submit
        </Button>
        {/* <Link to="/resetpassword">
          Resent OTP
        </Link> */}
      </Box>
    </AuthWraper>
  );
}

export default connect(null, { changePassword })(Otp)
