import React, { useState, useEffect, useRef } from 'react';
import {Button,Dialog,TextField, makeStyles, DialogActions, DialogContent, DialogTitle, Box} from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator'
import '../../scss/_dialog.scss'

function AddEditRole(props) {
  const classes = useStyles();
  const [, forceUpdate] = useState()
  const [form, setForm] = useState({
    role:""
  })

  const simpleValidator = useRef(new SimpleReactValidator())

  useEffect(() => {
    if(props.modal.form){
      setForm(props.modal.form)
    }else{
      setForm("")
    }
  }, [props.modal.form])

  const onChangeInput = (event) =>{
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
  }

  const handleSubmit = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    props.modal.action(form, setForm)
  }
 
  return (
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        aria-labelledby="max-width-dialog-title"
        onClose={() => props.handleRoleModal(false)}
        className="customDialog"
      >
        <DialogTitle id="responsive-dialog-title">{props.modal.title}</DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.textInput}
              autoFocus
              margin="dense"
              name="role"
              label="Role"
              variant="outlined"
              value={form.role}
              onChange={onChangeInput}
              type="text"
            />
            <Box style={{color: 'red'}}>
              {simpleValidator.current.message('Role', form.role, 'required')}
            </Box>
          </form>
        </DialogContent>
        <DialogActions className="diaglogButtonWrap">
          <Button 
              onClick={handleSubmit} 
              color="primary" 
              autoFocus
              className="greenBtn"
            >
              {props.modal.actionButton}
          </Button>
          <Button 
            autoFocus onClick={() => props.handleRoleModal(false)} 
            color="default"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default (AddEditRole)

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
  },
  textInput:{
    width:'100%'
  }
}));
