import React, {useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  CircularProgress,
  Box,
 } from '@material-ui/core'
 import TablePaginationActions from '../../common/TablePaginationActions'

 import '../../../scss/_table.scss'

const EventsTable = props => {
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setLoader(false)
  }, [props.data])

  return (
    <React.Fragment>
      <TableContainer>
        <Table size="medium" aria-label="user table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Attached to Beneficiaries</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          {
            loader ?
            <TableBody>
              <TableRow>
                <TableCell align="center" width="100%" colSpan={12}>
                <Box>
                  <CircularProgress disableShrink />
                </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            :
            <TableBody>
              {
                props.data.map((item, key)=> (
                  <TableRow key={key}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {item.attached_to_program && item.attached_to_program.map((x,i)=><p key={i}>{x}</p>)}
                    </TableCell>
                    <TableCell 
                      align="right" 
                      className="tableLink"
                      onClick={()=>alert(`All ${item.name}`)}
                    >
                      {`View all ${item.name}`}
                    </TableCell>
                  </TableRow>
                ))
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          }
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{borderBottom: 0}}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={8}
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'users per page' },
                  native: true,
                }}
                labelRowsPerPage='Users per page'
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default withRouter(EventsTable)

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);