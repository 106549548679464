import {} from './type'
import axios from '../axios'


export const getAllRole = (vm) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('/role', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleRole = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`role/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createRole = (role) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('role', {...role}, {
    headers:{
      Authorization: token
    }
  })
}
export const editRole = (role) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`role/${role._id}`, {...role}, {
    headers:{
      Authorization: token
    }
  })
}
export const deactivateRole = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`role/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}

export const updatePermission = (permission) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`permission/${permission._id}`,{...permission}, {
    headers:{
      Authorization: token
    }
  })
}