import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

const mapStyles = {
    width: '300px',
    height: '300px',
    position: 'initial'
};
const containerStyle = {
    position: 'initial',
    width: '300px',
    height: '300px',
}

export class MapContainer extends Component {
    state = {
        showingInfoWindow: true,  // Hides or shows the InfoWindow
        activeMarker: {},          // Shows the active marker upon click
        selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
    };
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        return (
            <Map
                containerStyle={containerStyle}
                google={this.props.google}
                zoom={14}
                style={mapStyles}
                initialCenter={
                    {
                        lat: this.props.data.latitude,
                        lng: this.props.data.longitude
                    }
                }
            >
                <Marker
                    onClick={this.onMarkerClick}
                    position={{ lat: this.props.data.latitude, lng: this.props.data.longitude }}
                />
                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={this.onClose}
                >
                    <div>
                        <h4>{this.state.selectedPlace.name}</h4>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyD7Fw2Nge0oFWs-RWR6TRMVaur0WT__bKM'
})(MapContainer);