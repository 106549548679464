import {} from './type'
import axios from '../mainaxios'


export const getAllPermissionFiles = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('permission', {
    headers:{
      Authorization: token
    }
  })
}
export const getSinglePermissionFile = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`permission/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createPermissionFile = (permission) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('permission', permission, {
    headers:{
      Authorization: token
    }
  })
}
export const editPermissionFile = (_id, permission) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`permission/${_id}`, permission, {
    headers:{
      Authorization: token
    }
  })
}
export const deletePermissionFile = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`permission/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
