import React, {useEffect} from 'react';
import Routes from './routes/index'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import store from './store/'
import { ConnectedRouter } from 'connected-react-router'
import {history} from './store'

const persistStore = store()

function App() {

 const permNotify = async () => {
 	return await Notification.requestPermission();
 }
 
  useEffect(() => {
  	permNotify()
  }, [])

  return (
    <Provider store={persistStore.store}>
      <PersistGate persistor={persistStore.persistor}>
        <ConnectedRouter history={history}>
          <Routes url={window.location.pathname}/>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
