import React, {useState, useEffect} from 'react'
import {
  Paper,
  Grid,
  TextField,
  Tooltip,
  Fab,
  Box,
  Typography,
  CircularProgress
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom'
import useStyles from '../../components//Global/useStyles'

const UserProfile = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [loader] = useState(false)

  useEffect(() => {
    const {id, emp_id, name, email, mobile, designation, role} = props
    setUser({
      id: id, 
      emp_id: emp_id,
      name: name, 
      email: email, 
      mobile: mobile, 
      designation: designation, 
      role: role
    })
  }, [props])
  // console.log(user, 'user...../')

  // console.log(typeof(user.name, 'typeof.../'))

  return (
    <React.Fragment>
      {
        loader ?
        <Box style={{textAlign: 'center'}}>
          <CircularProgress disableShrink />
        </Box>
        :
        <Grid container spacing={4}>
          <Grid item md={3}>
          <Paper className={classes.paper}>
            <Box className='profileImageWrap'>
              <img src="/image/user2.png" alt="User Profile" />
            </Box>
          </Paper>
          </Grid> 
          <Grid item md={9}>
            <Box className={'userProfileData'}>
              <Box component="span">
                <Typography component='h4' style={{fontWeight: 'bold'}}>
                  Employee ID:
                </Typography>
                <TextField 
                  type='text'
                  value={user.emp_id}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
              <Box component="span">
                <Typography component='h4' style={{fontWeight: 'bold'}}>
                  Name:
                </Typography>
                <TextField 
                  type='text'
                  value={user.name}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
              <Box component="span">
                <Typography component='h4' style={{fontWeight: 'bold'}}>
                  Email:
                </Typography>
                <TextField 
                  type='text'
                  value={props.email}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
              <Box component="span">
                <Typography component='h4' style={{fontWeight: 'bold'}}>
                  Phone No.:
                </Typography>
                <TextField 
                  type='text'
                  value={props.mobile}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
              <Box component="span">
                <Typography component='h4' style={{fontWeight: 'bold'}}>
                  Designation:
                </Typography>
                <TextField 
                  type='text'
                  value={props.designation}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
              <Box component="span">
                <Typography component='h4' style={{fontWeight: 'bold'}}>
                  Role:
                </Typography>
                <TextField 
                  type='text'
                  value={props.role}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Tooltip 
            title='Edit user' 
            aria-label='add-user' 
            arrow
            style={{position: 'absolute', bottom: 25, right: 25}}
          >
            <Link to={`/user/edit/${props.id}`} >
              <Fab className="circleButton greebBtn">
                <CreateIcon />
              </Fab>
            </Link>
          </Tooltip>
          
        </Grid>
      }
      
    </React.Fragment>
  )
}

export default UserProfile
