import React,{useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import { 
  TextField,
  MenuItem,
  List,
  ListItem,
  Checkbox,
  Button,
  FormControlLabel,
  Box,
 } from '@material-ui/core';
 import Form from '../../Form/form'
 import _ from 'lodash'
 import SimpleReactValidator from 'simple-react-validator'

const AddNewTeamMember = (props) => {
  const [, forceUpdate] = useState()
  const [form, setForm] = useState({
    form: '',
    is_program_head: false,
  })
  const [users, setUsers] = useState([]) 

  const [optional, setOptional] = useState({
    is_program_head: false,
  })

  const simpleValidator = useRef(new SimpleReactValidator())

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
  }
  const handleChangeOption = event => {
    let name = event.target.name
    let checked = event.target.checked
    setForm({...form, [name]:checked})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    let data = _.cloneDeep(form)
    props.handleSubmitData(data)
  }
  
  return (
    <Box className="createBeneficiaryType">
      <Form 
        onSubmit={handleSubmit} 
        className="beneficiaryTypeForm"
      >
        <Box className="formControl">
          <TextField
            id="attach-custom-form"
            select
            label="Select User"
            name="form"
            onChange={handleChange}
            variant="outlined"
          >
            {
              props.items.map(user => (
              <MenuItem key={user._id} value={user._id}>
                {user.first_name} {user.last_name}
              </MenuItem>
              ))
            }
          </TextField>
          <Box className="validationMessage">
            {simpleValidator.current.message('select user', form.form, 'required')}
          </Box>
        </Box>
        <Box className="optionalField" style={{paddingTop: 0}}>
          <Box className="formControl">
            <FormControlLabel
              control={
                <Checkbox 
                  checked={form.is_program_head } 
                  onChange={handleChangeOption} 
                  name="is_program_head" 
                  color='primary'
                />
              }
              label="Program Head"
            /> 
          </Box>
        </Box>
        <Box className="buttonWrap">
          <Button
            type="submit"
            variant="contained"
            className="customBtn greebBtn"
            // className={classes.submit}
            onClick={handleSubmit}
          >
            Save & Add to Program
          </Button>
          </Box>
      </Form>
    </Box>
  )
}

export default AddNewTeamMember
