import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Collapse,
  IconButton,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { loginUser } from "../../store/actions/auth";
import { connect } from "react-redux";
// import NotifierSnack, {openSnackbar} from '../../Global/NotifierSnackbar'
import AuthWraper from "../../components/Global/AuthWraper.js";
import NotifierSnack, {
  openSnackbar,
} from "../../components/Global/NotifierSnackbar";

const Login = (props) => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [open, setOpen] = useState(false);

  const submitUser = (event) => {
    event.preventDefault();
    props
      .loginUser(form, props)
      .then(() => {
        openSnackbar({ message: "Logged in", status: "success" });
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          openSnackbar({ message: err.response.data.message, status: "error" });
        } else {
          openSnackbar({
            message: err?.message || "Failed to login",
            status: "error",
          });
        }
      });
    // history.push('/dashboard')
  };
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setForm({ ...form, [name]: value });
  };
  return (
    <AuthWraper>
      <NotifierSnack />
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          All fields are required
        </Alert>
      </Collapse>
      <Typography component="h3" variant="h5">
        Login to Wild Connect
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        type="text"
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={form.email}
        onChange={onChangeInput}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        value={form.password}
        autoComplete="current-password"
        onChange={onChangeInput}
      />
      <Box className="buttonWrap">
        <Button
          type="submit"
          variant="contained"
          className="button"
          onClick={submitUser}
        >
          LOGIN
        </Button>
        <Link to="/otp">Forgot password?</Link>
      </Box>
    </AuthWraper>
  );
};

export default connect(null, { loginUser })(Login);
