import {} from './type'
import axios from '../axios'

export const getAllDesignation = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('designation', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleDesignation = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`designation/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createDesignation = (designation) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('designation', {...designation}, {
    headers:{
      Authorization: token
    }
  })
}
export const editDesignation = (designation) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`designation/${designation._id}`, {...designation}, {
    headers:{
      Authorization: token
    }
  })
}
export const deactivateDesignation = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`designation/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}