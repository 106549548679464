import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';

const notification = [
  {
    id:1,
    content:"this is comment 1"
  },
  {
    id:2,
    content:"this is comment 2"
  },
  {
    id:3,
    content:"this is comment 3"
  },
  {
    id:4,
    content:"this is comment 4"
  }
]
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});
export default function Notify(props) {
  const [notifications, setNotifications] = useState([])
  const classes = useStyles();
  const handleListItemClick = () => {
    props.handleNotifyModal(false)
  };

  useEffect(() => {
    setNotifications(notification)
  }, [])

  return (
    <Dialog onClose={() => props.handleNotifyModal(false)} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
      <List>
        {notifications.map((notification, i) => (
          <ListItem button onClick={() => handleListItemClick()} key={i}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={notification.content} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem>
      </List>
    </Dialog>
  );
}


