

export const addData = (listData, addedData) => {
  return [...listData, {...addedData}]
}
export const updateData = (listData, updatedData) => {
  let index = listData.findIndex(data => data._id === updatedData._id)
  listData.splice(index, 1, updatedData)
  return listData
}
export const deleteData = (listData, _id) => {
  return listData.filter(data => data._id !== _id)
}