import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  AppBar,
  Tab,
  Tabs,
  Paper,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import Wrapper from '../../components/Global/Wraper'
import useStyles from '../../components//Global/useStyles'
import TabPanel from '../../components//Tabs/TabPanel'
import UserProfile from './UserProfile'
import { getSingleUser } from '../../store/actions/user'

import '../../scss/useProfile.scss'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SignleUser = props => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [user, setUser] = useState({})
  const [loader, setLoader] = useState(false)

  // const getAllUsers = () =>{
  //   props.getAllUser().then(({data}) => {
  //     setUser2(data)
  //   })
  // }
  const getUser = () => {
    setLoader(true)
    const { id } = props.match.params
    props.getSingleUser(id).then(({ data }) => {
      setLoader(false)
      setUser(data)
    }).catch(err => {
      console.log("err", err)
      setLoader(false)
    })
  }
  useEffect(() => {
    getUser()
  }, [props])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Wrapper
        pageTitle={user.first_name && (user.first_name) + ' ' + (user.last_name && user.last_name)}
        backLink={props.history.location.pathname.split('/')[1]}
      >
        {
          loader ?
            <Box className={classes.loaderCenter}>
              <CircularProgress disableShrink />
            </Box>
            :
            <>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="user-details-tab"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="PROFILE" {...a11yProps(0)} />
                  {/* <Tab label="PERMISSIONS" {...a11yProps(1)} /> */}
                </Tabs>
              </AppBar>
              <Paper className={classes.paper}>
                <TabPanel value={value} index={0}>
                  <UserProfile
                    id={user._id}
                    name={`${user.first_name} ${user.last_name}`}
                    email={user.email}
                    mobile={user.mobile}
                    designation={user.designation ? user.designation.name : ""}
                    role={user.role ? user.role.role : ""}
                    emp_id={user.employeeId}
                  />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
              <UserPermission />
            </TabPanel> */}
              </Paper>
            </>
        }
      </Wrapper>
    </React.Fragment>
  )
}


export default connect(null, { getSingleUser })(withRouter(SignleUser))
