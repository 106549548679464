import React, { Component } from "react";
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box,
  Checkbox,
  ListItemText,
  Input,
  CircularProgress,
} from "@material-ui/core";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import Form from "../../components/Form/form";
import Wrapper from "../../components/Global/Wraper";
import { history } from "../../routes/history";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import {
  getSingleProgram,
  editProgramForProgram,
  syncSingleProgram,
} from "../../store/actions/program";
import { url } from "../../helper/CommonHelper";
import { getAllUser } from "../../store/actions/user";
import BackToLink from "../../components/common/BackToLink";
import NotifierSnack, {
  openSnackbar,
} from "../../components/Global/NotifierSnackbar";
import _ from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import "../../scss/_form.scss";

class EditProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        program_head: [],
        photo: "",
        description: "",
        documentVisible: "",
        no_of_days_for_edit_program: 0,
      },
      users: [],
      loader: true,
      selectedTrue: true,
      imagePreview: "",
    };
    this.validator = new SimpleReactValidator();
  }

  resetForm = () => {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        program_name: "",
        program_heads: [],
        photo: "",
        description: "",
        documentVisible: "",
        no_of_days_for_edit_program: 0,
      },
    }));
  };
  handleChange = (event) => {
    console.log(event.target, "event target");
    let name = event.target.name;
    let value = event.target.value;
    if (name === "program_head") {
      if (value.length > 0) {
        this.setState((prevState) => ({
          ...prevState,
          selectedTrue: true,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          selectedTrue: false,
        }));
      }
    }
    if (name === "photo") {
      let reader = new FileReader();
      value = event.target.files[0];
      reader.onloadend = () => {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            [name]: value,
          },
          imagePreview: reader.result,
        }));
      };
      reader.readAsDataURL(value);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        form: { ...prevState.form, [name]: value },
      }));
    }
  };
  onClearImage = () => {
    this.setState((prevState) => ({
      ...prevState,
      imagePreview: "",
      form: {
        ...prevState.form,
        photo: "",
      },
    }));
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages(true);
      this.forceUpdate();
      return;
    }
    let form = _.cloneDeep(this.state.form);
    form = _.omit(form, [
      "teams",
      "created_by",
      "location_types",
      "beneficiary_types",
      "event_types",
      "survey_types",
      "case_types",
      "permission_files",
      "active_module",
    ]);
    let formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key !== "photo") {
        if (typeof form[key] === "string" || typeof form[key] === "number") {
          formData.append(key, form[key]);
        } else {
          formData.append(key, JSON.stringify(form[key]));
        }
      }
    });
    if (form.photo) formData.append("photo", form.photo);

    this.props
      .editProgramForProgram(formData, form._id)
      .then(({ data }) => {
        // history.push('/program')

        this.props
          .syncSingleProgram(form._id)
          .then((res) => {})
          .catch((err) => {
            console.log("err", err, err.response);
          });
        openSnackbar({ message: `Program updated`, status: "success" });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          openSnackbar({
            message: `${err.response.data.msg}`,
            status: "error",
          });
        }
        console.log("err", err.response);
      });
  };

  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  getProgram = () => {
    const { id } = this.props.match.params;
    this.props
      .getSingleProgram(id)
      .then(({ data }) => {
        this.setState((prevState) => ({
          ...prevState,
          loader: false,
          // form:{...data, photo:"", program_head: data.program_head.map(head => head._id)}
          form: {
            ...data,
            program_head: data.program_head.map((head) => head._id),
          },
        }));
        console.log(this.state.form, "form");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  async getUsers() {
    this.props
      .getAllUser()
      .then(({ data }) => {
        this.setState((prevState) => ({
          ...prevState,
          loader: false,
          users: data,
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  checkPermssion = () => {
    let user = JSON.parse(localStorage.getItem("cws_user"));
    if (
      user &&
      user.role &&
      user.role.permission &&
      user.role.permission.edit_document_after_no_of_days
    ) {
      return true;
    } else {
      const date1 = new Date();
      const date2 = new Date(this.state.form.created_at);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (
        this.state.form &&
        this.state.form.no_of_days_for_edit_program >= diffDays
      ) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    this.getUsers();
    this.getProgram();
  }

  render() {
    const { users, loader, form, selectedTrue, imagePreview } = this.state;
    return (
      <Wrapper
        maxWidth="sm"
        pageTitle="Edit Program"
        backLink={history.location.pathname.split("/")[1]}
        secondParent={loader ? null : form.name}
        secondBackLink={`${history.location.pathname.split("/")[1]}/${
          history.location.pathname.split("/")[3]
        }`}
      >
        <NotifierSnack />
        {loader ? (
          <Box style={{ textAlign: "center" }}>
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <>
            <BackToLink
              link={`/program/${this.props.match.params.id}`}
              label="Back to program"
              style={{ marginLeft: -8 }}
            />
            <Form
              formHeading="Edit Program Details"
              formSubheading="Change the basic information about the program"
            >
              <Box className="formControl">
                <TextField
                  type="text"
                  label="Program Name"
                  required
                  variant="outlined"
                  name="name"
                  value={form.name}
                  onChange={(event) => this.handleChange(event)}
                />
                <Box component="span" style={{ color: "red" }}>
                  {this.validator.message(
                    "Program name",
                    form.name,
                    "required"
                  )}
                </Box>
              </Box>
              <Box className="formControl">
                <TextField
                  type="text"
                  label="Program Description"
                  required
                  variant="outlined"
                  multiline
                  rows={8}
                  name="description"
                  value={form.description}
                  onChange={(event) => this.handleChange(event)}
                />
              </Box>
              <Box
                className={`formControl multiSelectBox ${
                  selectedTrue ? "selectedTrue" : ""
                }`}
              >
                <InputLabel id="select-program-head">
                  Select Program Heads
                </InputLabel>
                <Select
                  labelId="select-program-head"
                  multiple
                  name="program_head"
                  value={form.program_head}
                  onChange={(event) => this.handleChange(event)}
                  input={<Input />}
                  renderValue={(selected) => {
                    return users.map((user) => {
                      if (selected.indexOf(user._id) > -1) {
                        return user.first_name + " " + user.last_name + ", ";
                      }
                    });
                  }}
                  MenuProps={this.MenuProps}
                >
                  {users.map((head, i) => {
                    return (
                      <MenuItem key={i} value={head._id}>
                        <Checkbox
                          checked={
                            this.state.form.program_head.indexOf(head._id) > -1
                          }
                        />
                        <ListItemText
                          primary={head.first_name + " " + head.last_name}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
                <Box component="span" style={{ color: "red" }}>
                  {this.validator.message(
                    "Program head",
                    form.program_head,
                    "required"
                  )}
                </Box>
              </Box>
              <Box className="formControl imageUpload">
                {form.photo && form.photo !== "unknown" && (
                  <Box className="photoBox">
                    <img
                      src={
                        imagePreview
                          ? imagePreview
                          : form.photo
                          ? url + form.photo
                          : ""
                      }
                      alt={form.name}
                    />
                    <ClearIcon onClick={this.onClearImage} size="small" />
                  </Box>
                )}
                <label for="image-upload" className="customFileUpload">
                  <span>Select Program Image</span>
                  <AccountBoxOutlinedIcon size="small" />
                </label>
                <TextField
                  id="image-upload"
                  type="file"
                  // label="Image Upload"
                  inputProps={{
                    accept: "image/x-png,image/gif,image/jpeg",
                  }}
                  autoFocus
                  name="photo"
                  onChange={(event) => this.handleChange(event)}
                />
              </Box>
              <Box className="formControl">
                <TextField
                  type="number"
                  label="Allowed number of days before disabling edit function"
                  variant="outlined"
                  name="no_of_days_for_edit_program"
                  value={form.no_of_days_for_edit_program}
                  onChange={(event) => this.handleChange(event)}
                />
                <Box component="span" style={{ color: "red" }}>
                  {this.validator.message(
                    "Allowed number of days before disabling edit function",
                    form.no_of_days_for_edit_program,
                    "required|integer|min:0,num"
                  )}
                </Box>
              </Box>
              <Box
                className={`formControl multiSelectBox ${
                  form.documentVisible !== "" ? "selectedTrue" : ""
                }`}
              >
                <InputLabel id="document-visible">
                  Documents Access Permission
                </InputLabel>
                <Select
                  id="documentVisible"
                  labelId="document-visible"
                  required
                  label="Documents Access Permission"
                  name="documentVisible"
                  value={form.documentVisible}
                  onChange={this.handleChange}
                  variant="outlined"
                >
                  <MenuItem value="ALL">Everyone can have access</MenuItem>
                  <MenuItem value="OWN">Only owner can have access</MenuItem>
                </Select>
                <Box component="span" style={{ color: "red" }}>
                  {this.validator.message(
                    "Documents Access Permission",
                    form.documentVisible,
                    "required"
                  )}
                </Box>
              </Box>

              {this.checkPermssion() && (
                <Button
                  type="submit"
                  variant="contained"
                  className="customBtn greebBtn"
                  onClick={(event) => this.handleSubmit(event)}
                >
                  Update
                </Button>
              )}
            </Form>
          </>
        )}
      </Wrapper>
    );
  }
}

export default connect(null, {
  getSingleProgram,
  getAllUser,
  editProgramForProgram,
  syncSingleProgram,
})(EditProgram);
