import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import Login from '../pages/auth/login'
import Otp from '../components/Form/otpForm'
import ResetPassword from '../components/Form/resetPassword'
import Dashboard from '../pages/Dashboard/index'
import Users from '../pages/Users/index'
import AddUser from '../pages/Users/AddUser'
import EditUser from '../pages/Users/EditUser'
import SingleUser from '../pages/Users/SignleUser'
// import NotFoundPage from '../components/common/NotFoundPage'
import { history } from './history'
import { connect } from 'react-redux'
import { checkToken } from '../store/actions/auth'
import { PrivateRoute } from './PrivateRoute'
import Roles from '../pages/roles'
import AddRole from '../pages/roles/AddEditRole'
import Desgnation from '../pages/designation'
import RolePermission from '../pages/roles/RolePermission'
import CustomForm from '../pages/customForm'
import ViewList from '../pages/customForm/ViewList'
import AddNewFormGroup from '../pages/customForm/AddNewFormGroup'
import EditFormGroup from '../pages/customForm/EditFormGroup'
import Programs from '../pages/programs/index'
import AddProgram from '../pages/programs/AddProgram'
import SinglrProgram from '../pages/programs/SingleProgram'
import EditProgram from '../pages/programs/EditProgram'
import Settings from '../pages/Settings/index'
import Support from '../pages/Support/index'
import ActivePrograms from '../pages/programs/ActivePrograms'
import DeactivatedPrograms from '../pages/programs/DeactivatedPrograms'
import Beneficiaries from '../pages/Beneficiaries/index'
import AddBeneficiaryType from '../pages/Beneficiaries/AddBeneficiaryType'
import Events from '../pages/Events/index'
import AddEventType from '../pages/Events/AddEventType'
import FormRevisions from '../pages/customForm/FormRevisions'

import GroupField from '../pages/customForm/GroupField' // test component
import Exports from '../pages/Exports';

let token = localStorage.getItem("cws_token")
let isLogin = localStorage.getItem("cws_isLogin")
const Routes = (props) => {
  const getUserStatus = () => {
    if (isLogin && token) {
      props.checkToken()
    } else {
      history.push('/login')
    }
  }
  useEffect(() => {
    getUserStatus()
  }, [])

  if (props.isLogin) {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route path='/dashboard' exact component={Dashboard} />
          <Route path='/user' exact component={Users} />
          <Route path='/user/add' exact component={AddUser} />
          <Route path='/user/edit/:id' exact component={EditUser} />
          <Route path='/user/:id' exact component={SingleUser} />
          <Route path="/designation" exact component={Desgnation} />
          <Route path="/role" exact component={Roles} />
          <Route path="/role/add" exact component={AddRole} />
          <Route path="/role/permission/:id" exact component={RolePermission} />
          <Route exact path="/custom-form" component={CustomForm} />
          <Route exact path="/custom-form/view-list" component={ViewList} />
          <Route exact path="/custom-form/add-new" component={AddNewFormGroup} />
          <Route exact path="/custom-form/edit/:id" component={EditFormGroup} />
          <Route exact path="/custom-form/form-revisions/:id" component={FormRevisions} />
          <Route exact path="/program" component={Programs} />
          <Route exact path="/program/active" component={ActivePrograms} />
          <Route exact path="/program/deactive" component={DeactivatedPrograms} />
          <Route exact path="/program/add" component={AddProgram} />
          <Route exact path="/program/:id" component={SinglrProgram} />
          <Route exact path="/program/edit/:id" component={EditProgram} />
          <Route exact path="/group" component={GroupField} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/beneficiaries" component={Beneficiaries} />
          <Route exact path="/beneficiaries/add" component={AddBeneficiaryType} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/events/add" component={AddEventType} />
          <Route exact path="/data-explorer" component={Exports} />
          {/* <Route component={NotFoundPage} /> */}
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router history={history}>
        <Switch>
          <Route path='/login' exact component={Login} />
          <Route path='/otp' exact component={Otp} />
          <Route path='/resetpassword' exact component={ResetPassword} />
          {/* <Route component={NotFoundPage} /> */}
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.user.isLogin
  }
}

export default connect(mapStateToProps, { checkToken })(Routes)