import axios from '../mainaxios'


export const getAllCaseType = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('casetype', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleCaseType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`casetype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createCaseType = (casetype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('casetype', casetype, {
    headers:{
      Authorization: token
    }
  })
}
export const editCaseType = (casetype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`casetype/${casetype._id}`, casetype, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteCaseType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`casetype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
