import {combineReducers} from 'redux'
import UserReducer from './userReducers'
import defaultReducer from './defaultReducer'
import {connectRouter} from 'connected-react-router'
import roleReducer from './roleReducer'

export default (history)=> combineReducers({
  router: connectRouter(history),
  default: defaultReducer,
  user:UserReducer,
  role:roleReducer,
})
