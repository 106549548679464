import React, { useState, useEffect } from 'react'
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core'
import fieldType from '../../../data/fieldType'
import CheckBoxChoice from './../CheckBoxChoice'
import TrueFalseTextMessage from './../TrueFalseTextMessage'
import Placeholder from './../Placeholder'
import MinMaxField from './../MinMaxField'
import CondLogicField from './../CondLogicField'
import CollapseTableVisibleHeader from './../CollapseTableVisibleHeader'
import FormStructureHelper from '../../../../helper/FormStructureHelper'
import DragableTableBody from './../DragableTableBody'
import DragableTableRow from './../DragableTableRow'
import arrayMove from "array-move";
import DefaultValue from './../DefaultValue'
import removeSpecialChar from '../../../../helper/fields/_special'
import NotifierSnack, {openSnackbar} from '../../../Global/NotifierSnackbar'
import {getViewList} from './../../../../store/actions/form'
import {connect} from 'react-redux'
import {url} from '../../../../helper/CommonHelper'

import xlsx from 'xlsx'

import _ from 'lodash'

import '../../../../scss/_collapseInnerForm.scss'
import '../../../../scss/_collapseTable.scss'


const GroupCollapse = (props) => {
  let { savedData, errorMsg, allStructure } = props
  const [loader, setLoader] = useState(false)
  const [tempInputList, setTempInputList] = useState([])
  const [open, setOpen] = useState(undefined);
  const [URLList, setURLList] = useState([])
  const [inputList, setInputList] = useState([{
    field_label: '',
    field_name: '',
    field_type: 'text',
    order: 1,
    default: '',
    placeholder:"",
    url:"",
    show_field:false,
    truefalse_text: "",
    extra_details:{},
    validation: {
      required: false,
      condition_chack: false,
      min: '',
      max: '',
      cond_logic: [
      ]
    }
  }]);
  
  const getFieldTypeData = (data) => {
    let form = new FormStructureHelper()
    return form.loadForm(data)
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = _.cloneDeep(inputList)
    list[index][name] = value;
    if(name==="field_label"){
      list[index].field_name = removeSpecialChar(list[index].field_label.toLowerCase().split(" ").join("_"))
    }
    if(name==="field_name"){
      list[index].field_name = removeSpecialChar(list[index].field_name.toLowerCase().split(" ").join("_"))
    }
    if(name==="required"){
      list[index].validation.required = !list[index].validation.required
    }
    if(name==="show_field"){
      list[index].show_field = e.target.checked
    }
    if(name==="field_type"){
      list[index][name] = value
      list[index] = getFieldTypeData(list[index])
    }
    if(name==="field_type" && value === "bracket-replace"){
      loadFieldName(list, index)
    }
    if(name === 'bracket_replace'){
      list[index].extra_details.field_name = value
    }
    if(name==="condition_chack"){
      list[index].validation.condition_chack = !list[index].validation.condition_chack
      if(list[index].validation.condition_chack){
        list[index].validation.cond_logic.push({field_name:"",show:"any-value", value:"", cond:"and"})
      }else{
        list[index].validation.cond_logic= []
      }
    }
    if(name === 'option_choice'){
      list[index].extra_details.option_choice = value
      loadSelect(list, value, index)
    }
    setInputList(list);

  };
  const loadFieldName = (list, index) => {
    let fieldTypes = ["heading", "barcode", "image", "files", "gallery", "multiple-select", "user-selection", "address", "current-user", "locations", "beneficiaries", "bracket-replace"]
    let all_fields = []
    let length = list.length
    for(let i = 0; i< length; i++){
      if(fieldTypes.includes(list[i].field_type)){
        continue;
      }else if(list[i].field_type === "field-group"){
        for(let j = 0; j<list[i].extra_details.length; j++){
          if(fieldTypes.includes(list[i].field_type)){
            continue;
          }
          all_fields = [...all_fields, list[i].extra_details[j].field_name]
        }
        continue
      }
      all_fields = [...all_fields, list[i].field_name]
    }
    list[index].extra_details.all_fields = all_fields
  }
  // const loadSelectFromURL = (list, url, index) => {
  //   let option_choice = ""
  //   let selects = null
  //   fetch(url).then(res => {
  //     if(!res.ok) {
  //       openSnackbar({ message: 'Please Enter valid url', status: 'error' });
  //       return
  //     }
  //     return res.arrayBuffer()
  //   }).then(ab => {
  //     if(!ab){
  //       return 
  //     }
  //     let data = new Uint8Array(ab);
  //     let workbook = xlsx.read(data, {type:"array"});
  //     let length_workbook = workbook.Strings.length
  //     for(let i = 0; i<length_workbook/2; i++){
  //       if(workbook.Strings[2*i].t){
  //         option_choice+= `${workbook.Strings[2*i].t}:${workbook.Strings[2*i+1].t}`+"\n"
  //         selects= {...selects, [workbook.Strings[2*i].t]:workbook.Strings[2*i+1].t}
  //       }
  //     }
  //     list[index].extra_details.selects = selects
  //     list[index].extra_details.option_choice = option_choice
  //     let tempInputList = _.cloneDeep(list)
  //     setInputList(tempInputList)
  //   })
  // }
  const loadSelect = (list, value, index) =>{
    const choice = value
    const choiceArray = choice.split('\n')
    let choice_obj = null
    if(choiceArray.length>0){
      choiceArray.map( choice => {
        if(choice.includes(":")){
          let objs = choice.split(":")
          choice_obj={...choice_obj, [objs[0].trim()]: objs[1].trim()}
        }
        return choice
      });
      if(choice_obj){
        list[index].extra_details.selects = choice_obj
      }
    }
  }

  const onChangeMinMax = (event, index) => {
    let temp_inputList = _.cloneDeep(inputList)
    let name = event.target.name
    let value = event.target.value
    temp_inputList[index].validation[name] = value
    setInputList(temp_inputList)
  }
  const handleRemoveCondLogin = (parentIndex, index) => {
    let temp_inputList = _.cloneDeep(inputList)
    temp_inputList[parentIndex].validation.cond_logic.splice(index, 1)
    setInputList(temp_inputList)
  }

  const onChangeInput = (event, parentIndex, index) => {
    let name = event.target.name
    let value = event.target.value
    let temp_inputList = _.cloneDeep(inputList)
    temp_inputList[parentIndex].validation.cond_logic[index][name]= value
    setInputList(temp_inputList)
  }

  const handleAddCondLogic = (cond, i) => {
    let temp_inputList = _.cloneDeep(inputList)
    temp_inputList[i].validation.cond_logic.push({
      field_name: "",
      show: "any-value",
      value: "",
      cond: cond
    })
    setInputList(temp_inputList)
  }

  const handleAddClick = () => {
    setInputList([...inputList, {
      field_label: '',
      field_name: '',
      field_type: 'text',
      order: inputList.length+1,
      default: '',
      placeholder:"",
      url:"",
      show_field:false,
      truefalse_text: "",
      extra_details:{},
      validation: {
        required: false,
        condition_chack: false,
        min: '',
        max: '',
        cond_logic: [
        ]
      }
    }]);
  }

  const handleRemoveClick = (field_id, index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  }

  const openCollpase = (x, i) => {
    setOpen(i)
    if (i === open) { setOpen(undefined) }
  }

  let requireMessage = '';
  const handleErrorMessage = (i) => {
     const list = [...inputList]
     if(list[i].field_label === ''){
      requireMessage = 'Field label is required.'
     }
  }
  const getURlList  = () => {
    props.getViewList().then(({data}) => {
      setURLList(data)
    })
  }
  useEffect(() => {
    setInputList(allStructure)
    setTempInputList(allStructure)
  }, [allStructure])

  useEffect(() => {
    savedData(inputList)
  }, [inputList])

  useEffect(() => {
    console.log('this is running')
    getURlList()
  }, [])
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setInputList((items) => arrayMove(items, oldIndex, newIndex));
  };

  return (
    <DragableTableBody onSortEnd={onSortEnd} useDragHandle>
      {
        loader ? 
        <TableRow>
          <TableCell align="center" width="100%" colSpan={12}>
          <Box>
            <CircularProgress disableShrink />
          </Box>
          </TableCell>
        </TableRow>
        :
        inputList.map((field, i) => {
          return (
            <DragableTableRow key={i} index={i}>
              <CollapseTableVisibleHeader 
                open={open}
                index={i}
                key={i}
                item={field}
                onDelete={() => handleRemoveClick(field._id, i)}
                openCollpase={() => openCollpase(field, i)}
                dialog={true}
              />
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                  <Collapse in={open === i} timeout="auto" unmountOnExit>
                    <Box className='collapseInnerFormWrap'>
                      {/* field lable */}
                      <Box className='innerFormContainer'>
                        <Box className='innerFormItem'>
                          <label>Field Label</label>
                          <span>This is the name which will appear on the EDIT page</span>
                        </Box>
                        <Box className='innerFormItem'>
                          <input
                            type="text"
                            name="field_label"
                            value={field.field_label}
                            onChange={e => handleInputChange(e, i)}
                            onBlur={handleErrorMessage(i)}
                          />
                          <Box style={{color: '#f04d56',}}>
                            {errorMsg ? requireMessage : null}
                          </Box>
                        </Box>
                      </Box>
                      {/* field name */}
                      <Box className='innerFormContainer'>
                        <Box className='innerFormItem'>
                          <label>Field Name</label>
                          <span>Single word, no spaces. Underscores and dashes allowed</span>
                        </Box>
                        <Box className='innerFormItem'>
                          <input
                            type="text"
                            name="field_name"
                            value={field.field_name}
                            onChange={e => handleInputChange(e, i)}
                          />
                        </Box>
                      </Box>
                      {/* Field Type */}
                      <Box className='innerFormContainer'>
                        <Box className='innerFormItem'>
                          <label>Field Type</label>
                        </Box>
                        <Box className='innerFormItem'>
                          <select
                            name="field_type"
                            value={field.field_type}
                            onChange={e => handleInputChange(e, i)}
                          >
                            {
                              fieldType.map((item, k)=>{
                                if(item.value !== "field-group"){
                                  return(
                                    <option key={k} value={item.value}>{item.name}</option>
                                  ) 
                                }
                                return <option key={k} value={item.value} disabled={true}>{item.name}</option>
                              })
                            }
                          </select>
                        </Box>
                      </Box>
                      {
                        field.field_type == "bracket-replace" &&
                        <Box className='innerFormContainer'>
                          <Box className='innerFormItem'>
                            <label>Replace Bracket</label>
                          </Box>
                          <Box className='innerFormItem'>
                            <select
                              name="bracket_replace"
                              value={field.extra_details? field.extra_details.field_name:null}
                              onChange={e => handleInputChange(e, i)}
                            >
                              {
                                field.extra_details && field.extra_details.all_fields && field.extra_details.all_fields.map((name, k)=>{
                                  if(name !== ""){
                                    return(
                                      <option key={k} value={name}>{name}</option>
                                    ) 
                                  }
                                  return <option key={k} value={name} disabled={true}>{name}</option>
                                })
                              }
                            </select>
                          </Box>
                        </Box>
                      }
                      <Box className='innerFormContainer'>
                        <Box className='innerFormItem'>
                          <label>Show field in list view?</label>
                        </Box>
                        <Box className='innerFormItem'>
                          <Box className="required-checkbox">
                            <input
                              type="checkbox"
                              name='show_field'
                              checked={field.show_field ? field.show_field : false}
                              value={field.show_field}
                              onChange={e => handleInputChange(e, i)}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {/* Choice Textarea */}
                      {
                        field.field_type === 'checkbox' || field.field_type === 'radio' || field.field_type === 'dropdown' || field.field_type === 'multiple-select' ?
                          <CheckBoxChoice 
                            value={field.extra_details && field.extra_details.option_choice? field.extra_details.option_choice: ""}
                            onChange={e => handleInputChange(e, i)}
                          />
                        : null
                      }
                      {
                        field.field_type === 'checkbox' || field.field_type === 'radio' || field.field_type === 'dropdown' || field.field_type === 'multiple-select'?
                        <Box className='innerFormContainer'>
                          <Box className='innerFormItem'>
                            <label>Select URL</label>
                          </Box>
                          <Box className='innerFormItem'>
                            <select
                              name="url"
                              value={field.url}
                              onChange={e => handleInputChange(e, i)}
                            >
                              <option value="">select url</option>
                              {
                                URLList.map((item, k)=>{
                                  return <option key={k} value={item._id}>{item.name}</option>
                                })
                              }
                            </select>
                          </Box>
                        </Box>:null
                      }
                      {/* Required */}
                      <Box className='innerFormContainer'>
                        <Box className='innerFormItem'>
                          <label>Required?</label>
                        </Box>
                        <Box className='innerFormItem'>
                          <Box className="required-checkbox">
                            <input
                              type="checkbox"
                              name='required'
                              checked={field.validation.required ? field.validation.required : false}
                              value={field.validation.required}
                              onChange={e => handleInputChange(e, i)}
                            />
                          </Box>
                        </Box>
                      </Box>
                      { // True False Text Message
                        field.field_type === 'boolean' &&
                        <TrueFalseTextMessage
                          value={field.truefalse_text}
                          onChange={e => handleInputChange(e, i)}
                        />
                      }
                      { /* Placeholder Text */
                        field.field_type === 'text' || field.field_type === 'text-area' || field.field_type === 'number' || field.field_type === 'email' || field.field_type === 'phone' || field.field_type === 'url' ?
                        <Placeholder
                          value={field.placeholder}
                          onChange={e => handleInputChange(e, i)}
                        />
                        : null
                      }
                      { /* Default Value */
                        field.field_type === 'text' || field.field_type === 'text-area' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'address' || field.field_type === 'phone'|| field.field_type === 'url' ?
                        <DefaultValue 
                          value={field.default}
                          onChange={e => handleInputChange(e, i)}
                        />
                        : null
                      }
                        {/* min max limit */}
                      {
                        field.field_type === 'number' || field.field_type === 'text' ?
                        <MinMaxField 
                          maxValue={field.validation.max}
                          minValue={field.validation.min}
                          index={i}
                          onChange={onChangeMinMax}
                        />
                        : null
                      }
                      {/* Conditional Logic */}
                      <Box className='innerFormContainer'>
                        <Box className='innerFormItem'>
                          <label>Conditional Logic</label>
                        </Box>
                        <Box className='innerFormItem'>
                          <Box className="required-checkbox">
                            <input
                              type="checkbox"
                              name='condition_chack'
                              checked={field.validation.condition_chack ? field.validation.condition_chack : false}
                              value={field.validation.condition_chack}
                              onChange={e => handleInputChange(e, i)}
                            />
                          </Box>
                       
                          {
                          field.validation.condition_chack ?
                          <Box className="conditionMain">
                          {
                            field.validation.cond_logic.map((logic, index)=>{
                              return(
                                <CondLogicField
                                  key={index}
                                  logic={logic} 
                                  fields={inputList}
                                  parentIndex={i}
                                  index={index}
                                  onChangeInput={onChangeInput}
                                  onAdd={()=> handleAddCondLogic('and', i)}
                                  onRemove={handleRemoveCondLogin}
                                />
                              )
                            })
                          }
                            <Box>or</Box>
                            <Button variant="outlined" className="borderBtn greebBtn" size='small' onClick={()=> handleAddCondLogic('or', i)}>
                                Add rule group
                            </Button>
                          </Box>
                          : null
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </DragableTableRow>
          )
        })
      }
      <TableRow style={{ padding: 10 }}>
        <TableCell align="right" width="100%" colSpan={12} style={{ borderBottom: 0 }}>
          <Button variant="contained" className="customBtn greebBtn" size='medium' onClick={handleAddClick}>
            + Add Field
          </Button>
        </TableCell>
      </TableRow>
      <NotifierSnack />
    </DragableTableBody>
  );
}


export default connect(null, {getViewList})(GroupCollapse)