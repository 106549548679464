import React from 'react';
import { 
  Grid,
  Paper,
  Box,
} from '@material-ui/core';
import useStyles from '../Global/useStyles'
import '../../scss/_form.scss'


const Form = props => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {
            props.formHeading &&
            <Box className="formHeadingWrap">
              <h2>{props.formHeading}</h2>
              {
                props.formSubheading &&
                <span className="formSubheading">{props.formSubheading}</span>
              }
            </Box>
          }
          <form 
            className={`${classes.formRoot} ${props.className ? props.className : ''}`}
            autoComplete="off"
            onSubmit={props.onSubmit}
          >
            {props.children}
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form