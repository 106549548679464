import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DeactivateFormDialog = (props) => {
  const {item} = props
  console.log('props',props.openDelete)

  useEffect(() => {
    // console.log(props, 'props../')
  }, [])
  
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        onClose={() => props.handleModal(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span color='red'>Do you really want to Delete: {item && item.name}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleModal(false)} color="default">
            Cancel
          </Button>
          <Button onClick={() => props.deleteForm()} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeactivateFormDialog
