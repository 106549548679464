import { GETALLUSER, LOGIN, LOGOUT, CHECKTOKEN } from "../actions/type"

let INTIAL_STATE={
  users:[],
  isLogin:false,
  user:{},
}

export default (state = INTIAL_STATE, action) =>{
  switch(action.type){
    case GETALLUSER:
      return {...state, users:action.payload}

    case LOGIN:
      return {...state, isLogin:true}

      case CHECKTOKEN:
      return {...state, isLogin:action.payload}

    case LOGOUT:
      return {...state, isLogin:false} 
    
      default:
      return state
  }
}