import React from 'react'
import {Link} from 'react-router-dom'
import { 
  Tooltip,
  Fab,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BeneficiariesTypeTable from './BeneficiariesTypeTable'

const BeneficiariesType = props => {
  return (
    <React.Fragment>
      <BeneficiariesTypeTable data={props.data} />
      <Tooltip title='Add beneficiary type' aria-label='add-beneficiary-type' arrow style={{position: 'absolute', bottom: 25, right: 25}}>
        <Link to='/beneficiaries/add'>
          <Fab className="circleButton greebBtn">
            <PersonAddIcon />
          </Fab>
        </Link>
      </Tooltip>
    </React.Fragment>
  )
}

export default BeneficiariesType
