import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Box,
} from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import DeckIcon from '@material-ui/icons/Deck';
import SettingsIcon from '@material-ui/icons/Settings';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import ListAltIcon from '@material-ui/icons/ListAlt';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { NavLink } from 'react-router-dom'

const DrawerListItems = props => {
  const [subMenu, setSubMenu] = useState(false)
  const [userSubMenu, setUserSubMenu] = useState(false)
  const [formSubMenu, setFormSubMenu] = useState(false)
  const [user, setUser] = useState({})
  const [allowedRoles, setAllowedRoles] = useState(['program-manager', 'admin'])


  const getUser = async () => {
    let currentUser = JSON.parse(await localStorage.getItem('cws_user'))
    await setUser(currentUser)
    console.log(currentUser, 'currentUser')
  }

  useEffect(() => {
    getUser()
  }, [])
  return (
    <React.Fragment>
      <ListItem button>
        <NavLink to='/dashboard'>
          <ListItemIcon title="Dashboard">
            <DashboardIcon className='icon' />
          </ListItemIcon>
          <ListItemText primary="Dashboard" className="text" />
        </NavLink>
      </ListItem>
      {/* <ListItem button>
      <NavLink to='/beneficiaries'>
        <ListItemIcon title="Beneficiaries">
          <SupervisedUserCircleIcon className='icon'/>
        </ListItemIcon>
        <ListItemText primary="Beneficiaries" className="text" />
      </NavLink>
    </ListItem> */}
      <ListItem button className={subMenu ? 'open' : null}>
        <Box component="span" className="dropMenuMain">
          <NavLink to="/program">
            <ListItemIcon title="Programs">
              <DeckIcon className='icon' />
            </ListItemIcon>
            <ListItemText primary="Programs" className="text" />
          </NavLink>
          <Box
            component="span"
            className="dropArrow"
            onClick={() => setSubMenu(!subMenu)}
          >
            <KeyboardArrowDownIcon />
          </Box>
        </Box>
        <List>
          <ListItem button>
            <NavLink to='/program/active'>
              <ListItemIcon title="All Active Programs">
                {/* <DashboardIcon className='icon'/> */}
              </ListItemIcon>
              <ListItemText primary="All Active Programs" className="text" />
            </NavLink>
          </ListItem>
          <ListItem button>
            <NavLink to='/program/add'>
              <ListItemIcon title="All Active Programs">
              </ListItemIcon>
              <ListItemText primary="Add New Program" className="text" />
            </NavLink>
          </ListItem>
          <ListItem button>
            <NavLink to='/program/deactive'>
              <ListItemIcon title="Deactivated Programs">
              </ListItemIcon>
              <ListItemText primary="Deactivated Programs" className="text" />
            </NavLink>
          </ListItem>
        </List>
      </ListItem>

      <ListItem button className={userSubMenu ? 'open' : null}>
        <Box component="span" className="dropMenuMain">
          <NavLink to="/user" >
            <ListItemIcon title="Users & Permissions">
              <PeopleIcon className='icon' />
            </ListItemIcon>
            <ListItemText primary="Users & Permissions" className="text" />
          </NavLink>
          <Box
            component="span"
            className="dropArrow"
            onClick={() => setUserSubMenu(!userSubMenu)}
          >
            <KeyboardArrowDownIcon />
          </Box>
        </Box>
        <List>
          <ListItem button>
            <NavLink to='/user'>
              <ListItemIcon title="Users">
              </ListItemIcon>
              <ListItemText primary="Users" className="text" />
            </NavLink>
          </ListItem>
          <ListItem button>
            <NavLink to='/role'>
              <ListItemIcon title="Roles">
              </ListItemIcon>
              <ListItemText primary="Roles" className="text" />
            </NavLink>
          </ListItem>
          <ListItem button>
            <NavLink to='/designation'>
              <ListItemIcon title="Designation">
              </ListItemIcon>
              <ListItemText primary="Designation" className="text" />
            </NavLink>
          </ListItem>
        </List>
      </ListItem>

      {/* <ListItem button>
      <NavLink to='/user'>
        <ListItemIcon title="Users">
          <PeopleIcon className='icon' />
        </ListItemIcon>
        <ListItemText primary="Users" className="text" />
      </NavLink>
    </ListItem>
    <ListItem button>
      <NavLink to='/role'>
        <ListItemIcon title="Roles">
          <PeopleIcon className='icon' />
        </ListItemIcon>
        <ListItemText primary="Roles" className="text" />
      </NavLink>
    </ListItem>
    <ListItem button>
      <NavLink to='/designation'>
        <ListItemIcon title="Designation">
          <PeopleIcon className='icon' />
        </ListItemIcon>
        <ListItemText primary="Designation" className="text" />
      </NavLink>  
    </ListItem> */}
      <ListItem button className={formSubMenu ? 'open' : null}>
        <Box component="span" className="dropMenuMain">
          <NavLink to="/custom-form">
            <ListItemIcon title="Forms">
              <ListAltIcon className='icon' />
            </ListItemIcon>
            <ListItemText primary="Forms" className="text" />
          </NavLink>
          <Box
            component="span"
            className="dropArrow"
            onClick={() => setFormSubMenu(!formSubMenu)}
          >
            <KeyboardArrowDownIcon />
          </Box>
        </Box>
        <List>
          <ListItem button>
            <NavLink to="/custom-form">
              <ListItemIcon title="Custom Form">
              </ListItemIcon>
              <ListItemText primary="Custom Form" className="text" />
            </NavLink>
          </ListItem>
          {/* <ListItem button>
          <NavLink to='/custom-form/form-revisions'>
            <ListItemIcon title="Form Revisions">
            </ListItemIcon>
            <ListItemText primary="Form Revisions" className="text" />
          </NavLink>
        </ListItem> */}
          <ListItem button>
            <NavLink to='/custom-form/view-list'>
              <ListItemIcon title="View List">
              </ListItemIcon>
              <ListItemText primary="View List" className="text" />
            </NavLink>
          </ListItem>
        </List>
      </ListItem>
      {/* <ListItem button>
      <NavLink to ="/coutom-form">
        <ListItemIcon title="Custom Form">
          <ListAltIcon className='icon' />
        </ListItemIcon>
        <ListItemText primary="Custom Form" className="text" />
      </NavLink>
    </ListItem> */}

      {/* <ListItem button>
      <NavLink to='/events'>
        <ListItemIcon title="Events">
          <EventIcon className='icon'/>
        </ListItemIcon>
        <ListItemText primary="Events" className="text" />
      </NavLink>
    </ListItem> */}
      <ListItem button>
        <NavLink to="/settings">
          <ListItemIcon title="Settings">
            <SettingsIcon className='icon' />
          </ListItemIcon>
          <ListItemText primary="Settings" className="text" />
        </NavLink>
      </ListItem>
      {user && user.role && allowedRoles.includes(user.role.role_slug) &&
        <ListItem button>
          <NavLink to='/data-explorer'>
            <ListItemIcon title="Data Explorer">
              <DashboardIcon className='icon' />
            </ListItemIcon>
            <ListItemText primary="Data Explorer" className="text" />
          </NavLink>
        </ListItem>
      }
      {/* <ListItem button>
        <NavLink to="/support">
          <ListItemIcon title="Support">
            <PermPhoneMsgIcon className='icon' />
          </ListItemIcon>
          <ListItemText primary="Support" className="text" />
        </NavLink>
      </ListItem> */}

    </React.Fragment>
  )
};

export default DrawerListItems