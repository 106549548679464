import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Box,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import CustomFormTableRow from './CustomFormTableRow'
import DeactivateFormDialog from './DeactivateFormDialog'
import { connect } from 'react-redux'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { deactivateForm, deleteForm } from '../../../store/actions/form'
import DeleteDialogForm from './DeleteDialogForm'

import '../../../scss/_table.scss'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    backgroundColor: 'white'
  },
}))(TableCell);

const CustomFormTable = (props) => {
  const [sortedDate, setSortedDate] = useState(true)
  const [openDeactivate, setOpenDeactivate] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [formData, setFormData] = useState([])
  const [searchData, setSearchData] = useState('')

  const handleDeactivateModal = (value) => {
    setOpenDeactivate(value)
  }
  const handleClickDelete = (item) => {
    console.log('this is working')
    setOpenDelete(true)
    setFormData(item)
  }
  const handleClickOpen = (item) => {
    setOpenDeactivate(true);
    setFormData(item)
  };

  const handleDeleteModal = (value) => {
    setOpenDelete(value)
  }

  const deactivateForm = (item) => {
    if (item.hasOwnProperty("_id")) {
      props.deactivateForm(item._id).then(res => {
        setOpenDeactivate(false)
        props.deactivateForms(item)
      }).catch(err => {
        console.log("err", err)
      })
    } else {
      setOpenDeactivate(false)
    }
  }

  const deleteForm = (item) => {
    if (item.hasOwnProperty("_id")) {
      props.deleteForm(item._id).then(res => {
        setOpenDelete(false)
        props.deleteFormTheList(item)
      }).catch(err => {
        console.log("err", err)
      })
    } else {
      setOpenDeactivate(false)
    }
  }

  useEffect(() => {
    setSearchData(props.data)
  }, [props.data])

  const filterData = event => {
    const updateList = props.data.filter(item => {
      const name = item.name.toLowerCase()
      return (
        name.search(event.target.value.toLowerCase()) !== -1
      )
    })
    setSearchData(updateList.length > 0 && updateList)
  }
  const sortingForm = (tempData, type, status) => {
    console.log('sortinggggggg')
    return tempData.sort((a, b) => {
      console.log('b[type].slice(0,10).split("/").reverse().join("-")', b[type].slice(0, 10).split("/").reverse().join("-"))
      if (status)
        return new Date(b[type].slice(0, 10).split("/").reverse().join("-")) - new Date(a[type].slice(0, 10).split("/").reverse().join("-"))
      return new Date(a[type].slice(0, 10).split("/").reverse().join("-")) - new Date(b[type].slice(0, 10).split("/").reverse().join("-"))
    })
  }
  const sortedForms = (type, status) => {
    setSearchData(sortingForm(searchData, type, status))
    setSortedDate(!status)
  }
  console.log('opendelete', openDelete)
  return (
    <React.Fragment>
      <Box className="customeFormTableMain">
        <Box className="searchBoxTable" style={{ marginBottom: 0 }}>
          <IconButton className="iconButton" type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
          <input
            autoComplete="off"
            type="text"
            name="searchData"
            placeholder="Search by Form Name"
            className="serachInput"
            onChange={filterData}
          />
        </Box>
        <TableContainer>
          <Table aria-label="user table" size='small' className="form_tableMain">
            <TableHead>
              <TableRow>
                <StyledTableCell>Form Name</StyledTableCell>
                <StyledTableCell>Program</StyledTableCell>
                <StyledTableCell>Current Version</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>
                  Last Updated
                  {
                    sortedDate ?
                      <ArrowUpwardIcon onClick={() => sortedForms("updated_at", sortedDate)} size="small" />
                      :
                      <ArrowDownwardIcon onClick={() => sortedForms("updated_at", sortedDate)} size="small" />
                  }
                </StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            {
              props.loader ?
                <TableBody>
                  <TableRow style={{ backgroundColor: '#fff' }}>
                    <TableCell align="center" width="100%" colSpan={12}>
                      <Box>
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
                :
                <TableBody>
                  {searchData &&
                    searchData ?
                    searchData.map((form, i) => (
                      <React.Fragment key={i}>
                        <CustomFormTableRow
                          index={i}
                          onClick={() => props.history.push(`/custom-form/edit/${form._id}`)}
                          form={form}
                          onDelete={handleClickDelete}
                          onDeactivate={handleClickOpen}
                          columns={props.columns}
                        />
                      </React.Fragment>
                    ))
                    :
                    <TableRow style={{ backgroundColor: '#fff' }}>
                      <TableCell align="center" width="100%" colSpan={12}>
                        <Box style={{ color: '#bbb6b6', padding: 15 }}>
                          No form found with this name
                        </Box>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
            }
            <TableFooter>
              <TableRow>
                <TableCell colSpan={12} style={{ backgroundColor: '#fff', padding: 12 }}>
                  {/* <Box style={{padding: 15}}>{}</Box> */}
                </TableCell>

              </TableRow>
            </TableFooter>
          </Table>
          <DeactivateFormDialog
            handleModal={(value) => handleDeactivateModal(value)}
            open={openDeactivate}
            item={formData}
            deactivateForm={() => deactivateForm(formData)}
          />
          <DeleteDialogForm
            handleModal={(value) => handleDeleteModal(value)}
            open={openDelete}
            item={formData}
            deleteForm={() => deleteForm(formData)}
          />
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}

export default connect(null, { deactivateForm, deleteForm })(withRouter(CustomFormTable))