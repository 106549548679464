import React,{useState, useEffect} from 'react'
import { 
  TextField,
  MenuItem,
  List,
  ListItem,
  Button,
  FormControlLabel,
  Box,
  Radio,
  RadioGroup,
 } from '@material-ui/core';
 import Wrapper from '../../components/Global/Wraper'
 import Form from '../../components/Form/form'
 import {history} from '../../routes/history'
 import BackToLink from '../../components/common/BackToLink'
 import MultiSelectChip from '../../components/common/MultiSelectChip'

const AddEventType = () => {
  const[form, setForm] = useState({
    name: '',
    plural_name: '',
    pre_event_forms: '',
    post_event_forms: '',
    has_checklist: false,
    has_attendies: true,
    check_list_items: [],
    mandatory_fields:[]
  })
  const [mandatory, setMandatory] = useState({
    dates: false,
    team_members: false,
    location: false,
  })
  
  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setForm(prev => ({
      ...prev,
      [name]:value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let fieldsData = []
    fieldsData.push(mandatory)
    form.fields = fieldsData
    // form.optional = optional
  }

  const preEventForm = [
    {
      value: 'form-1',
      label: 'Form 1'
    },
    {
      value: 'form-2',
      label: 'Form 2'
    },
    {
      value: 'form-3',
      label: 'Form 3'
    },
  ]

  const postEventForm = [
    {
      value: 'form-1',
      label: 'Form 1'
    },
    {
      value: 'form-2',
      label: 'Form 2'
    },
    {
      value: 'form-3',
      label: 'Form 3'
    },
  ]

  const attendanceData = [
    {
      value: 'form-1',
      label: 'Form 1'
    },
    {
      value: 'form-2',
      label: 'Form 2'
    },
    {
      value: 'form-3',
      label: 'Form 3'
    },
  ]

  const checklistItems = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

  return (
    <Wrapper 
        pageTitle="Add Evevnt type" 
        maxWidth="sm"
        backLink={history.location.pathname.split('/')[1]}
      >
        <BackToLink 
          link="/events"
          label="Back to all Events"
          style={{marginLeft: -8}}
        />
        <Form 
          onSubmit={handleSubmit} 
          formHeading="Add events type"
          className="beneficiaryTypeForm"
        >
          <Box className="formControl">
            <TextField 
              type='text'
              label="Name"
              required
              variant="outlined"
              name='name'
              value={form.name}
              onChange={handleChange}
            />
          </Box>
          <Box className="formControl">
            <TextField 
              type='text'
              label="Plural Name"
              required
              variant="outlined"
              name='plural_name'
              value={form.plural_name}
              onChange={handleChange}
            />
          </Box>
          <Box className="optionalField">
            <Box className="flexRow">
              <Box className="formControlHeading">
                <h3>Mandatory Fields:</h3>
              </Box>
              <List className="rightFlex">
                <ListItem className="formControl">Dates</ListItem>
                <ListItem className="formControl">Team Members</ListItem>
                <ListItem className="formControl" >Location</ListItem>
              </List>
            </Box>
            <Box className="flexRow">
              <Box className="formControlHeading"><h3>Has Checklist:</h3></Box>
              <RadioGroup aria-label="checklist" name="has_checklist" value={form.has_checklist} onChange={handleChange}className="rightFlex">
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box>
          {
            form.has_checklist === 'yes' &&
            <MultiSelectChip 
              list={checklistItems}
              name='check_list_items'
              value={form.check_list_items}
              handleChange={handleChange}
              label='Checklist Items'
              className="customMultiSelect"
            />
          }
          <Box className="formControl">
            <TextField
              className="rightFlex"
              id="pre-event-form"
              select
              label="Pre Event Form"
              name="pre_event_form"
              value={form.pre_event_form}
              onChange={handleChange}
              variant="outlined"
            >
              {
                preEventForm.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
                ))
              }
            </TextField>
          </Box>
          <Box className="formControl">
            <TextField
              className="rightFlex"
              id="post-event-form"
              select
              label="Post Event Form"
              name="post_event_form"
              value={form.post_event_form}
              onChange={handleChange}
              variant="outlined"
            >
              {
                postEventForm.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
                ))
              }
            </TextField>
          </Box>
          <Box className="optionalField">
            <Box className="flexRow">
              <Box className="formControlHeading"><h3>Has Attendance:</h3></Box>
              <RadioGroup aria-label="attendance" name="has_attendance" value={form.has_attendance} onChange={handleChange}className="rightFlex">
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
          </Box>
          {
            form.has_attendance === 'yes' &&
            <Box className="formControl">
            <TextField
              className="rightFlex"
              id="attendance"
              select
              label="Attendance"
              name="attendance"
              value={form.attendance}
              onChange={handleChange}
              variant="outlined"
            >
              {
                attendanceData.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
                ))
              }
            </TextField>
          </Box>
          }
          <Box style={{paddingTop: 10, paddingBottom: 10}}>
            <Button
              type="submit"
              variant="contained"
              className="customBtn greebBtn"
              // className={classes.submit}
              onClick={handleSubmit}
            >
              Save
            </Button>
            </Box>
        </Form>
      </Wrapper>
  )
}

export default AddEventType
