import React, {useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {getAllRevisionForms, restoreForm} from './../../store/actions/form'
import {connect} from 'react-redux'
import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Box,
  Avatar,
  Button
 } from '@material-ui/core'
import Wrapper from '../../components/Global/Wraper'
import {history} from '../../routes/history'
import '../../scss/_table.scss'
import ReStoreDialog from './RestoreDialog';

const FormRevisions = props => {
  const [open, setOpen] = useState(false)
  const [form, setForm] = useState(null)
  const {id} = props.match.params
  const [loader, setLoader] = useState(false)
  const [revisionForms, setRevisionForms] = useState([])


  const getAllRevisionForm = () => {
    setLoader(true)
    props.getAllRevisionForms(id).then(({data}) =>{
      setRevisionForms(data)
      setLoader(false)
    }).catch(err => {
      setLoader(false)
      console.log('err',err)
    })
  }
  useEffect(() => {
    getAllRevisionForm()
  },[])
  const date = value => {
    let d = new Date(value)
    return d.getDate()+"/"+d.getMonth()+"/"+d.getFullYear()+" "+d.toLocaleString([], { hour: 'numeric', minute: '2-digit',hour12:true})
  }

  const handleRestore = (form) => {
    console.log('form', form)
    props.restoreForm(form).then(({data}) => {
      console.log("data", data)
      setOpen(false)
      history.push('/custom-form')
    }).catch(err => {
      console.log("err", err)
    })
  }
  const handleOpenDialog = (item) => {
    setForm(item)
    setOpen(true)
  };

  return (
    <React.Fragment>
      <Wrapper pageTitle="Form Revisions">
        <Box className="formRevisionsMain">
          <Box className="tableTopContentMain">
            <Box className="pageTableTitle">
              <h2>All Forms: {revisionForms && revisionForms.length>0 && revisionForms[0].name}</h2>
            </Box>
            <Box className="tableTopActionIcons">
              <Button className="gobackButton" onClick={() => history.goBack()}>
                Go Back
              </Button>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="form revision table" size="medium" className="form_tableMain">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>       
                  <StyledTableCell>Version Number</StyledTableCell>       
                  <StyledTableCell>Created By</StyledTableCell>       
                  <StyledTableCell>Number of Fields</StyledTableCell>       
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              {
                loader ? 
                <TableBody>
                  <TableRow style={{backgroundColor: '#fff'}}>
                    <TableCell align="center" width="100%" colSpan={12}>
                    <Box>
                      <CircularProgress />
                    </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
                :
                <TableBody style={{backgroundColor: '#fff'}}>
                  {
                    revisionForms.map((item, i) => (
                    <TableRow hover key={i}>
                      <TableCell>
                        <span>{date(item.created_at)}</span>
                      </TableCell>
                      <TableCell>
                        <span>{item.version}</span>
                      </TableCell>
                      <TableCell>
                        <Box className="userNameWithImage">
                          <span className="userImage">
                            <Avatar src="/image/user5.png" alt={item.created_by && item.created_by.first_name} />
                          </span>
                          <span className="userName">
                            {item.created_by && item.created_by.first_name} {item.created_by &&  item.created_by.last_name}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <span>
                          {
                            item && item.structures ? item.structures.length : 0
                          }
                        </span>
                      </TableCell>
                      <TableCell>
                        <span  className={`greenBtn statusBtn`} onClick={() => {handleOpenDialog(item)}}>
                          Restore
                        </span>
                      </TableCell>
                    </TableRow>
                    ))
                  }
                  
                </TableBody>
              }
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={12} style={{backgroundColor: '#fff', padding: 12}}>
                    {/* <Box style={{padding: 15}}>{}</Box> */}
                  </TableCell>
                
                </TableRow>
              </TableFooter>
            </Table>
            
          </TableContainer>
        </Box>
      </Wrapper>
      <ReStoreDialog 
        open={open}
        setOpen={setOpen}
        form={form}
        handleRestore={handleRestore}
      />
    </React.Fragment>
  )
}

export default connect(null, {getAllRevisionForms, restoreForm})(FormRevisions)

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    backgroundColor: 'white'
  },
}))(TableCell);
