import React, { useState, useEffect } from 'react'
import {
  Box,
  Tooltip,
  CardMedia,
  Divider,
} from '@material-ui/core';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SyncIcon from '@material-ui/icons/Sync';
import LockIcon from '@material-ui/icons/Lock';
import { Link } from 'react-router-dom'
import CreateIcon from '@material-ui/icons/Create';
import Modules from './Modules'
import GoalRange from './GoalRange'
import { getAllGoal, createGoal, editGoal, getFullGoals, deleteGoal, getGaolTarget } from './../../../store/actions/goal'
import { syncSingleProgram, compactDatabaseOfCouch, createUserToCouchDB, lockProgram } from '../../../store/actions/program'
import { connect } from 'react-redux'
import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import { getAllUser } from '../../../store/actions/user'
import { getSingleProgramModule, getSingleProgramFull } from '../../../store/actions/program'
import { auth_url } from '../../../store/mainaxios'
import _ from 'lodash'
import { history } from '../../../routes/history'
// import Teams from './Teams'

import '../../../scss/_programOverview.scss';


const ProgramOverview = props => {
  const [team, setTeam] = useState([])
  const [fullProgram, setFullProgram] = useState(null)
  const [forms, setForms] = useState([])
  const [goals, setGoals] = useState([])
  const [fullGoals, setFullGoals] = useState([])
  const [lockStatus, setLockStatus] = useState('')
  const { data } = props
  const goalsRange = [
    {
      title: 'Workshops',
      name: 'workshop',
      minValue: 0,
      maxValue: 245,
      value: 0
    },
    {
      title: 'Pre workshop survey forms',
      name: "pre_workshop_survey_forms",
      minValue: 0,
      maxValue: 210,
      value: 0
    },
  ]
  const SyncProgramToCouch = () => {
    props.syncSingleProgram(props.program._id).then(res => {
      openSnackbar({ message: 'Sync To Database Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Sync to Database', status: 'error' });
    })
  }
  const loadTeams = (all_users, tempProgram) => {
    let rest_teams = all_users.filter(user => {
      if (tempProgram.teams.indexOf(user._id) > -1) {
        return true
      }
      return false
    }).map(user => { user.is_program_head = false; return user })
    let program_head = all_users.filter(user => {
      if (tempProgram.program_head.indexOf(user._id) > -1) {
        return true
      }
      return false
    }).map(user => { user.is_program_head = true; return user })
    let tempTeam = [...program_head, ...rest_teams]
    setTeam(tempTeam)
  }
  const lockProgram = () => {
    props.lockProgram(props.program._id).then(res => {
      if (res.data.lockProgram == true) {
        setLockStatus('Unlock')
        openSnackbar({ message: `Program Locked`, status: 'success' });
      }
      if (res.data.lockProgram == false) {
        setLockStatus('Lock')
        openSnackbar({ message: `Program Unlocked`, status: 'success' });
      }
      props.syncSingleProgram(props.program._id).then(res => {
      }).catch(err => {
        console.log("err", err, err.response)
      })
    })
  }
  const getAllUsers = (tempProgram) => {
    props.getAllUser().then(({ data }) => {
      loadTeams(data, tempProgram)
    })
  }
  const getSingleProgram = () => {
    props.getSingleProgramModule(props.program._id).then(({ data }) => {
      getAllUsers(data)
    })
  }
  const compactDatabase = () => {
    props.compactDatabaseOfCouch(props.program.name_slug).then(res => {
      openSnackbar({ message: `Compact ${props.program.name} Successfully`, status: 'success' });
    }).catch(err => {
      openSnackbar({ message: 'Error in compact to database', status: 'error' });
    })
  }
  const createUserToCouchDB = () => {
    props.createUserToCouchDB(props.program._id).then(res => {
      openSnackbar({ message: `user create for ${props.program.name} Successfully`, status: 'success' });
    }).catch(err => {
      openSnackbar({ message: 'Error in user creation to database', status: 'error' });
    })
  }
  const getFullGoals = async (goals, fullTempPogram) => {
    let tempGoals = _.cloneDeep(goals)
    if (props.program && fullTempPogram) {
      for (let i = 0; i < goals.length; i++) {
        let index = props.program.beneficiary_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, beneficiary_type_id: goals[i].goal_type, type: "BENEFICIARY" })
          tempGoals[i].goal_type = fullTempPogram.beneficiary_types[index]
          tempGoals[i].document_type = "BENEFICIARY"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.location_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, location_type_id: goals[i].goal_type, type: "LOCATION" })
          tempGoals[i].goal_type = fullTempPogram.location_types[index]
          tempGoals[i].document_type = "LOCATION"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.event_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, event_type_id: goals[i].goal_type, type: "EVENT" })
          tempGoals[i].goal_type = fullTempPogram.event_types[index]
          tempGoals[i].document_type = "EVENT"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.survey_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, survey_type_id: goals[i].goal_type, type: "SURVEY" })
          tempGoals[i].goal_type = fullTempPogram.survey_types[index]
          tempGoals[i].document_type = "SURVEY"
          tempGoals[i].achieve = data.achieve
        }
        index = props.program.case_types.indexOf(goals[i].goal_type)
        if (index > -1) {
          let { data } = await props.getGaolTarget({ program_slug: props.program.name_slug, case_type_id: goals[i].goal_type, type: "CASE" })
          tempGoals[i].goal_type = fullTempPogram.case_types[index]
          tempGoals[i].document_type = "CASE"
          tempGoals[i].achieve = data.achieve
        }
      }
    }
    setFullGoals(tempGoals)
  }
  const getAllGoal = (program_id, fullTempPogram) => {
    props.getAllGoal(program_id).then(({ data }) => {
      getFullGoals(data, fullTempPogram)
      setGoals(data)
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  const fullSingleProgram = (_id) => {
    props.getSingleProgramFull(_id).then(({ data }) => {
      getAllGoal(props.program._id, data)
      setFullProgram(data)
      if (data.lockProgram == true) {
        setLockStatus('Unlock')
      }
      if (data.lockProgram == false) {
        setLockStatus('Lock')
      }
    }).catch(err => {
      console.log("err", err)
    })
  }
  const updateForms = () => {

  }
  useEffect(() => {
    fullSingleProgram(props.program._id)
    getSingleProgram()
  }, [])
  return (
    <React.Fragment>
      <NotifierSnack />
      <Box className="overviewMain">
        <Box className="goalWrap">
          <Box className="headingWithLeftIcon">
            <img src="/icon/push-pin.svg" alt="pin" style={{ width: 18 }} />
            <span className="heading">Pinned Goals</span>
          </Box>
          <GoalRange goalsRange={fullGoals.filter(fullGoal => fullGoal.isPinned)} />
          <button className="whiteButton" onClick={() => props.handleLink(1)}>See all Goals</button>
        </Box>
        <Divider />
        <Box className="teamWrap">
          <Box className="headingWithLeftIcon">
            <LockIcon />
            <span className="heading">Lock Program</span>
          </Box>
          <Box className="btnWrap">
            <button className="whiteButton" onClick={() => lockProgram()}>{lockStatus}</button>
            {/* <button style={{marginLeft:20}} className="whiteButton" onClick={()=>compactDatabase()}>Compaction</button>
            <button style={{marginLeft:20}} className="whiteButton" onClick={()=>createUserToCouchDB()}>Create User</button> */}
          </Box>
        </Box>
        <Divider />
        <Box className="teamWrap">
          <Box className="headingWithLeftIcon">
            <SyncIcon />
            <span className="heading">Database</span>
          </Box>
          <Box className="btnWrap">
            <button className="whiteButton" onClick={() => SyncProgramToCouch()}>Sync</button>
            {/* <button style={{marginLeft:20}} className="whiteButton" onClick={()=>compactDatabase()}>Compaction</button>
            <button style={{marginLeft:20}} className="whiteButton" onClick={()=>createUserToCouchDB()}>Create User</button> */}
          </Box>
        </Box>
        <Divider />
        <Modules
          heading="Module"
          updateForms={updateForms}
          buttonLabel="Manage Modules"
          handleLink={props.handleLink}
          linkValue={3}
          data={props.data}
        />
        <Divider />
        {/* <Teams 
          buttonLabel="Manage Team"
        /> */}
        <Box className="teamWrap">
          <Box className="headingWithLeftIcon">
            <AccountCircleOutlinedIcon />
            <span className="heading">Team</span>
          </Box>
          <Box className="teamCardMain">
            {
              team && team.map(member => (
                <Box className="userInfo">
                  <CardMedia
                    className="userImage img40x40 imgCircle"
                    image={member.photo ? auth_url + member.photo : "/image/user5.png"}
                    title={`team`}
                  />
                  <span className="text">
                    <span className="name">{member && member.first_name} {member && member.last_name}</span>
                    <span className="description">{member && member.designation && member.designation.name}</span>
                  </span>
                </Box>
              ))
            }

          </Box>
          <button className="whiteButton" onClick={() => props.handleLink(10)}>Manage Team</button>
        </Box>
        <Divider />
        <Box className="programOverView">
          <Box className="headingWithLeftIcon">
            {/* <SelectAllIcon /> */}
            <ControlCameraIcon />
            <span className="heading">Program Overview</span>
          </Box>
          {/* Row 1 */}
          <Box className="innerContentRow">
            <Box className="innerContent1">
              <span className="text">Program Name</span>
            </Box>
            <Box className="innerContent2">
              <span className="text">{data && data.name}</span>
            </Box>
            <Box className="innerContent3">
              {
                props.can_edit_document &&
                <Tooltip
                  title='Edit Program'
                  aria-label='edit-program'
                  arrow
                  className="tooltipEdit"
                >

                  <Link to={`/program/edit/${data._id}`} >
                    <CreateIcon />
                  </Link>
                </Tooltip>
              }
            </Box>
          </Box>
          {/* Row 2 */}
          <Box className="innerContentRow">
            <Box className="innerContent1">
              <span className="text">Program Heads</span>
            </Box>
            <Box className="innerContent2">
              {data && data.program_head ?
                data.program_head.map((user, i) => {
                  if (user)
                    return (
                      <Box className="userInfo" key={i}>
                        <CardMedia
                          className="userImage img40x40 imgCircle"
                          image={user.photo ? auth_url + user.photo : "/image/user5.png"}
                          title={`${user.first_name} ${user.last_name}`}
                        />
                        <span className="text">
                          <span className="name">
                            {`${user.first_name} ${user.last_name && user.last_name}`}
                          </span>
                        </span>
                      </Box>
                    )
                  else
                    return null
                }) : null}
            </Box>
            <Box className="innerContent3">
              <Tooltip
                title='Edit Program'
                aria-label='edit-program'
                arrow
                className="tooltipEdit"
              >
                <Link to={`/program/edit/${data._id}`} >
                  <CreateIcon />
                </Link>
              </Tooltip>
            </Box>
          </Box>
          {/* Row 3 */}
          <Box className="innerContentRow">
            <Box className="innerContent1">
              <span className="text">Description</span>
            </Box>
            <Box className="innerContent2">
              <span className="text">
                {data.description ? data.description : '------'}
              </span>
            </Box>
          </Box>
          <Box className="btnWrap">
            <button className="whiteButton" onClick={() => history.push(`/program/edit/${data._id}`)}>Program Details</button>
            <button className="whiteButton">Access Program Reports</button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default connect(null, { syncSingleProgram, getSingleProgramFull, getAllGoal, getGaolTarget, getSingleProgramModule, createUserToCouchDB, getAllUser, compactDatabaseOfCouch, lockProgram })(ProgramOverview)
