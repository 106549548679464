import React, { useEffect, useState } from 'react'
import _ from 'lodash'

export default function FieldGroupComponent({ label, fields, values, onChangeValue }) {
  const [forms, setForms] = useState([])
  const [arrayIndex, setArrayIndex] = useState(0)

  const addMoreGroup = (event) => {
    event.preventDefault()
    setArrayIndex(arrayIndex+1)
    setForms([...forms, formDataHandle()])
  }

  const removeGroup = (event) => {
    event.preventDefault()
    let formsTemp = _.cloneDeep(forms)
    setArrayIndex(arrayIndex-1)
    formsTemp.pop()
    setForms(formsTemp)
  }
  
  const onChangeHandler = (event, field_name, index ,j) => {
    let formsTemp = _.cloneDeep(forms)
    let value = event.target.value
    formsTemp[index][j].field_name = field_name
    formsTemp[index][j].value = value 
    setForms(formsTemp)
  }

  const formDataHandle = () => {
    var jsonArray = [];
    if(forms.length>0){
      let coefficient = forms[forms.length-1][0].field_name.split("_")[1]
      fields.forEach(field => {
        jsonArray = [...jsonArray, {field_name: `${field.field_name}_${Number(coefficient)+1}`, value:''}]
      })
    }else{
      fields.forEach(field => {
        jsonArray = [...jsonArray, {field_name: `${field.field_name}_0`, value:''}]
      })
    }
    return jsonArray
  }

  useEffect(() => {
    let jsonArray = formDataHandle()
    setForms([jsonArray])
  }, [])

  useEffect(() => {
    setForms([...values])
    setArrayIndex(values[0].length-1)
  }, [values])
  return (
    <div>
      <div className="form">
        {forms.map((form, index) => {
          return (
          <div key={index}>
            {form.map((item, j) => {
              return (
              <div key={j} style={{ display: 'flex', flexDirection: 'column', margin: 20 }}>
                <label>{fields[j].field_label}</label>
                <input label={fields[j].field_label} name={item.field_name} onChange={(event) => onChangeHandler(event, item.field_name, index, j)} value={item.value} />
              </div>
            )})}
            {arrayIndex > 0 && (
              <div style={{ marginTop: 20 }} />
            )}
          </div>
        )})}
        <div style={{ display: 'flex', flexDirection: 'column', margin: 20 }}>
          {arrayIndex > 0 && (
            <a href="#" onClick={removeGroup} style={{ marginRight: 10, marginBottom: 10 }}>Remove</a>
          )}
          <a href="#" onClick={addMoreGroup}>Add</a>
        </div>
      </div>
    </div>
  )
}
