import React, { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Paper,
  Button,
  Box,
  MenuItem,
  Checkbox,
  Select,
  Input,
  ListItemText,
} from '@material-ui/core'
import Wrapper from '../../components/Global/Wraper'
import useStyles from '../../components/Global/useStyles'
import TableNewForm from '../../components/PageCompnent/CustomForm/TableNewForm'
import SimpleReactValidator from 'simple-react-validator'
import { getSingleForm, getAllRevisionForms, editForm, createForm } from './../../store/actions/form'
import { connect } from 'react-redux'
import { history } from '../../routes/history'
import { getAllProgram } from './../../store/actions/program'
import _ from 'lodash'
import NotifierSnack, { openSnackbar } from '../../components/Global/NotifierSnackbar'
import '../../scss/_form.scss'

const EditFormGroup = (props) => {
  const classes = useStyles()
  const [, forceUpdate] = useState()
  const { id } = props.match.params
  const [colorBorder, setColorBorder] = useState('#a0a0a0')
  const [errorMsg, setErrorMsg] = useState(false)
  const [formData, setFormData] = useState([])
  const [programs, setPrograms] = useState([])
  const [allStructure, setAllStructure] = useState([])
  const [errorFormData, setErrorFormData] = useState([])
  const [tempForm, setTempForm] = useState(null)
  const [revisionForms, setRevisionForms] = useState([])
  const [form, setForm] = useState({
    _id: "",
    name: '',
    parent_id: "",
    program_ids: [],
    structures: [],
  })

  const simpleValidator = useRef(new SimpleReactValidator())

  const onChangeInput = (event) => {
    let name = event.target.name
    let value = event.target.value
    if (value !== '') {
      setColorBorder('#a0a0a0')
    }
    setForm({ ...form, [name]: value })
  }
  const getRevisionForms = () => {
    props.getAllRevisionForms(id).then(({ data }) => {
      setRevisionForms(data)
    }).catch(err => {
      console.log("err", err)
    })
  }
  const getForm = () => {
    props.getSingleForm(id).then(({ data }) => {
      let customFormData = {
        ...data,
        name: data.name,
        program_ids: data.program_ids,
        programs: data.programs,
        structures: data.structures,
        created_by: data.created_by ? data.created_by._id : ""
      }
      setTempForm(customFormData)
      setForm(customFormData)
      setAllStructure(data.structures)
    }).catch(err => {
      console.log("err", err)
    })
  }
  const getAllPrograms = () => {
    props.getAllProgram().then(({ data }) => {
      setPrograms(data)
    })
  }
  useEffect(() => {
    getRevisionForms()
    getForm()
    getAllPrograms()
  }, [])

  const hadleCopyForm = () => {
    history.push({
      pathname: '/custom-form/add-new',
      state: { allStructure }
    })
  }

  const savedData = (data) => {
    if (data) {
      const dataItem = data.map((x, i) => {
        x.order = i + 1
        return x
      })
      setFormData(dataItem)
    }
  }

  const getDublicate = (tempFormData, structures, checks) => {
    for (let i = 0; i < tempFormData.length; i++) {
      if (structures.indexOf(tempFormData[i].field_name) > -1) {
        tempFormData[i].error = "Please make unique field_name"
        checks.push(i)
      } else {
        structures.push(tempFormData[i].field_name)
      }
      if (Array.isArray(tempFormData[i].extra_details)) {
        getDublicate(tempFormData[i].extra_details, structures, checks)
      }
    }
  }
  const handleSubmit = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    } else if (formData.map(x => x.field_label).includes('')) {
      setErrorMsg(true)
      return;
    }
    let tempForm = _.cloneDeep(form)
    formData.forEach((structure, i) => {
      if (structure.field_type === 'address') {
        formData[i].extra_details = []
      }
    })

    tempForm.structures = formData
    tempForm.programs = form.program_ids

    let structures = []
    let checks = []
    let tempFormData = _.cloneDeep(formData)
    getDublicate(tempFormData, structures, checks)
    setFormData(tempFormData)
    savedData(tempFormData)
    setErrorFormData(tempFormData)
    props.editForm(tempForm).then(({ tempForm }) => {
      openSnackbar({ message: 'Successfully Updated', status: 'success' });
    }).catch(err => {
      openSnackbar({ message: 'Something went Wrong', status: 'error' });
    })

  }
  const publishForm = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages(true)
      forceUpdate(true)
      return
    }
    const emptyLabel = formData.map(x => x.field_label)
    if (emptyLabel.includes('')) {
      setErrorMsg(true)
      return;
    }
    form.structures = formData
    form.programs = form.program_ids
    let versionForm = {
      ...form,
      version: form.version ? form.version + 1 : 1,
      has_published: true
    }
    let tempBackupForm = _.omit(tempForm, "_id", "__v", "created_by", "name_slug")
    tempBackupForm.has_published = false
    props.editForm(versionForm).then(({ data }) => {
      tempBackupForm.parent_id = data._id
      history.push("/custom-form")
      props.createForm(tempBackupForm).then(({ data }) => {
      }).catch(err => console.log("err", err))
    }).catch(err => console.log("err", err))
  }
  return (
    <Wrapper
      pageTitle="Edit Form Group"
      backgroundColor="#f1f1f1"
      backLink={history.location.pathname.split('/')[1]}
    >
      <NotifierSnack />
      <form className="customFormPage">
        <Grid container className="CustomFormTitleInput">
          <Grid item md={9}>
            <label>Edit Form</label>
            <input
              type="text"
              name="name"
              placeholder="Add Title"
              value={form.name}
              onChange={onChangeInput}
              style={{ borderColor: colorBorder }}
            />
            <Box style={{ color: 'red' }}>
              {simpleValidator.current.message('add title', form.name, 'required')}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={9} className="leftItem">
            <Paper className={classes.paper}>
              <TableNewForm errorFormData={errorFormData} allStructure={allStructure} savedData={savedData} errorMsg={errorMsg} />
            </Paper>
          </Grid>
          <Grid item md={3} className="rightItem">
            {/* Select Program */}
            <Box style={{ marginBottom: 15 }}>
              <Paper className={classes.paper}>
                <Box className="rightCard">
                  <span className="head">Select Program</span>
                  <span className="description">Forms can be associated with a program. These are visible when you create surveys or case types.</span>
                  <Box className="formControl">
                    <Select
                      className="multiSelectWithCheckbox"
                      labelId="select-program"
                      label="Select Program"
                      multiple
                      name='program_ids'
                      value={form.program_ids}
                      onChange={onChangeInput}
                      input={<Input />}
                      renderValue={(selected) => {
                        return programs.map(program => {
                          if (selected.indexOf(program._id) > -1) {
                            return program.name
                          }
                        })
                      }}
                      MenuProps={MenuProps}
                      variant="outlined"
                    >
                      {
                        programs.map((program, i) => {
                          return (
                            <MenuItem key={i} value={program._id}>
                              <Checkbox checked={form.program_ids.indexOf(program._id) > -1} />
                              <ListItemText primary={program.name} />
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                  </Box>
                </Box>
              </Paper>
            </Box>
            {/* Copy form */}
            <Box style={{ marginBottom: 15 }}>
              <Paper className={classes.paper}>
                <Box className="rightCard">
                  <span className="head">Copy from</span>
                  <span className="description">Copy the form and add your new fields</span>
                  <Button
                    variant="contained"
                    className="customBtn greenBtn"
                    size='small'
                    onClick={hadleCopyForm}
                  >
                    Copy Form
                    </Button>
                </Box>
              </Paper>
            </Box>
            {/* Button section */}
            <Box style={{ marginBottom: 10 }}>
              <Paper className={classes.paper}>
                <Box className="rightCard" style={{ borderBottomColor: '#ccc', borderBottomWidth: 1, borderBottomStyle: 'solid', marginBottom: 10 }}>
                  <span className="head">Changes have been made</span>
                  <span className="btnWrap">
                    <Button
                      variant="contained"
                      className="customBtn whiteBtn"
                      size='small'
                      onClick={() => handleSubmit()}
                    >
                      Save Draft
                    </Button>
                  </span>
                </Box>
                <Button
                  variant="contained"
                  className="customBtn greenBtn"
                  size='medium'
                  style={{ maxWidth: 170 }}
                  onClick={publishForm}
                >
                  PUBLISH FORM
                </Button>
              </Paper>
            </Box>
            <Box className="bottomTextWrap">
              {
                revisionForms.length > 0 ?
                  (
                    <span>
                      There are <span className="link" onClick={() => { history.push(`/custom-form/form-revisions/${id}`) }}>{revisionForms.length}</span> Revisions of this form See and restore by clicking <span className="link" onClick={() => history.push(`/custom-form/form-revisions/${id}`)}>here</span>
                    </span>
                  ) :
                  (
                    <span>
                      Not Published Form
                    </span>
                  )
              }
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box style={{ marginTop: 15 }}>
        {/* <pre>{JSON.stringify(finalData, undefined, 2)}</pre> */}
      </Box>
    </Wrapper>
  )
}

export default connect(
  null, {
  getSingleForm,
  editForm,
  getAllProgram,
  createForm,
  getAllRevisionForms
})(EditFormGroup)

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};
