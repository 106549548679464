import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { editProgram } from '../../../store/actions/program'
import { getAllFormByItsProgram } from '../../../store/actions/form'
import { connect } from 'react-redux'
import BeneficiariesTable from './BeneficiariesTable'
import BeneficiariesDrawerTab from './BeneficiariesDrawerTab'
import { createPermissionFile, editPermissionFile, getAllPermissionFiles } from '../../../store/actions/permission'
import _ from 'lodash'

import NotifierSnack, { openSnackbar } from '../../Global/NotifierSnackbar'
import ModulePermissionDialog from './ModulePermissionDialog'

import '../../../scss/_beneficiaries.scss'

const Permissions = props => {
  const [forms, setForms] = useState([])
  const [isDrawerOpen, setIsdrawerOpen] = useState(false)
  const [isEditDrawer, setIsEditDrawer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [permissionFiles, setPermissionFiles] = useState([])
  const [program, setProgram] = useState({})
  const [addedPermissionFiles, setAddedPermissionFiles] = useState([])
  const [openModulePermission, setOpenModulePermission] = useState(false)
  let { active_module } = props.program ? props.program : null


  const getAddedPermissionFile = (data) => {
    let tempPermissionFiles = data.filter(permissin_file => {
      if (props.program.permission_files.indexOf(permissin_file._id) > -1) {
        return true
      }
      return false
    })
    setSearchData([...tempPermissionFiles])
    setAddedPermissionFiles(tempPermissionFiles)
  }
  const getPermissionNotAddedToProgram = (data) => {
    let tempPermissionFiles = data.filter(permissin_file => {
      if (props.program.permission_files.indexOf(permissin_file._id) > -1) {
        return false
      }
      return true
    })
    setPermissionFiles([...tempPermissionFiles])
  }
  const getPermissionFiles = () => {
    props.getAllPermissionFiles().then(({ data }) => {
      getAddedPermissionFile(data)
      getPermissionNotAddedToProgram(data)
      setLoader(false)
    }).catch(err => {
      console.log("err", err, err.response)
      setLoader(false)
    })
  }

  const addPermissionFile = (permissin_file) => {
    props.createPermissionFile(permissin_file).then(({ data }) => {
      addItemTypeToProgram(data)
      let tempPermissionFile = _.cloneDeep(permissionFiles)
      tempPermissionFile = [...tempPermissionFile, data]
      setPermissionFiles(tempPermissionFile)
      handleDrawer(false)
      openSnackbar({ message: 'Added Permission File Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Added Permission File', status: 'error' });
    })
  }

  const updatePermissionFile = (_id, permission_file) => {
    props.editPermissionFile(_id, permission_file).then(({ data }) => {
      let index = addedPermissionFiles.findIndex(case_type => case_type._id === data._id)
      addedPermissionFiles.splice(index, 1, data)
      setAddedPermissionFiles([...addedPermissionFiles])
      setSearchData([...addedPermissionFiles])
      handleEditDrawer(false)
      openSnackbar({ message: 'Edited Permission File Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err, err.response)
      openSnackbar({ message: 'Error in Permission File', status: 'error' });
    })
  }
  const addItemTypeToProgram = (permissin_file) => {
    console.log('permissin_file', permissin_file)
    let tempProgram = { _id: program._id, permission_files: [...program.permission_files, permissin_file._id] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = permissionFiles.findIndex(permissionFile => permissionFile._id === permissin_file._id)
      let tempPermissionFiles = _.cloneDeep(permissionFiles)
      tempPermissionFiles.splice(index, 1)
      setPermissionFiles(tempPermissionFiles)
      setAddedPermissionFiles([...addedPermissionFiles, permissin_file])
      setSearchData([...addedPermissionFiles, permissin_file])
      handleDrawer(false)
      openSnackbar({ message: 'Added Cases Type Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      openSnackbar({ message: 'Error in Edit Cases Type', status: 'error' });
    })
  }
  const removeItemTypeFromProgram = (permission_file) => {
    let temp_permission_files = program.permission_files.filter(_id => _id !== permission_file._id)
    let tempProgram = { _id: program._id, permission_files: [...temp_permission_files] }
    props.editProgram(tempProgram).then(({ data }) => {
      setProgram(data)
      let index = addedPermissionFiles.findIndex(permissionFile => permissionFile._id === permission_file._id)
      let tempAddedPermissionFile = _.cloneDeep(addedPermissionFiles)
      tempAddedPermissionFile.splice(index, 1)
      setAddedPermissionFiles(tempAddedPermissionFile)
      setSearchData([...tempAddedPermissionFile])
      setPermissionFiles([...permissionFiles, permission_file])
      handleDrawer(false)
      setDeleteModal(false)
      openSnackbar({ message: 'Removed Permission File Successfully', status: 'success' });
    }).catch(err => {
      console.log("err", err);
      openSnackbar({ message: 'Error in Removing Permission File', status: 'error' });
    })
  }
  const getAllForms = () => {
    props.getAllFormByItsProgram(props.program._id).then(({ data }) => {
      setForms(data)
    }).catch(err => {
      console.log("err", err, err.response)
    })
  }
  const handleDrawer = (isOpen) => {
    setIsdrawerOpen(isOpen)
  }
  const handleEditDrawer = (isOpen) => {
    setIsEditDrawer(isOpen)
  }
  const handleDeleteModal = (value) => {
    setDeleteModal(value)
  }
  useEffect(() => {
    setProgram(props.program)
    getPermissionFiles()
    getAllForms()
    if (props.program && props.program.active_module && props.program.active_module.permission === false) {
      setOpenModulePermission(true)
    }
  }, []);
  const filterData = event => {
    const updateList = permissionFiles.filter(item => {
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
    setSearchData(updateList)
  }
  const handlePermissionModal = (value) => {
    setOpenModulePermission(value)
  }
  const programModuleActivate = () => {
    let tempProgram = { _id: props.program._id, active_module: { ...props.program.active_module, permission: true } }
    console.log('tempProgram', tempProgram)
    props.editProgram(tempProgram).then(({ data }) => {
      handlePermissionModal(false)
      props.setProgram(data)
    }).catch(err => {
      console.log('err', err)
    })
  }

  return (
    <React.Fragment>
      <NotifierSnack />
      <ModulePermissionDialog
        openModulePermission={openModulePermission}
        handlePermissionModal={handlePermissionModal}
        programModuleActivate={programModuleActivate}
        handleLink={props.handleLink}
      />
      <Box className="tabbackLinkMain">
        <Box className="link" onClick={() => props.handleLink(2)}>
          <ChevronLeftIcon />
          <span>Back to all modules</span>
        </Box>
      </Box>
      <Box className="Beneficiaries">
        <Box className="headingWrap">
          <FileCopyOutlinedIcon />
          <span className="heading">
            <h3>Permissions</h3>
            <span>{active_module && active_module.permission ? "Active" : "Inactive"}</span>
          </span>
        </Box>
        <Box className="leftTextRightSearchAndBtn">
          <span className="leftTxt">Active Permission Files</span>
          <Box className="rightContent">
            <Box className="searchBoxTable" style={{ marginBotthandleDeleteModaom: 0 }}>
              <input
                type="text"
                name="searchData"
                placeholder="Search"
                className="serachInput"
                onChange={filterData}
              />
              <IconButton className="iconButton" type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className="buttonWrap">
              <BeneficiariesDrawerTab
                buttonLabel="ADD PERMISSION FILE"
                name="Add PERMISSION FILE TO PROGRAM"
                title='Permission'
                existing="Select from existing"
                add_title="Create new permission file"
                isDrawerOpen={isDrawerOpen}
                handleDrawer={handleDrawer}
                forms={forms}
                handleSubmitData={addPermissionFile}
                addItemTypeToProgram={addItemTypeToProgram}
                data={permissionFiles}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <BeneficiariesTable
          isEditDrawer={isEditDrawer}
          handleEditDrawer={handleEditDrawer}
          forms={forms}
          handleSubmitData={updatePermissionFile}
          data={searchData}
          loader={loader}
          removeItemTypeFromProgram={removeItemTypeFromProgram}
          name="Permission"
          backLink={true}
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
        />
      </Box>
    </React.Fragment>
  )
}

export default connect(null, {
  getAllPermissionFiles,
  editPermissionFile,
  getAllFormByItsProgram,
  createPermissionFile,
  editProgram
})(Permissions)


