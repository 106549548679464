import React, {useState} from 'react'
import {
  Box,
 } from '@material-ui/core'
 import {  Link } from 'react-router-dom'
 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';
 import DesktopWindowsTwoToneIcon from '@material-ui/icons/DesktopWindowsTwoTone'; 
 import DesktopAccessDisabledTwoToneIcon from '@material-ui/icons/DesktopAccessDisabledTwoTone';
 import '../../scss/_tableActionIcons.scss'

const TableActionIcons = props => {
  const {data, handleAction, deleteModal, handleIsActive} = props

  const suspendHandle = () => {
    props.getSuspendedData(data)
    if(handleIsActive){
      handleIsActive(false)
    }
    
  }

  return (
    <React.Fragment>
      <Box className="tableActionIcons">
        {
          data.status !== true &&
          <Box component="span" className="actionIcon active" onClick={suspendHandle} title="Active">
            <DesktopWindowsTwoToneIcon fontSize="small" />
          </Box>
        }
        {
          handleAction ?
            <Link to='#' onClick={() => handleAction(data)} className="actionIcon edit" title="Edit">
              <EditIcon fontSize="small" /> 
            </Link>
            :
             <Link to={`/user/edit/${data._id}`} className="actionIcon edit" title="Edit">
              <EditIcon fontSize="small" /> 
            </Link>

        }
        {
          data.status ?
          <Box component="span" className="actionIcon inactive" onClick={suspendHandle} title="Inactive">
            <DesktopAccessDisabledTwoToneIcon fontSize="small" />
          </Box>
          :
          <Box component="span" className="actionIcon delete" onClick={deleteModal? deleteModal : suspendHandle} title="Delete">
            <DeleteIcon fontSize="small" />
          </Box>
        }
      </Box>
    </React.Fragment>
  )
}

export default TableActionIcons
