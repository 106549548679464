import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import reducer from './reducers'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history';
import {routerMiddleware } from 'connected-react-router'
import {composeWithDevTools} from 'redux-devtools-extension'


export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['navigation'],
}
const persistedReducer = persistReducer(persistConfig, reducer(history))
const middleware = applyMiddleware(reduxThunk, routerMiddleware(history))

export default () => {
  let store = createStore(persistedReducer, {}, composeWithDevTools(middleware))
  let persistor = persistStore(store)
  return { store, persistor }
}
