import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableFooter,
  CircularProgress,
  Box,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';

import TablePaginationActions from '../../common/TablePaginationActions'
import ProgramSlider from './ProgramSlider'
import LeftImageRightName from './LeftImageRightName'

import '../../../scss/_table.scss'
import '../../../scss/_singleProgramGrid.scss'


const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.Primary,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
}))(TableCell);

const TablePrograms = (props) => {
  const { data, loader } = props
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState('true')
  const [tableData, setTableData] = useState([])
  const [searchData, setSearchData] = useState('')

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setTableData(data)
    setSearchData(data)
  }, [data])

  const handleChange = (e, index) => {
    const { name, value } = e.target
    const list = [...tableData]
    list[index][name] = value
    setStatus(list);
    // setSearchData(value)
  };


  const filterData = e => {
    const updatedList = tableData.filter(item => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    setSearchData(updatedList.length > 0 && updatedList)
  };

  // console.log(tableData, 'Table Dat../')

  const sliderData = [
    {
      id: 0,
      name: 'Form Entries',
      stats: '0'
    },
    {
      id: 1,
      name: 'Team',
      stats: '0'
    },
    {
      id: 2,
      name: 'Events',
      stats: '0'
    },
    {
      id: 3,
      name: 'Team',
      stats: '0'
    },
    {
      id: 4,
      name: 'Goals',
      stats: '0'
    },
    {
      id: 5,
      name: 'Form Entries',
      stats: '0'
    },
    {
      id: 6,
      name: 'Events',
      stats: '0'
    }
  ]


  return (
    <React.Fragment>
      <Box className="programMain">
        <Box className="searchBoxTable" style={{ marginBottom: 10 }}>
          <IconButton className="iconButton" type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
          {/* <Divider orientation="vertical"  style={{height: 28, margin: 4}}/> */}
          <input
            type="text"
            name="searchData"
            placeholder="Search by name"
            className="serachInput"
            onChange={filterData}
          />
        </Box>
        <TableContainer>
          <Table size="small" aria-label="user table" className="programTable">
            {
              loader ?
                <TableBody>
                  <TableRow>
                    <TableCell align="center" width="100%" colSpan={12}>
                      <Box>
                        <CircularProgress disableShrink />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
                :
                <TableBody>
                  {searchData ?
                    (rowsPerPage > 0 && searchData
                      ? searchData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : []
                    ).map((program, i) => (
                      <TableRow key={i} hover={true}>
                        <TableCell>
                          <Box className="singleProgramGrid">
                            <Box
                              className="imageWithName"
                              onClick={
                                () => props.history.push(`/program/${program._id}`)
                              }
                            >
                              <LeftImageRightName
                                data={program}
                              />
                            </Box>
                            <Box className="programSlider">
                              <ProgramSlider
                                data={program.goals}
                              />
                            </Box>
                          </Box>
                          {/* <Box className="imageTextWrap" >
                      <CardMedia
                        className="tableImage"
                        image={program.photo? "http://api.cws-main.cobold.xyz/"+program.photo: ""}
                        title="user"
                      />
                      <Box className="textWrap" >
                        <span className="pName">{program.name}</span>
                        {
                          program.updated_at &&
                          <span className="lastUpdated">
                            Last updated: {
                              program.updated_at.slice(0, program.updated_at.indexOf('T'))
                            } 
                          </span>
                        }
                      </Box>
                    </Box> */}
                        </TableCell>
                        {/* <TableCell>
                    {
                      program.program_head &&
                    program.program_head.map((head, index)=> <Box key={index}>{head.first_name} {head.last_name}</Box>)
                    }
                  </TableCell> */}
                        {/* <TableCell align="right" className="statusRow">
                    <ProgramStatus
                      value={program.status}
                      handleChange={e=>handleChange(e, i)}
                      name="status"
                    />
                  </TableCell> */}
                        {/* <TableCell align="right">
                    <Box className="programSlider">
                      <ProgramSlider 
                        data={sliderData}
                      />
                    </Box>
                  </TableCell> */}
                      </TableRow>
                    ))
                    :
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box style={{ textAlign: 'center', fontSize: 15, color: '#c5c5c5' }}>
                          No program found
                        </Box>
                      </TableCell>
                    </TableRow>
                  }

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 43 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
            }
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ borderBottom: 0 }}
                  // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  rowsPerPageOptions={[10, 20, 30]}
                  colSpan={8}
                  count={props.data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'program per page' },
                    native: true,
                  }}
                  labelRowsPerPage='Programs per page'
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
}

export default withRouter(TablePrograms)