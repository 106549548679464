import axios from '../mainaxios'


export const getAllSurveyType = () => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get('surveytype', {
    headers:{
      Authorization: token
    }
  })
}
export const getSingleSurveyType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.get(`surveytype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
export const createSurveyType = (surveytype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.post('surveytype', surveytype, {
    headers:{
      Authorization: token
    }
  })
}
export const editSurveyType = (surveytype) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.patch(`surveytype/${surveytype._id}`, surveytype, {
    headers:{
      Authorization: token
    }
  })
}
export const deleteSurveyType = (_id) => async ()=>{
  const token = `Bearer ${localStorage.getItem("cws_token")}`
  return await axios.delete(`surveytype/${_id}`, {
    headers:{
      Authorization: token
    }
  })
}
